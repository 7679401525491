import styled from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

import AccordionItem from 'shared/ui/deprecated/Accordion/Accordion';

export const InfoUnitGrid = styled.div`
    display: grid;
    gap: var(--size-spacing-x6);
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        'createdDate plannedDate contractor'
        'address region city';
`;

export const StyledAccordionItem = styled(AccordionItem)<{active?: boolean; noOverflow?: boolean}>`
    & > div:first-child {
        ${(p) => p.active && ' background: rgba(25, 28, 52, 0.08);'}
    }
`;

export const WhiteTypography = styled(Typography)`
    white-space: pre-line;
`;
