import React from 'react';
import {Label, Skeleton, Typography} from '@beeline/design-system-react';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import ProjectInfoCell from 'shared/ui/deprecated/ProjectInfoCell/ProjectInfoCell';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {IDocument} from 'shared/types/documentTypes';

import * as S from './style';
import {sizeSpacingX2, sizeSpacingX4} from '@beeline/design-tokens/js/tokens';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface DocumentViewInfoUnitProps {
    data: IDocument | null;
}

export const DocumentViewInfoUnit = ({data}: DocumentViewInfoUnitProps) => {
    return (
        <WrapperOffset flexColumn gap={sizeSpacingX4}>
            {data ? (
                <WrapperFlex alignItems="center" gap={sizeSpacingX2}>
                    <Typography variant="h5">{`БС ${data.bs.bs_number} - ${data.bs.bs_name}`}</Typography>
                    {data.is_urgent && (
                        <Label title="Срочный запрос" type="warning" variant="contained" />
                    )}
                </WrapperFlex>
            ) : (
                <Skeleton variant="line" width={200} height={28} />
            )}
            <S.BaseStationInfoWrapper>
                <ProjectInfoCell title="Адрес" flex="0 1 517px">
                    {data ? (
                        data.bs.bs_address
                    ) : (
                        <Skeleton variant="line" width={200} height={22} />
                    )}
                </ProjectInfoCell>
                <ProjectInfoCell title="Тип проекта" flex="0 1 303px">
                    {data ? (
                        data.project_type_name
                    ) : (
                        <Skeleton variant="line" width={200} height={22} />
                    )}
                </ProjectInfoCell>
                <ProjectInfoCell title="Дата создания" flex="0 1 200px">
                    {data ? (
                        formatDateHours(data.created_at.toString())
                    ) : (
                        <Skeleton variant="line" width={200} height={22} />
                    )}
                </ProjectInfoCell>
                <ProjectInfoCell title="Дата ответа" flex="0 0 160px">
                    {data ? (
                        formatDateHours(data.approved_at)
                    ) : (
                        <Skeleton variant="line" width={200} height={22} />
                    )}
                </ProjectInfoCell>
            </S.BaseStationInfoWrapper>
        </WrapperOffset>
    );
};
