import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Autocomplete, Button, IconButton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TrimTextArea} from 'Features/TrimTextArea';
import {CommonLayout} from 'shared/ui/CommonLayout';
import {
    $documentCreationStore,
    commentValueChanged,
    selectedDocumentTypeChanged,
    creationButtonClicked,
    DocumentConstructorGate,
    editingButtonClicked,
    selectedDocumentTypeReset,
    documentTypeAutocompleteListFilled,
} from 'pages/DocumentConstructorPage/model';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TFile, useFileUploader} from 'shared/ui/use-file-uploader';
import {EActionType} from 'shared/ui/use-file-uploader/store';
import {TFileUploaderMode} from 'shared/ui/use-file-uploader/useFileUploader.types';
import {DOCUMENT_ALLOWED_FORMAT_LIST, MAX_ALLOWED_UPLOAD_FILE_SIZE} from 'shared/const/fileFormats';
import {EDocumentType, TDocumentSubtype} from 'shared/types/documentTypes';
import {
    CANCEL_BUTTON_TITLE,
    CREATE_TITLE,
    DOCUMENT_TYPE_LABEL,
    EDIT_TITLE,
    FOR_AGREEMENT_BUTTON_TITLE,
    UNNECESSARY_TEXTAREA_LABEL,
} from 'shared/constants';

import * as S from './style';
import {IDocumentOption} from '../model/helpers';

interface IDocumentCreationPageProps {
    initialFiles?: TFile[];
    mode: TFileUploaderMode;
    documentType: EDocumentType;
    projId?: string;
    documentId?: string;
    documentSubtype?: TDocumentSubtype;
    handleCloseEditing?: (bool: boolean) => void;
    handleClickWorkflow?: (index: number) => void;
    isWorkflowButtonClicked?: boolean;
}

export const DocumentConstructorPage = ({
    initialFiles,
    mode,
    documentId,
    documentType,
    projId,
    handleCloseEditing,
    handleClickWorkflow,
    documentSubtype,
    isWorkflowButtonClicked,
}: IDocumentCreationPageProps) => {
    const {projectId} = useParams<{projectId: string}>();
    useGate(DocumentConstructorGate, {
        projectId: projId ?? projectId ?? '',
        documentType: documentType,
        mode,
    });
    const {
        documentTypeList,
        selectedDocumentType,
        commentValue,
        createdDocumentId,
        editedDocumentId,
        minFilesCount,
        maxFilesCount,
        buttonClicked,
        formTouched,
    } = useStore($documentCreationStore);
    const navigate = useNavigate();
    const [FileUploader, files, fileUploaderDispatch, isError] = useFileUploader({
        fileUploaderName: 'test',
        maxFileSize: MAX_ALLOWED_UPLOAD_FILE_SIZE,
        acceptFormats: DOCUMENT_ALLOWED_FORMAT_LIST,
        minFiles: minFilesCount,
        maxFiles: maxFilesCount,
        mode,
        initialFiles,
        withRegistrationDate: selectedDocumentType?.isRegistration ?? false,
        formTouched,
    });
    const [initialState, setInitialState] = useState(files);

    useEffect(() => {
        if (mode === 'editing' && !!documentSubtype) {
            const result: IDocumentOption = {
                id: documentSubtype.id,
                value: documentSubtype.name,
                isRegistration: documentSubtype.is_registration,
            };
            documentTypeAutocompleteListFilled([documentSubtype]);
            selectedDocumentTypeChanged(result);
        }
    }, []);

    useEffect(() => {
        return () => {
            fileUploaderDispatch({type: EActionType.ResetState});
        };
    }, [fileUploaderDispatch]);

    if (createdDocumentId) {
        navigate(`/documents/${createdDocumentId}`);
    }

    if (editedDocumentId) {
        handleCloseEditing && handleCloseEditing(false);
        handleClickWorkflow && handleClickWorkflow(0);
        navigate(`/documents/${editedDocumentId}`);
    }

    const handleCancel = () => {
        if (mode === 'creation') {
            navigate(-1);
        } else {
            handleCloseEditing && handleCloseEditing(false);
        }
    };

    const handleCreation = () => {
        if (mode === 'creation')
            creationButtonClicked({
                files,
                isError,
                isWorkflowButtonClicked,
            });
        if (mode === 'editing')
            editingButtonClicked({
                initialState,
                files,
                isError,
                projId,
                documentId,
                isWorkflowButtonClicked,
            });
    };

    const creationDisabled =
        selectedDocumentType === null ||
        (formTouched &&
            (buttonClicked ||
                isWorkflowButtonClicked ||
                Object.keys(files).length < minFilesCount ||
                isError));

    const title = mode === 'creation' ? CREATE_TITLE : EDIT_TITLE;
    const autocompleteDisabled = mode === 'editing';

    return (
        <CommonLayout>
            <WrapperOffset offset={32}>
                <S.Wrapper>
                    <S.Title>
                        <IconButton
                            iconName={Icons.ArrowLeft}
                            size="medium"
                            onClick={handleCancel}
                        />
                        <Typography variant="h4">{title} документ</Typography>
                    </S.Title>
                    <Autocomplete
                        dropdownClassName="normalWhiteSpace"
                        renderValue={(value) => value.value.toString()}
                        onInputClear={selectedDocumentTypeReset}
                        onInputChange={(_) => 1}
                        makeOption={(option, inputValue) => {
                            if (inputValue) {
                                return option.value
                                    .toString()
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ? (
                                    <Typography>{option.value}</Typography>
                                ) : null;
                            }
                            return <Typography>{option.value}</Typography>;
                        }}
                        onChange={selectedDocumentTypeChanged}
                        value={selectedDocumentType}
                        options={documentTypeList}
                        placeholder={DOCUMENT_TYPE_LABEL}
                        type={autocompleteDisabled ? 'select' : 'search'}
                        disabled={autocompleteDisabled}
                        fullWidth
                    />
                    <FileUploader />
                    <TrimTextArea
                        value={commentValue}
                        label={UNNECESSARY_TEXTAREA_LABEL}
                        onChange={commentValueChanged}
                        fullWidth
                    />
                    <S.ButtonsContainer>
                        <Button size="medium" onClick={handleCancel} disabled={buttonClicked}>
                            {CANCEL_BUTTON_TITLE}
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            disabled={creationDisabled}
                            onClick={handleCreation}
                        >
                            {FOR_AGREEMENT_BUTTON_TITLE}
                        </Button>
                    </S.ButtonsContainer>
                </S.Wrapper>
            </WrapperOffset>
        </CommonLayout>
    );
};
