import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {IExpandedColumn} from 'shared/types/tableTypes';
import {TDocumentsListItem} from 'shared/types/documentTypes';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {StyledSpan} from 'shared/styles/commonStyle';
import {EAccessActions} from 'shared/const/actions';

export const logisticColumns: IExpandedColumn<TDocumentsListItem>[] = [
    {
        name: 'toggle',
        label: '',
        width: '52px',
        render: ({previous_version}, userActions, isOpen) =>
            (previous_version?.length ?? 0) > 0 ? (
                <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} size="medium" />
            ) : undefined,
    },
    {
        name: 'requestNumber',
        label: '№ запроса',
        hasSorting: true,
        width: '130px',
        render: ({id, version}, userActions) => {
            const hasPermission =
                userActions && userHasPermission(EAccessActions.Development, userActions);
            return (
                <TooltipedSpan title={version} lineCount={1}>
                    {hasPermission ? <Link to={`/documents/${id}`}>{version}</Link> : version}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'creationDate',
        label: 'Дата создания',
        hasSorting: true,
        width: '150px',
        render: ({created_at}) => formatDateHours(created_at),
    },
    {
        name: 'plannedDate',
        label: 'Планируемая дата',
        hasSorting: true,
        width: '150px',
        render: ({planned_date}) => formatDateHours(planned_date),
    },
    {
        name: 'version',
        label: 'Версия',
        width: '84px',
        render: ({prefix_version, version}) => {
            const versionString = `${prefix_version.toString()}.${version.toString()}`;
            return (
                <TooltipedSpan title={versionString} lineCount={1}>
                    {versionString}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'status',
        label: 'Статус',
        width: '166px',
        render: ({status}) =>
            !!status ? (
                <Label title={status.name} type={getLabelType(status.token) as AllStatuses} />
            ) : (
                '—'
            ),
    },
    {
        name: 'author',
        label: 'Автор',
        width: '204px',
        render: ({editor}) => (
            <TooltipedSpan title={editor.full} lineCount={1}>
                {editor.full}
            </TooltipedSpan>
        ),
    },
    {
        name: 'contractor',
        label: 'Подрядчик',
        hasSorting: true,
        width: '220px',
        render: ({organization_gpo}) => <StyledSpan lineCount={1}>{organization_gpo}</StyledSpan>,
    },
    {
        name: 'isUrgent',
        label: 'Срочное',
        hasSorting: true,
        width: '88px',
        render: ({is_urgent}) => (is_urgent ? 'Да' : undefined),
    },
];
