import React from 'react';
import {useStore} from 'effector-react';

import {TableBody} from '@beeline/design-system-react';

import {TableHeader} from 'Features/TableHeader';
import Pagination from 'shared/ui/Pagination/Pagination';
import {CustomTableData, InnerTableRow, OuterTableRow} from 'shared/styles/commonStyle';
import {$isUserVK, $userActions} from 'shared/model/user';
import {EAccessActions} from 'shared/const/actions';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {
    IColumnCompletedWorksListItem,
    IToggledCompletedListItem,
} from 'shared/types/completedWorksTypes';

import * as S from './style';
import {CompletedWorksTableSkeleton} from './CompletedWorksTableSkeleton';
import {IInnerColumn} from './allColumns';
import {
    $completedWorksStageStore,
    pageChanged,
    pageSizeChanged,
    rowExpanded,
} from '../../../../model/completedWorksStage';

interface CompletedWorksTableProps {
    columns: IColumnWithActions<IToggledCompletedListItem>[];
    innerColumns: IInnerColumn<IColumnCompletedWorksListItem>[];
}

export const CompletedWorksTable = ({columns, innerColumns}: CompletedWorksTableProps) => {
    const {completedWorksList, expandedRowList, tableProps, listIsLoading, total} =
        useStore($completedWorksStageStore);
    const userActions = useStore($userActions);
    const isVk = useStore($isUserVK);

    const completedWorksLinksAllowed = userHasPermission(
        EAccessActions.CompletedWorksCard,
        userActions,
    );

    const handleClickRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
        e.stopPropagation();
        rowExpanded(id);
    };

    let finalColumns = columns;
    let finalInnerColumns = innerColumns;

    if (!isVk) {
        finalColumns = finalColumns.filter((item) => item.name !== 'main_contractor');
        finalInnerColumns = finalInnerColumns.filter((item) => item.name !== 'main_contractor');
    }

    return (
        <S.PaginationWrapper>
            <S.TableWrapper>
                <S.StyledTable>
                    <TableHeader columns={finalInnerColumns} />
                    <TableBody>
                        {listIsLoading ? (
                            <CompletedWorksTableSkeleton
                                columns={finalInnerColumns}
                                pageSize={tableProps.pageSize}
                            />
                        ) : (
                            completedWorksList.map((item) => (
                                <React.Fragment key={item.id}>
                                    <OuterTableRow
                                        onClick={(e) => handleClickRow(e, item.id)}
                                        dense
                                        hover
                                    >
                                        {finalColumns.map((col, index) => (
                                            <CustomTableData
                                                key={`outer${item.id}_${index}`}
                                                colSpan={
                                                    col.name === 'completed_works_number' ? 2 : 1
                                                }
                                            >
                                                {col.render &&
                                                    col.render(
                                                        {
                                                            ...item,
                                                            isOpen: expandedRowList.includes(
                                                                item.id,
                                                            ),
                                                        },
                                                        userActions,
                                                    )}
                                            </CustomTableData>
                                        ))}
                                    </OuterTableRow>
                                    {expandedRowList.includes(item.id) &&
                                        item.vvrs.map((completedWorkSheet) => (
                                            <InnerTableRow key={completedWorkSheet.id} dense>
                                                {finalInnerColumns.map((col, index) => (
                                                    <CustomTableData
                                                        key={`inner${completedWorkSheet.id}_${index}`}
                                                    >
                                                        {col.render &&
                                                            col.render(
                                                                {
                                                                    ...completedWorkSheet,
                                                                    hasPermission:
                                                                        completedWorksLinksAllowed,
                                                                },
                                                                item.sum,
                                                            )}
                                                    </CustomTableData>
                                                ))}
                                            </InnerTableRow>
                                        ))}
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </S.StyledTable>
            </S.TableWrapper>
            <Pagination
                total={total}
                setPageSize={pageSizeChanged}
                setPage={pageChanged}
                page={tableProps.page}
                pageSize={tableProps.pageSize}
                isLoading={listIsLoading}
            />
        </S.PaginationWrapper>
    );
};
