import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {or} from 'patronum';

import {fetchErrorList, postAgreementError} from 'shared/services/errors.service';
import {IAdditionalAgreementError} from 'shared/types/additionalAgreementsTypes';
import {IErrorPostParams} from 'shared/services/types/agreementParamsTypes';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';

export const ErrorListWidgetDomain = createDomain();
export const ErrorListWidgetGate = createGate<{id: string}>();

// effects
const getErrorListFx = ErrorListWidgetDomain.createEffect(async (id: string) => {
    try {
        const result = await fetchErrorList(id);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

const postErrorFx = ErrorListWidgetDomain.createEffect(async (payload: IErrorPostParams) => {
    try {
        const result = await postAgreementError(payload);
        return {
            id: payload.data.id,
            result: result.status === 200,
        };
    } catch (e) {
        console.warn(e);
        return {
            id: '',
            result: false,
        };
    }
});

// events
export const ErrorCheckboxToggled = ErrorListWidgetDomain.createEvent<string>();

// stores
const $errorList = ErrorListWidgetDomain.createStore<IAdditionalAgreementError[]>([]);
const $someErrorsNotFixed = ErrorListWidgetDomain.createStore<boolean>(true);
const $togglingInProgress = ErrorListWidgetDomain.createStore<boolean>(false);
const $actionButtonDisabled = or($togglingInProgress, $someErrorsNotFixed);

export const $errorListWidget = combine({
    errorList: $errorList,
    togglingInProgress: $togglingInProgress,
    actionButtonDisabled: $actionButtonDisabled,
});

sample({
    clock: ErrorListWidgetGate.open,
    fn: (gate) => gate.id,
    target: getErrorListFx,
});

sample({
    clock: getErrorListFx.doneData,
    fn: (response) => response ?? [],
    target: $errorList,
});

sample({
    clock: ErrorCheckboxToggled,
    source: combine({
        errorList: $errorList,
        idStore: ErrorListWidgetGate.state,
    }),
    fn: ({errorList, idStore: {id}}, errorId) => {
        const changedError = errorList.find((item) => item.id === errorId);
        return {
            id: id,
            data: {
                result: !changedError?.is_fixed ?? false,
                id: changedError?.id ?? '',
                atribute: null,
            },
        };
    },
    target: postErrorFx,
});

sample({
    clock: postErrorFx.pending,
    target: $togglingInProgress,
});

sample({
    clock: postErrorFx.doneData,
    source: $errorList,
    filter: (_, clock) => clock.result ?? false,
    fn: (store, clock) => {
        return store.map((item) => {
            if (item.id === clock.id) {
                item.is_fixed = !item.is_fixed;
            }
            return item;
        });
    },
    target: $errorList,
});

sample({
    source: $errorList,
    fn: (list) => list.some((item) => !item.is_fixed),
    target: $someErrorsNotFixed,
});

resetDomainStoresByEvents(ErrorListWidgetDomain, ErrorListWidgetGate.close);
