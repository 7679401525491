import React from 'react';
import {useUnit} from 'effector-react';
import {TableBody} from '@beeline/design-system-react';

import {TableHeader} from 'Features/TableHeader';
import {TableBase} from 'Entities/TableBase';
import {EmptyTab} from 'Entities/EmptyTab';
import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import {$userActions} from 'shared/model/user';
import {IExpandedColumn} from 'shared/types/tableTypes';
import {TDocumentsListItem} from 'shared/types/documentTypes';
import {IPaginationData} from 'shared/types/commonTypes';
import {CustomTableData, InnerTableRow, OuterTableRow} from 'shared/styles/commonStyle';
import {
    DISCARD_BUTTON_TITLE,
    EMPTY_FILTERED_DATA_TITLE,
    TRY_CHANGE_FILTERS_SUBTITLE,
} from 'shared/constants';

interface DocumentTableProps {
    columns: IExpandedColumn<TDocumentsListItem>[];
    data: TDocumentsListItem[];
    expandedRowList: string[];
    onRowClick: (id: string) => void;
    paginationData: IPaginationData;
    onResetData?: () => void;
    minTableWidth?: string;
}

export const DocumentTable = ({
    columns,
    data,
    expandedRowList,
    paginationData,
    onRowClick,
    onResetData,
    minTableWidth,
}: DocumentTableProps) => {
    const userActions = useUnit($userActions);
    const {isLoading} = paginationData;

    const handleClickRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
        e.stopPropagation();
        onRowClick(id);
    };

    const handleOnClick = () => {
        onResetData && onResetData();
    };

    return (
        <>
            {data.length > 0 || isLoading ? (
                <TableBase
                    minTableWidth={minTableWidth ?? '1300px'}
                    paginationData={paginationData}
                >
                    <TableHeader columns={columns} />
                    {isLoading ? (
                        <TableBodySkeleton<TDocumentsListItem> columns={columns} />
                    ) : (
                        <TableBody>
                            {data.map((item) => (
                                <React.Fragment key={item.id}>
                                    <OuterTableRow
                                        onClick={(e) => handleClickRow(e, item.id)}
                                        dense
                                        hover={(item.previous_version?.length ?? 0) > 0}
                                    >
                                        {columns.map((col) => (
                                            <CustomTableData key={`${col.name}${item.id}`}>
                                                {col.render &&
                                                    col.render(
                                                        item,
                                                        userActions,
                                                        expandedRowList.includes(item.id),
                                                    )}
                                            </CustomTableData>
                                        ))}
                                    </OuterTableRow>
                                    {expandedRowList.includes(item.id) &&
                                        item.previous_version &&
                                        item.previous_version.map((prevItem) => (
                                            <InnerTableRow key={prevItem.id}>
                                                {columns.map((col) => (
                                                    <CustomTableData
                                                        key={`inner_${col.name}${item.id}`}
                                                        dense
                                                    >
                                                        {col.render &&
                                                            col.render(
                                                                prevItem,
                                                                userActions,
                                                                false,
                                                            )}
                                                    </CustomTableData>
                                                ))}
                                            </InnerTableRow>
                                        ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    )}
                </TableBase>
            ) : (
                <EmptyTab
                    title={EMPTY_FILTERED_DATA_TITLE}
                    subtitle={TRY_CHANGE_FILTERS_SUBTITLE}
                    buttonTitle={DISCARD_BUTTON_TITLE}
                    onClick={handleOnClick}
                    showButton={!!onResetData}
                />
            )}
        </>
    );
};
