import {createGate} from 'effector-react';
import {combine, createDomain, forward, sample} from 'effector';

import {additionalAgreementService} from 'pages/DSPage/service';
import {ITaskResponse} from 'pages/DSPage/types';
import {requestEditingInvoked} from 'pages/RequestCreationPage/model';
import {fetchRawDocument, postDocumentComment} from 'shared/services/documents.service';
import {IDocument} from 'shared/types/documentTypes';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {TAttachmentFile} from 'shared/types/FileTypes';
import {fetchFileWithValidation} from 'shared/services/files.service';
import {downloadBlobFile} from 'shared/helpers/blob';
import {getTask, getTasksList, updateTask} from 'shared/services/tasks.service';
import {$isUserVK, $userActions, $userStore} from 'shared/model/user';
import {TTaskList} from 'shared/types/tasksTypes';

export const RawDocumentPageGate = createGate<string>();
export const RawDocumentPageDomain = createDomain();

// effects
const fetchRawDocumentData = RawDocumentPageDomain.createEffect(async (id: string) => {
    try {
        const result = await fetchRawDocument(id);
        return result.data;
    } catch (e) {
        console.error(e);
    }
});

const downloadAttachmentFileFx = RawDocumentPageDomain.createEffect(
    async ({id, file_name, type}: TAttachmentFile) => {
        try {
            const result = await fetchFileWithValidation(id, type);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, file_name);
            }
        } catch (e) {
            console.log(e);
        }
    },
);

const fetchAvailableTasksFx = RawDocumentPageDomain.createEffect(async (id: string) => {
    try {
        const result = await getTasksList({id: id});
        return result.data;
    } catch (e) {
        console.log(e);
    }
});
const fetchWorkflowButtonsFx = RawDocumentPageDomain.createEffect(async (id: string) => {
    try {
        return await getTask(id);
    } catch (e) {
        console.log(e);
    }
});

const acceptTaskFx = RawDocumentPageDomain.createEffect(additionalAgreementService.acceptTask);
const cancelTaskFx = RawDocumentPageDomain.createEffect(additionalAgreementService.cancelTask);

const updateTaskFx = RawDocumentPageDomain.createEffect(async ({id, actionId}) => {
    try {
        return await updateTask({id, actionId});
    } catch (e) {
        console.log(e);
    }
});

export const sendCommentFx = RawDocumentPageDomain.createEffect(
    async (data: {id: string; comment: string}) => {
        try {
            return await postDocumentComment(data);
        } catch (e) {
            console.log(e);
        }
    },
);

export const addCommentFx = RawDocumentPageDomain.createEffect(
    async (data: {id: string; comment: string}) => {
        try {
            return await postDocumentComment(data);
        } catch (e) {
            console.log(e);
        }
    },
);

// events
export const downloadAttachmentFile = RawDocumentPageDomain.createEvent<TAttachmentFile>();
export const resetStores = RawDocumentPageDomain.createEvent();
export const handleWorkflowBtn = RawDocumentPageDomain.createEvent<number>();
export const handleAcceptWorkflowBtn = RawDocumentPageDomain.createEvent<string>();
export const handleCancelWorkflowBtn = RawDocumentPageDomain.createEvent<string>();
export const setComment = RawDocumentPageDomain.createEvent<string>();
export const sendComment = RawDocumentPageDomain.createEvent<{
    index: number;
    isPositive: boolean;
}>();
export const sendNewComment = RawDocumentPageDomain.createEvent();
export const resetTextArea = RawDocumentPageDomain.createEvent();
export const commentHasSent = RawDocumentPageDomain.createEvent();
export const setEditMode = RawDocumentPageDomain.createEvent<boolean>();
export const commentRowToggled = RawDocumentPageDomain.event<number>();
export const editRequestClicked = RawDocumentPageDomain.event();
// stores
const $rawDocumentData = RawDocumentPageDomain.createStore<IDocument | null>(null).on(
    fetchRawDocumentData.doneData,
    forwardPayload(),
);

const $availableTasksData = RawDocumentPageDomain.createStore<TTaskList>([])
    .on(fetchAvailableTasksFx.doneData, forwardPayload())
    .reset(resetStores);

const $workflowButtonsData = RawDocumentPageDomain.createStore<ITaskResponse | null>(null)
    .on(fetchWorkflowButtonsFx.doneData, forwardPayload())
    .reset(resetStores);
const $workflowButtonClicked = RawDocumentPageDomain.createStore<boolean>(false).on(
    [sendCommentFx.pending, updateTaskFx.pending],
    forwardPayload(),
);

export const $comment = RawDocumentPageDomain.createStore<string>('')
    .on(setComment, forwardPayload())
    .reset([commentHasSent, resetTextArea]);

const $isSendButtonHide = RawDocumentPageDomain.createStore<boolean>(false).reset(resetTextArea);

const $commentIsValid = RawDocumentPageDomain.createStore<boolean>(false);
const $buttonIsTouched = RawDocumentPageDomain.createStore<boolean>(false);
const $isCommentError = RawDocumentPageDomain.createStore<boolean>(false);
const $isEditMode = RawDocumentPageDomain.createStore<boolean>(false).on(
    setEditMode,
    forwardPayload(),
);
const $expandedItemId = RawDocumentPageDomain.store<number | null>(null).on(
    commentRowToggled,
    (state, payload) => {
        return state === payload ? null : payload;
    },
);

export const $pushedButtonIndex = RawDocumentPageDomain.createStore<number>(-1)
    .on(sendComment, (_, payload) => payload.index)
    .reset(resetTextArea);

export const $workflowButtonsStore = combine({
    availableTasks: $availableTasksData,
    workflowButtons: $workflowButtonsData,
    buttonIsLoading: $workflowButtonClicked,
});

export const $rawDocumentStore = combine({
    data: $rawDocumentData,
    anyFileIsLoading: downloadAttachmentFileFx.pending,
    workflowButtonsStore: $workflowButtonsStore,
    comment: $comment,
    commentError: $isCommentError,
    isSendButtonHide: $isSendButtonHide,
    isEditMode: $isEditMode,
    isUserVK: $isUserVK,
    ownerId: $userStore.map((state, _) => state.owner_id),
    userActions: $userActions,
    expandedItemId: $expandedItemId,
});

//перезагрузка данных после нажатия на кнопку workflow, либо при изменении id в URL, при первом входе на страницу
sample({
    clock: [RawDocumentPageGate.state, updateTaskFx.doneData, acceptTaskFx.doneData],
    source: RawDocumentPageGate.state,
    filter: (gateState) => typeof gateState === 'string',
    target: fetchRawDocumentData,
});

//скачивание файла
forward({
    from: downloadAttachmentFile,
    to: downloadAttachmentFileFx,
});

//Загрузка доступных задач при открытии страницы и Перезагрузка актуальных кнопок workflow после нажатия на кнопку
sample({
    clock: [
        RawDocumentPageGate.state,
        updateTaskFx.doneData,
        acceptTaskFx.doneData,
        cancelTaskFx.doneData,
    ],
    source: RawDocumentPageGate.state,
    filter: (gateState) => typeof gateState === 'string',
    target: fetchAvailableTasksFx,
});

//после отработки первого запроса на доступные задачи, идет запрос на кнопки
sample({
    source: $availableTasksData,
    filter: (source) => source.length > 0,
    fn: (source) => source[0].id,
    target: fetchWorkflowButtonsFx,
});

//после нажатия на кнопку сток кнопок ресетится
sample({
    clock: [updateTaskFx.doneData, cancelTaskFx.doneData],
    filter: (clock) => clock?.status === 200,
    fn: () => null,
    target: $workflowButtonsData,
});

//Нажатие на workflow кнопку
sample({
    clock: handleWorkflowBtn,
    source: $workflowButtonsData,
    filter: (source) => !!source?.data,
    fn: (buttonsData, index) => ({
        id: buttonsData?.data.id ?? '',
        actionId: buttonsData?.data.actions[index].id ?? '',
    }),
    target: updateTaskFx,
});

//нажатие на кнопку "взять в работу"
sample({
    clock: handleAcceptWorkflowBtn,
    target: acceptTaskFx,
});

sample({
    clock: handleCancelWorkflowBtn,
    target: cancelTaskFx,
});

// отображение/скрытие кнопки "сохранить коммент" в зависимости от кнопок воркфлоу
sample({
    source: combine({
        buttonsData: $workflowButtonsData,
        isVK: $isUserVK,
    }),
    fn: (source) => (source.buttonsData?.data.actions.length ?? -1) > 0 && source.isVK,
    target: $isSendButtonHide,
});

//Нажатие на кнопку "На доработку"
sample({
    clock: sendComment,
    source: combine({
        docData: $rawDocumentData,
        comment: $comment,
        isValid: $commentIsValid,
        isCommentAvailable: $isSendButtonHide,
        isCommentError: $isCommentError,
    }),
    filter: ({isValid}, clock) => isValid && !clock.isPositive,
    fn: ({docData, comment}) => ({
        id: docData?.id ?? '',
        comment: comment,
    }),
    target: sendCommentFx,
});

//Нажатие на кнопку "сохранить" коммент
sample({
    clock: sendNewComment,
    source: combine({
        docData: $rawDocumentData,
        comment: $comment,
    }),
    filter: ({comment}) => comment != '',
    fn: ({docData, comment}) => ({
        id: docData?.id ?? '',
        comment: comment,
    }),
    target: addCommentFx,
});

// обновление карточки после отправки коммента
sample({
    clock: addCommentFx.doneData,
    filter: (response) => response?.status === 200,
    fn: (response) => response?.data,
    target: [commentHasSent, $rawDocumentData],
});

//Нажатие на кнопку "согласовать" без комментария
sample({
    clock: sendComment,
    source: combine({
        docData: $rawDocumentData,
        comment: $comment,
        buttonsData: $workflowButtonsData,
        index: $pushedButtonIndex,
    }),
    filter: (source, clock) => clock.isPositive && !source.comment,
    fn: ({buttonsData, index}) => ({
        id: buttonsData?.data.id ?? '',
        actionId: buttonsData?.data.actions[index].id ?? '',
    }),
    target: updateTaskFx,
});

// Нажатие на кнопку "согласовать" с комментарием
sample({
    clock: sendComment,
    source: combine({
        docData: $rawDocumentData,
        comment: $comment,
    }),
    filter: (source, clock) => clock.isPositive && !!source.comment,
    fn: ({docData, comment}) => ({
        id: docData?.id ?? '',
        comment: comment,
    }),
    target: sendCommentFx,
});

// Логика нажатия кнопок "На доработку" и "согласовать" ч.2 (сначала отправляется post коммента, после - нажатие)
sample({
    clock: sendCommentFx.doneData,
    source: combine({
        buttonsData: $workflowButtonsData,
        index: $pushedButtonIndex,
    }),
    filter: ({index}, clock) => clock?.status === 200 && index >= 0,
    fn: ({buttonsData, index}) => ({
        id: buttonsData?.data.id ?? '',
        actionId: buttonsData?.data.actions[index].id ?? '',
    }),
    target: updateTaskFx,
});

//сброс и скрытие textarea
sample({
    clock: updateTaskFx.doneData,
    target: resetTextArea,
});

// проверка валидности textarea
sample({
    source: $comment,
    fn: (source) => source.length > 0,
    target: $commentIsValid,
});

//состояние ошибки textarea
sample({
    source: combine({
        isValid: $commentIsValid,
        isTouched: $buttonIsTouched,
    }),
    fn: ({isValid, isTouched}) => (isTouched ? !isValid : false),
    target: $isCommentError,
});

// "включение" проверки валидности после нажатия на любую воркфлоу кнопку
sample({
    clock: [sendComment],
    fn: (clock) => {
        if (typeof clock === 'object') {
            return !clock.isPositive;
        }
        return true;
    },
    target: $buttonIsTouched,
});

// сброс проверки валидности textarea после успешного workflow
sample({
    clock: [sendCommentFx.doneData, addCommentFx.doneData],
    fn: () => false,
    target: [$isCommentError, $buttonIsTouched],
});

// dev вызов конструктора редактирвания логистики
sample({
    clock: editRequestClicked,
    source: $rawDocumentData,
    filter: (source) => !!source,
    target: requestEditingInvoked,
});

//сброс домена после ухода со страницы
resetDomainStoresByEvents(RawDocumentPageDomain, RawDocumentPageGate.close);
