import React from 'react';
import {Link} from 'react-router-dom';
import {IconButton} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {ReplayBadge} from 'Entities/ReplayBadge';
import {MultiAvatar} from 'Entities/MultiAvatar';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import Label from 'shared/ui/deprecated/Label/Label';
import {hasLinkPermission, userHasPermission} from 'shared/helpers/accessCheckers';
import {createLink} from 'shared/helpers/links';
import {formatDate} from 'shared/helpers/formatHelper';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {IColumn, IExpandedColumn} from 'shared/types/tableTypes';
import {IDetailedTask, TListedProcess} from 'shared/types/processesTypes';
import {EAccessActions} from 'shared/const/actions';

import * as S from '../style';
import {setOpenId} from '../../../model';
import {TaskStatusBadge, TaskStatusTitle} from '../../../../DSPage/types';

export const processesColumns: IExpandedColumn<TListedProcess>[] = [
    {
        name: 'toggle',
        width: '32px',
        render: ({id}, _, isOpen) => (
            <IconButton
                iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown}
                size="medium"
                onClick={() => setOpenId(id)}
            />
        ),
        noHiding: true,
    },
    {
        name: 'name',
        label: 'Наименование',
        hasSorting: true,
        render: ({name}) => (
            <TooltipedSpan title={name} lineCount={2}>
                {name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'project__project_ext_id',
        label: 'Проект',
        hasSorting: true,
        render: ({project_bs}, userActions) => {
            const hasLink =
                userActions && userHasPermission(EAccessActions.ProjectCard, userActions);
            return (
                <S.Cell>
                    {hasLink ? (
                        <Link to={`/projects/${project_bs.id}`}>{project_bs.project_number}</Link>
                    ) : (
                        <span>{project_bs.project_number}</span>
                    )}
                </S.Cell>
            );
        },

        width: '90px',
    },
    {
        name: 'project__bs_number',
        label: 'Базовая станция',
        hasSorting: true,
        render: ({project_bs}) => (
            <TooltipedSpan title={`${project_bs.bs_number} ${project_bs.bs_name}`} lineCount={2}>
                {project_bs.bs_number}
                <br />
                {project_bs.bs_name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'subtypes_document__name',
        label: 'Тип документа',
        hasSorting: true,
        render: ({object}) => (
            <TooltipedSpan title={object.name} lineCount={2}>
                {object.name}
            </TooltipedSpan>
        ),
        width: '162px',
    },
    {
        name: 'object_number',
        label: '№ документа',
        hasSorting: true,
        render: ({object}, userActions) => {
            const link = createLink(object.type_object, object.id);
            return (
                <TooltipedSpan title={object.number.toString()} lineCount={2}>
                    {userActions && hasLinkPermission(object.type_object, userActions) ? (
                        <Link to={link}>{object.number}</Link>
                    ) : (
                        object.number
                    )}
                </TooltipedSpan>
            );
        },
        width: '242px',
    },
    {
        name: 'documentStatus',
        label: 'Статус документа',
        render: ({object: {status}}) => {
            return (
                status && (
                    <Label outline variant={getLabelType(status.token)}>
                        {status.name}
                    </Label>
                )
            );
        },
        width: '206px',
    },
    {
        name: 'created_at',
        label: 'Создано',
        hasSorting: true,
        render: ({created_at}) => <span>{formatDate(created_at)}</span>,

        width: '110px',
    },
    {
        name: 'approved_at',
        label: 'Завершено',
        hasSorting: true,
        render: ({approved_at}) => <span>{formatDate(approved_at)}</span>,
        width: '110px',
    },
    {
        name: 'region',
        label: 'Регион',
        hasSorting: true,
        render: ({region}) => <span>{region}</span>,
        width: '110px',
    },
    {
        name: 'branch',
        label: 'Филиал',
        hasSorting: true,
        render: ({branch}) => <span>{branch}</span>,
        width: '130px',
    },
];

export const innerProcessesColumns: IColumn<IDetailedTask>[] = [
    {
        name: 'inner_name',
        label: 'Наименование задачи',
        render: ({name}) => (
            <TooltipedSpan title={name} lineCount={2}>
                {name}
            </TooltipedSpan>
        ),
        width: '300px',
    },
    {
        name: 'inner_replay',
        label: '',
        render: ({replay}) => <ReplayBadge count={replay} />,
        width: '84px',
    },
    {
        name: 'inner_executor',
        label: 'Исполнитель',
        render: ({executor, user, co_users}) => {
            return <MultiAvatar executor={executor} user={user} co_users={co_users} />;
        },
        width: '206px',
    },
    {
        name: 'inner_createdAt',
        label: 'Создано',
        render: ({created_at}) => <span>{formatDate(created_at)}</span>,

        width: '110px',
    },
    {
        name: 'inner_approvedAt',
        label: 'Завершено',
        render: ({approved_at}) => <span>{formatDate(approved_at)}</span>,
        width: '110px',
    },
    {
        name: 'inner_task_number',
        label: '№ задачи',
        render: ({number}) => <span>{number}</span>,
        width: '110px',
    },
    {
        name: 'inner_status',
        label: 'Статус задачи',
        render: ({status}) => {
            return (
                status && (
                    <Label
                        variant={TaskStatusBadge[status]}
                        title={TaskStatusTitle[status]}
                        outline
                    >
                        {TaskStatusTitle[status]}
                    </Label>
                )
            );
        },
        width: '205px',
    },
];
