import React from 'react';

import {InformationalUnitSkeleton} from 'Entities/InformationalUnit';

import * as S from '../style';

export const InfoUnitSkeleton = () => {
    return (
        <S.InfoUnitGrid>
            <InformationalUnitSkeleton gridArea="createdDate" />
            <InformationalUnitSkeleton gridArea="plannedDate" />
            <InformationalUnitSkeleton gridArea="contractor" />
            <InformationalUnitSkeleton gridArea="address" />
            <InformationalUnitSkeleton gridArea="region" />
            <InformationalUnitSkeleton gridArea="city" />
        </S.InfoUnitGrid>
    );
};
