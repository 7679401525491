import styled from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const InfoUnitGrid = styled.div`
    display: grid;
    gap: var(--size-spacing-x6);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 'expiryRange number KZP KZP';
`;

export const StyledInnerTableTitle = styled(Typography)`
    padding-bottom: var(--size-spacing-x2);
`;

export const InnerTableWrapper = styled.div`
    width: 100%;
    margin-bottom: var(--size-spacing-x2);
`;
