import React from 'react';
import {Label, Typography} from '@beeline/design-system-react';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {Breadcrumbs} from 'widgets/Breadcrumbs';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {EAccessActions} from 'shared/const/actions';
import {TBreadcrumb} from 'shared/types/breadcrumbTypes';
import {IDocument} from 'shared/types/documentTypes';
import {EQUIPMENT_REQUEST_TITLE, FROM_TITLE} from 'shared/constants';
import {formatDate, formatDateHours} from 'shared/helpers/formatHelper';
import {getLabelType} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';

import {HeaderSkeleton} from './skeletons/HeaderSkeleton';

const getBreadcrumbsData = (projectId: string, projectNumber: number): TBreadcrumb[] => [
    {
        name: 'Проекты',
        skeletonWidth: 100,
    },
    {
        name: `Проект ${projectNumber}`,
        access: EAccessActions.ProjectCard,
        link: `/projects/${projectId}`,
        skeletonWidth: 100,
    },
    {
        name: `Запрос на требование`,
        skeletonWidth: 100,
    },
];

interface RequestViewHeaderUnitProps {
    data: IDocument | null;
    isLoading: boolean;
    children?: React.ReactNode;
}

export const RequestViewHeaderUnit = ({children, data, isLoading}: RequestViewHeaderUnitProps) => {
    if (!data) return <HeaderSkeleton />;
    const {
        status,
        bs: {gfk, project_number, id},
        created_at,
        creator,
        editor,
        is_urgent,
        organization_gpo,
        number,
        updated_at,
    } = data;
    return (
        <WrapperFlex flexDirection="column" gap="12px">
            <Breadcrumbs data={getBreadcrumbsData(id, project_number)} isLoading={isLoading} />
            <WrapperFlex justifyContent="space-between">
                <WrapperFlex gap="16px" alignItems="center">
                    <Typography variant="h4">{`${EQUIPMENT_REQUEST_TITLE} ${number} ${FROM_TITLE} ${formatDate(
                        created_at,
                    )} ${organization_gpo}`}</Typography>
                    <Label title={status.name} type={getLabelType(status.token) as AllStatuses} />
                </WrapperFlex>
                {children}
            </WrapperFlex>
            <Typography variant="caption">
                {`Создал ${creator.short}, редактировал ${editor.short} ${formatDateHours(
                    updated_at.toString(),
                )}`}
            </Typography>
            <WrapperFlex alignItems="center" gap="16px" mt="20px">
                <Typography variant="h5">{gfk}</Typography>
                {is_urgent && <Label title="Срочный запрос" type="warning" variant="contained" />}
            </WrapperFlex>
        </WrapperFlex>
    );
};
