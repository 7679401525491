import {axiosInstance} from 'shared/api/api';
import {AdditionalAgreementFull} from 'pages/DSPage/types';
import {
    AdditionalAgreementsParams,
    IAdditionalAgreementListItem,
    IAgreementCopingWorksheet,
    ICopingAdditionalAgreement,
    TCopyingAdditionalAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {IPaginationResponse} from 'shared/types/apiTypes';

import {TGetWorksheetListForCopingParams} from './types/agreementParamsTypes';

export const getAllAdditionalAgreements = (params: AdditionalAgreementsParams) => {
    return axiosInstance.get<IPaginationResponse<IAdditionalAgreementListItem>>(`/DS/agreements/`, {
        params,
    });
};

export const fetchAgreementFile = (id: string) => {
    return axiosInstance.get(`/DS/agreements/${id}/agreement_file/`, {
        responseType: 'blob',
        timeout: 200000,
    });
};

export const fetchDiadocArchive = (id: string) => {
    return axiosInstance.get(`/DS/agreements/${id}/diadoc_archive/`, {
        responseType: 'blob',
        timeout: 200000,
    });
};

export function getDSPrintFiles(id) {
    return axiosInstance.get(`/DS/${id}/print/files/`);
}

export function postApproveVirItem(data: {id: string; action: boolean | null}) {
    const {id, action} = data;

    return axiosInstance.post(`DS/work_specifications/${id}/approve`, {
        approved: action,
    });
}

export function postAgreementComment(data: {id: string; comment: string}) {
    return axiosInstance.post(`DS/agreements/${data.id}/add_comment/`, {
        comment: data.comment,
    });
}

export const getJobCategoriesList = () => {
    return axiosInstance.get(`catalogs/work_categories/`);
};

export const getAdditionalAgreementCopy = (id: string) => {
    return axiosInstance.get<AdditionalAgreementFull>(`DS/agreements/${id}/copy/`);
};

export const getAdditionalAgreementListToCopy = (id: string) => {
    return axiosInstance.get<TCopyingAdditionalAgreement[]>(`DS/agreements/available/`, {
        params: {
            project_id: id,
        },
    });
};

export const getAdditionalAgreementListForCopingWorksheets = (id: string, search: string) => {
    return axiosInstance.get<IPaginationResponse<ICopingAdditionalAgreement>>(
        `DS/agreements/copy_available/`,
        {
            params: {
                contract_id: id,
                search: search,
            },
        },
    );
};

export const fetchWorksheetListForCoping = (params: TGetWorksheetListForCopingParams) => {
    const {id, remoteTerritoryId, tpiId, projectId} = params;
    return axiosInstance.get<IAgreementCopingWorksheet>(`DS/agreements/copy_available/${id}/`, {
        params: {
            project_id: projectId,
            tpi_id: tpiId,
            remote_territory_id: remoteTerritoryId,
        },
    });
};

export const fetchSignerActualization = (id: string) => {
    return axiosInstance.get<{message: string}>(`DS/agreements/${id}/signer_actualization/`);
};
