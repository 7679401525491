import React from 'react';
import {Button, Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {ColorTypes} from '@beeline/design-system-react/types/types/status';

import * as S from './style';

interface EmptyTabProps {
    title: string;
    subtitle: string;
    showButton: boolean;
    buttonTitle: string;
    onClick: () => void;
    iconName?: Icons;
    color?: ColorTypes;
}

export const EmptyTab = ({
    iconName,
    color,
    showButton,
    title,
    subtitle,
    buttonTitle,
    onClick,
}: EmptyTabProps) => {
    return (
        <S.EmptyWrapper>
            {!!iconName && !!color && (
                <Icon iconName={iconName} color={color} size="medium" contained />
            )}
            <S.StyledTypography variant="h6" margin="24px 0 8px">
                {title}
            </S.StyledTypography>
            {showButton && (
                <>
                    <S.StyledTypography variant="body2" className="inactiveText" margin="0 0 24px">
                        {subtitle}
                    </S.StyledTypography>
                    <Button size="medium" onClick={() => onClick()}>
                        {buttonTitle}
                    </Button>
                </>
            )}
        </S.EmptyWrapper>
    );
};
