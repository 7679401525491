import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore, useUnit} from 'effector-react';
import {Option, Typography} from '@beeline/design-system-react';

import {DocumentTable} from 'widgets/DocumentTable';
import {DocumentCreationButton} from 'Features/buttons/DocumentCreationButton';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserGpo} from 'shared/model/user';
import {EDocumentType} from 'shared/types/documentTypes';
import {IPaginationData} from 'shared/types/commonTypes';
import {NO_RESULT_TITLE} from 'shared/constants';

import * as S from './style';
import {columns} from './columns';
import {
    changeDocumentTypeSearchValue,
    changePrimeContractorSearchValue,
    changeSelectedDocumentType,
    changeSelectedPrimeContractor,
    ChangingSelectValueType,
    DocumentTableGate,
    documentTableStore,
    resetAllSelects,
    resetDocumentTypeSearchValue,
    resetPrimeContractorSearchValue,
    rowExpanded,
    setPage,
    setPageSize,
} from '../../../../model/documentTab';

export const DocumentTab = () => {
    const {projectId} = useParams<{projectId: string}>();

    useGate(DocumentTableGate, projectId);

    const {
        documentsStore,
        primeContractorsStore,
        documentTypesStore,
        paginationCount,
        tableIsLoading,
        documentListParams: {page, page_size, selectedDocumentType, selectedPrimeContractor},
        primeContractorSearchValue,
        documentTypeSearchValue,
        expandedRowList,
        tableAllowed,
        creationAllowed,
    } = useStore(documentTableStore);
    const [isUserGpo] = useUnit([$isUserGpo]);

    const handleChangePrimeContractor = (
        value: Option<String>[],
        targetItem: Option<String> | null,
        checked: boolean,
    ) => {
        changeSelectedPrimeContractor({value, targetItem, checked} as ChangingSelectValueType);
    };

    const handleChangeDocumentType = (
        value: Option<String>[],
        targetItem: Option<String> | null,
        checked: boolean,
    ) => {
        changeSelectedDocumentType({value, targetItem, checked} as ChangingSelectValueType);
    };

    const paginationData: IPaginationData = {
        total: paginationCount,
        isLoading: tableIsLoading,
        page,
        pageSize: page_size,
        setPage,
        setPageSize,
    };
    const visibleColumns = isUserGpo ? columns.filter((col) => col.name !== 'contractor') : columns;
    return (
        <>
            <S.SelectWrapper>
                {creationAllowed && <DocumentCreationButton type={EDocumentType.DOCUMENTS} />}
                {!isUserGpo && tableAllowed && (
                    <S.PrimeContractorWrapper>
                        <Select
                            value={selectedPrimeContractor}
                            valueKey="id"
                            valueLabel="value"
                            title="ГПО"
                            handleEdit={handleChangePrimeContractor}
                            searchValue={primeContractorSearchValue}
                            setSearch={changePrimeContractorSearchValue}
                            notAll={primeContractorsStore.length >= 0}
                            handleResetSearch={resetPrimeContractorSearchValue}
                            search
                            multiple
                            fullWidth
                            fullWidthList
                        >
                            {primeContractorsStore.length > 0 ? (
                                primeContractorsStore.map((item) => (
                                    <SelectItem
                                        key={item.id as string}
                                        value={item}
                                        multiple={false}
                                    >
                                        {item.value}
                                    </SelectItem>
                                ))
                            ) : (
                                <WrapperFlex justifyContent="center">
                                    <Typography variant="subtitle3">Нет результатов</Typography>
                                </WrapperFlex>
                            )}
                        </Select>
                    </S.PrimeContractorWrapper>
                )}
                {tableAllowed && (
                    <S.TypeWrapper>
                        <Select
                            value={selectedDocumentType}
                            valueKey="id"
                            valueLabel="value"
                            title="Тип документа"
                            handleEdit={handleChangeDocumentType}
                            searchValue={documentTypeSearchValue}
                            setSearch={changeDocumentTypeSearchValue}
                            notAll={documentTypesStore.length >= 0}
                            handleResetSearch={resetDocumentTypeSearchValue}
                            search
                            multiple
                            fullWidth
                            fullWidthList
                        >
                            {documentTypesStore.length > 0 ? (
                                documentTypesStore.map((item) => (
                                    <SelectItem
                                        key={item.id as string}
                                        value={item}
                                        multiple={false}
                                    >
                                        {item.value}
                                    </SelectItem>
                                ))
                            ) : (
                                <WrapperFlex justifyContent="center">
                                    <Typography variant="subtitle3">{NO_RESULT_TITLE}</Typography>
                                </WrapperFlex>
                            )}
                        </Select>
                    </S.TypeWrapper>
                )}
            </S.SelectWrapper>
            {tableAllowed && (
                <DocumentTable
                    columns={visibleColumns}
                    onRowClick={rowExpanded}
                    data={documentsStore}
                    expandedRowList={expandedRowList}
                    paginationData={paginationData}
                    onResetData={resetAllSelects}
                />
            )}
        </>
    );
};
