import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button} from '@beeline/design-system-react';

import {Content} from 'pages/ProjectPage/style';
import {CommonLayout} from 'shared/ui/CommonLayout';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {RequestViewHeaderUnit} from './RequestViewHeaderUnit';
import {RequestViewInfoUnit} from './RequestViewInfoUnit';
import {$requestViewPageStore, editRequestClicked, RequestViewPageGate} from '../model';

export const RequestViewPage = () => {
    const {id} = useParams<{id: string}>();
    useGate(RequestViewPageGate, id);
    const {requestData, isLoading} = useStore($requestViewPageStore);

    return (
        <CommonLayout>
            <Content>
                <WrapperFlex padding="32px" flexDirection="column" gap="24px" width="100%">
                    <RequestViewHeaderUnit data={requestData} isLoading={!requestData || isLoading}>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={() => editRequestClicked()}
                        >
                            Редактировать
                        </Button>
                    </RequestViewHeaderUnit>
                    <RequestViewInfoUnit data={requestData} isLoading={isLoading} />
                </WrapperFlex>
            </Content>
        </CommonLayout>
    );
};
