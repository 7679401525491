import React from 'react';
import {useStore} from 'effector-react';
import {TableBody, TableRow, Typography} from '@beeline/design-system-react';

import {TableHeader} from 'Features/TableHeader';
import {TableBase} from 'Entities/TableBase';
import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {CustomTableData, ExpandedStyledInnerTableData, InnerTable} from 'shared/styles/commonStyle';
import {LOTS_AND_BRANCHES_TITLE, LOTS_TITLE} from 'shared/constants';
import {IContractPurchasingEvent} from 'shared/types/purchasesTypes';

import {InnerTableWrapper, StyledInnerTableTitle} from './style';
import {lotColumns, purchaseEventColumns} from './columns';
import {$contractViewPageStore, $expandedRowId, tableRowToggled} from '../model';

export const ContractViewTable = () => {
    const expandedRowId = useStore($expandedRowId);
    const {contractData, isLoading, loadingTpiIdList} = useStore($contractViewPageStore);

    return (
        <WrapperFlex flexDirection="column" gap="16px">
            <WrapperFlex justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{LOTS_AND_BRANCHES_TITLE}</Typography>
            </WrapperFlex>
            <TableBase minTableWidth="700px">
                <TableHeader<IContractPurchasingEvent> columns={purchaseEventColumns} />
                {!contractData || isLoading ? (
                    <TableBodySkeleton<IContractPurchasingEvent>
                        pageSize={5}
                        columns={purchaseEventColumns}
                        height="52px"
                        dense
                    />
                ) : (
                    <TableBody>
                        {contractData.purchasing_events.map((event) => (
                            <React.Fragment key={event.id}>
                                <TableRow onClick={() => tableRowToggled(event.id)} hover>
                                    {purchaseEventColumns.map((column) => (
                                        <CustomTableData
                                            width={column.width}
                                            key={`${event.id}${column.name}`}
                                        >
                                            {column.render &&
                                                column.render(
                                                    event,
                                                    [],
                                                    expandedRowId === event.id,
                                                )}
                                        </CustomTableData>
                                    ))}
                                </TableRow>
                                {expandedRowId === event.id && (
                                    <TableRow>
                                        <ExpandedStyledInnerTableData colSpan={4}>
                                            <InnerTableWrapper>
                                                <StyledInnerTableTitle variant="h6">
                                                    {LOTS_TITLE}
                                                </StyledInnerTableTitle>
                                                <InnerTable>
                                                    <TableHeader columns={lotColumns} inner />
                                                    <TableBody>
                                                        {event.lots.map((lot) => (
                                                            <TableRow key={lot.id} dense>
                                                                {lotColumns.map((lotColumn) => (
                                                                    <CustomTableData
                                                                        key={`inner_${lotColumn.name}${lot.id}`}
                                                                        width={lotColumn.width}
                                                                        height="52px"
                                                                    >
                                                                        {lotColumn.render &&
                                                                            lotColumn.render(
                                                                                lot,
                                                                                {
                                                                                    tpi_id:
                                                                                        lot.tpis
                                                                                            ?.id ??
                                                                                        '',
                                                                                    gpo_short_name:
                                                                                        contractData.gpo_short_name,
                                                                                    lot_name:
                                                                                        lot.name,
                                                                                    pe_number:
                                                                                        event.number,
                                                                                },
                                                                                loadingTpiIdList,
                                                                            )}
                                                                    </CustomTableData>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </InnerTable>
                                            </InnerTableWrapper>
                                        </ExpandedStyledInnerTableData>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                )}
            </TableBase>
        </WrapperFlex>
    );
};
