import React from 'react';
import {useGate, useUnit} from 'effector-react';
import {Button, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {TableWithSort} from 'widgets/TableWithSort';
import {EmptyTab} from 'Entities/EmptyTab';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {LOGISTIC_STAGE_TITLE} from 'shared/constants';
import {ITableData} from 'shared/types/tableTypes';
import {TDocumentsListItem} from 'shared/types/documentTypes';

import {logisticColumns} from './columns';
import {LOGISTIC_STAGE_EMPTY_TITLE} from '../../../../constants';
import {
    $logisticTabStore,
    LogisticsStageGate,
    pageChanged,
    pageSizeChanged,
    tableReset,
} from '../../../../model/logisticsStage';

interface LogisticTabProps {
    projectId: string;
}

export const LogisticsStage = ({projectId}: LogisticTabProps) => {
    useGate(LogisticsStageGate, projectId);
    const {logisticsList, isLoading, count, tableParams} = useUnit($logisticTabStore);
    const dataObject: ITableData<TDocumentsListItem> = {
        count: count ?? 0,
        data: logisticsList,
        page: tableParams.page,
        isLoading,
        pageSize: tableParams.page_size,
    };
    const tableIsEmpty = !isLoading && count === 0;
    return (
        <div>
            <Typography variant="h5">{LOGISTIC_STAGE_TITLE}</Typography>
            {tableIsEmpty ? (
                <EmptyTab
                    iconName={Icons.PageEmpty}
                    showButton={false}
                    color="blue"
                    title={LOGISTIC_STAGE_EMPTY_TITLE}
                    subtitle={''}
                    buttonTitle={''}
                    onClick={() => 1}
                />
            ) : (
                <WrapperFlex flexDirection="column" margin="16px 0 0" gap="24px">
                    <WrapperFlex gap="24px">
                        <Button size="medium">Создать запрос БС</Button>
                        <Button
                            size="medium"
                            onClick={() =>
                                navigationInvoked({
                                    to: `projects/${projectId}/logistic-creation`,
                                })
                            }
                        >
                            Создать запрос ТС
                        </Button>
                    </WrapperFlex>
                    <TableWithSort<TDocumentsListItem>
                        columns={logisticColumns}
                        dataObject={dataObject}
                        onChangePage={pageChanged}
                        onChangePageSize={pageSizeChanged}
                        onReset={tableReset}
                    />
                </WrapperFlex>
            )}
        </div>
    );
};
