import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore, useUnit} from 'effector-react';
import {Progress} from '@beeline/design-system-react';

import {PageInfoUnit} from 'widgets/PageInfoUnit';
import {DsCommentsBlock} from 'widgets/DsCommentsBlock';
import {CommonLayout} from 'shared/ui/CommonLayout';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {EDMC_TEMPLATES_TITLE, EDMC_TITLE} from 'shared/constants';

import {ClosingDocumentsCreation} from './ClosingDocumentsCreation';
import {PoNumberCreation} from './PoNumberCreation';
import {PageTitle} from './PageTitle';
import {EdmsWidget} from './widgets/EDMSWidget';
import {CompletedWorksAccordion} from './CompletedWorksAccordion/CompletedWorksAccordion';
import {
    $completedWorksViewPageStore,
    $completedWorksWorkflowStore,
    $pageMode,
    commentSent,
    CompletedWorksViewGate,
    pageModeChanged,
} from '../model';
import {ECompletedWorksView, handleDownloadAttachment} from '../model/helpers';
import {PaymentWidget} from './widgets/PaymentWidget';

export const CompletedWorksViewPage = () => {
    const {id} = useParams<{id: string}>();
    useGate(CompletedWorksViewGate, id);
    const [{completedWorksSheet}, pageMode, {taskData}] = useUnit([
        $completedWorksViewPageStore,
        $pageMode,
        $completedWorksWorkflowStore,
    ]);
    if (!id) return null;
    const currentAddendumNumber = completedWorksSheet?.current_agreement?.number_addendum ?? null;
    const renderPage = () => {
        switch (pageMode) {
            case ECompletedWorksView.normal: {
                return <CompletedWorksView />;
            }
            case ECompletedWorksView.kzd: {
                return (
                    <ClosingDocumentsCreation
                        id={id}
                        taskData={taskData}
                        handleClose={() => pageModeChanged(ECompletedWorksView.normal)}
                    />
                );
            }
            case ECompletedWorksView.po: {
                return (
                    <PoNumberCreation
                        addendumNumber={currentAddendumNumber}
                        id={id}
                        taskData={taskData}
                        mode={pageMode}
                        handleClose={() => pageModeChanged(ECompletedWorksView.normal)}
                    />
                );
            }
            case ECompletedWorksView.mandatoryPo: {
                return (
                    <PoNumberCreation
                        addendumNumber={currentAddendumNumber}
                        id={id}
                        taskData={taskData}
                        mode={pageMode}
                        handleClose={() => pageModeChanged(ECompletedWorksView.normal)}
                    />
                );
            }
        }
    };

    return (
        <CommonLayout>
            <WrapperOffset offset={32}>{renderPage()}</WrapperOffset>
        </CommonLayout>
    );
};

const CompletedWorksView = () => {
    const {
        completedWorksSheet,
        activeSheet,
        attachmentLoading,
        currentCompletion,
        prevCompletion,
        widgetUnitShown,
    } = useStore($completedWorksViewPageStore);
    if (!completedWorksSheet) return CompletedWorksProgress();

    const {
        agreement,
        project,
        approved_at,
        created_at,
        gpo_short_name,
        ds_attachments,
        linked_entities: {vvr_edo, vvr_edo_templates, payment_info},
        purchase_lots_name,
        purchasing_event,
        status,
        contract_name,
        contract_id,
        completed_work_attachments,
        completed_work_comments,
    } = completedWorksSheet;
    return (
        <WrapperFlex flexDirection="column" gap="36px">
            <PageTitle />
            <PageInfoUnit
                agreement={agreement}
                project={project}
                purchasing_event={purchasing_event}
                approved_at={approved_at}
                created_at={created_at}
                contract_name={contract_name}
                contract_id={contract_id}
                gpo_short_name={gpo_short_name}
                purchase_lots_name={purchase_lots_name}
                currentCompletion={currentCompletion}
                prevCompletion={prevCompletion}
            />
            {widgetUnitShown && (
                <WrapperFlex gap="32px" flexWrap="wrap">
                    <EdmsWidget data={vvr_edo_templates} title={EDMC_TEMPLATES_TITLE} />
                    <EdmsWidget data={vvr_edo} title={EDMC_TITLE} />
                    <PaymentWidget data={payment_info} />
                </WrapperFlex>
            )}
            <CompletedWorksAccordion
                data={completedWorksSheet}
                status={status}
                activeSheet={activeSheet}
            />
            <DsCommentsBlock
                handleSaveComment={commentSent}
                handleDownloadAttachment={handleDownloadAttachment}
                loadingAttachmentId={attachmentLoading}
                commentList={completed_work_comments}
                attachmentList={completed_work_attachments}
                parentAttachmentList={ds_attachments}
            />
        </WrapperFlex>
    );
};

const CompletedWorksProgress = () => (
    <WrapperFlex width="100%" justifyContent="center" alignItems="center" height="80vh">
        <Progress shape="circle" value={37} cycled />
    </WrapperFlex>
);
