import {Checkbox} from '@beeline/design-system-react';

import {QuantityCell} from 'Features/TableCells/QuantityCell';
import {CommentButton} from 'Features/buttons/CommentButton';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {IColumn} from 'shared/types/tableTypes';

import {itemCommentRowToggled, itemToggled, requestedQuantityChanged} from '../model';
import {IAdaptedConsumerOrderItem} from '../model/helpers';

export const columns: IColumn<IAdaptedConsumerOrderItem>[] = [
    {
        name: 'choice',
        label: <Checkbox />,
        width: '56px',
        render: ({checked, consume_order_item_id}) => (
            <Checkbox
                checked={checked}
                onChange={(event) =>
                    itemToggled({
                        id: consume_order_item_id,
                        checked: event.currentTarget.checked,
                    })
                }
            />
        ),
    },
    {
        name: 'nomenclature',
        label: 'Номенклатура',
        width: '320px',
        render: ({name}) => (
            <TooltipedSpan title={name} lineCount={2}>
                {name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'nfcCode',
        label: 'Код НФС',
        width: '96px',
        render: ({nfs_id}) => (
            <TooltipedSpan title={nfs_id} lineCount={2}>
                {nfs_id}
            </TooltipedSpan>
        ),
    },
    {
        name: 'measurement',
        label: 'Ед. изм.',
        width: '96px',
        render: ({unit: {name}}) => name,
    },
    {
        name: 'plannedQuantity',
        label: 'План. кол-во',
        render: ({planned_quantity}) => planned_quantity,
        width: '112px',
    },
    {
        name: 'requested',
        label: 'Треб. кол-во',
        width: '120px',
        render: ({consume_order_item_id, required_quantity, planned_quantity}) => (
            <QuantityCell
                value={required_quantity}
                maxValue={planned_quantity}
                onChange={(num) =>
                    requestedQuantityChanged({id: consume_order_item_id, quantity: num})
                }
                isEditable
            />
        ),
    },
    {
        name: 'Approved',
        label: 'Согласовано',
        width: '120px',
        render: ({agreed_quantity}) => agreed_quantity,
    },
    {
        name: 'Issued',
        label: 'Выдано',
        width: '100px',
        render: ({relocation_order_quantity}) => relocation_order_quantity,
    },
    {
        name: 'znpNumber',
        label: '№ ЗнП',
        width: '216px',
        render: ({relocation_order_number}) => relocation_order_number,
    },
    {
        name: 'comment',
        label: 'Комментарий',
        width: '120px',
        render: ({comment, consume_order_item_id}) => (
            <CommentButton
                onClick={() => itemCommentRowToggled(consume_order_item_id)}
                hasComments={!!comment}
            />
        ),
    },
];
