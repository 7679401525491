import {
    IConsumerOrder,
    IConsumerOrderItem,
    IDocument,
    IDocumentSpecification,
} from 'shared/types/documentTypes';

export interface IAdaptedConsumerOrderItem extends IConsumerOrderItem {
    checked: boolean;
}

export type TAdaptedConsumerOrder = Omit<IConsumerOrder, 'items'> & {
    items: IAdaptedConsumerOrderItem[];
};

export type TAdaptedDocumentSpecification = {
    consume_orders: {
        consume_order: TAdaptedConsumerOrder;
    }[];
};

export type TAdaptedDocument = Omit<IDocument, 'specification'> & {
    specification: TAdaptedDocumentSpecification | null;
    comment?: string;
};
// тестирую
export type TAdaptedTestDocumentSpecification = TAdaptedConsumerOrder[];

// подготовка запроса для конструктора
export const adaptDocumentToAdaptedDocument = (
    document: IDocument | null,
): TAdaptedDocument | null => {
    if (!document) return null;
    return {
        ...document,
        specification: adaptSpecificationToCreationSpecification(document.specification),
    };
};

// подготовка запроса для отправки на бэк
export const mapAdaptedDocumentToDocument = (
    document: TAdaptedDocument | null,
): IDocument | null => {
    if (!document) return null;
    return {
        ...document,
        specification: adaptCreationSpecificationToSpecification(document.specification),
    };
};

export const adaptSpecificationToCreationSpecification = (
    specification: IDocumentSpecification | null,
): TAdaptedDocumentSpecification | null => {
    if (!specification) return null;
    return {
        consume_orders: specification.consume_orders.map((order) => ({
            consume_order: {
                ...order.consume_order,
                items: order.consume_order.items.map((item) => ({
                    ...item,
                    checked: item.required_quantity > 0 || !!item.comment,
                })),
            },
        })),
    };
};

export const adaptCreationSpecificationToSpecification = (
    specification: TAdaptedDocumentSpecification | null,
): IDocumentSpecification | null => {
    if (!specification) return null;
    return {
        consume_orders: specification.consume_orders.map((order) => ({
            consume_order: {
                ...order.consume_order,
                items: order.consume_order.items.map((item) => {
                    const result: IConsumerOrderItem = {
                        consume_order_item_id: item.consume_order_item_id,
                        comment: item.comment,
                        required_quantity: item.required_quantity,
                        planned_quantity: item.planned_quantity,
                        name: item.name,
                        unit: item.unit,
                        nfs_id: item.nfs_id,
                        agreed_quantity: item.agreed_quantity,
                        is_used: item.is_used,
                        gfk: item.gfk,
                        is_canceled: item.is_canceled,
                        relocation_order_number: item.relocation_order_number,
                        relocation_order_quantity: item.relocation_order_quantity,
                        vendor_id: item.vendor_id,
                    };
                    return result;
                }),
            },
        })),
    };
};

export const adaptSpecification = (
    specification: IDocumentSpecification | null,
): TAdaptedTestDocumentSpecification | null =>
    specification?.consume_orders.map((order) => ({
        ...order.consume_order,
        items: order.consume_order.items.map((item) => ({
            ...item,
            checked: true,
        })),
    })) ?? null;

export const mapFrontendSpecificationToBackendSpecification = (
    specification: TAdaptedDocumentSpecification | null,
): IDocumentSpecification | null => {
    if (!specification) return null;
    return {
        consume_orders: specification.consume_orders.map((order) => ({
            consume_order: {
                ...order.consume_order,
                items: order.consume_order.items.map((item) => {
                    const result: IConsumerOrderItem = {...item};
                    delete result['checked'];
                    return result;
                }),
            },
        })),
    };
};

export const adaptRequestForBackend = (data: TAdaptedDocument | null): IDocument | null => {
    if (!data) return null;
    return {
        ...data,
        specification: mapFrontendSpecificationToBackendSpecification(data.specification),
    };
};

export const changeItemChecked = (
    data: TAdaptedDocument | null,
    value: {
        id: number;
        checked: boolean;
    },
): TAdaptedDocument | null => {
    if (!data) return null;
    const {id, checked} = value;
    const resultData = {...data};
    const spec = resultData.specification;
    if (!!spec) {
        const resultOrder = spec.consume_orders.map((order) => ({
            consume_order: {
                ...order.consume_order,
                items: order.consume_order.items.map((item) => {
                    const result = {...item};
                    if (result.consume_order_item_id === id) {
                        result.checked = checked;
                        result.required_quantity = checked ? result.planned_quantity : 0;
                    }

                    return result;
                }),
            },
        }));
        resultData.specification = {consume_orders: resultOrder};
    }
    return resultData;
};

export const changeRequestedQuantity = (
    data: TAdaptedDocument | null,
    value: {
        id: number;
        quantity: number;
    },
): TAdaptedDocument | null => {
    if (!data) return null;
    const {id, quantity} = value;
    const resultData = {...data};
    const spec = resultData.specification;
    if (!!spec) {
        const resultOrder = spec.consume_orders.map((order) => ({
            consume_order: {
                ...order.consume_order,
                items: order.consume_order.items.map((item) => {
                    const result = {...item};
                    if (result.consume_order_item_id === id) {
                        result.required_quantity = quantity;
                        if (quantity !== 0) {
                            result.checked = true;
                        }
                    }
                    return result;
                }),
            },
        }));
        resultData.specification = {consume_orders: resultOrder};
    }
    return resultData;
};

export const changeIsUrgent = (
    data: TAdaptedDocument | null,
    isUrgent: boolean,
): TAdaptedDocument | null => {
    if (!data) return null;
    const resultData = {...data};
    resultData.is_urgent = isUrgent;
    return resultData;
};

export const changePlannedDate = (
    data: TAdaptedDocument | null,
    date: string | null,
): TAdaptedDocument | null => {
    if (!data) return null;
    const resultData = {...data};
    resultData.planned_date = date;
    return resultData;
};

export const changeDocumentComment = (
    data: TAdaptedDocument | null,
    comment: string,
): TAdaptedDocument | null => {
    if (!data) return null;
    const resultData = {...data};
    resultData.comment = comment;
    return resultData;
};

export const changeItemComment = (
    data: TAdaptedDocument | null,
    value: {
        id: number;
        comment: string;
    },
): TAdaptedDocument | null => {
    if (!data) return null;
    const {id, comment} = value;
    const resultData = {...data};
    const spec = resultData.specification;
    if (!!spec) {
        const resultOrder = spec.consume_orders.map((order) => ({
            consume_order: {
                ...order.consume_order,
                items: order.consume_order.items.map((item) => {
                    const result = {...item};
                    if (result.consume_order_item_id === id) {
                        result.comment = comment;
                        if (!!comment) {
                            result.checked = true;
                        } else {
                            if (result.required_quantity === 0) result.checked = false;
                        }
                    }
                    return result;
                }),
            },
        }));
        resultData.specification = {consume_orders: resultOrder};
    }
    return resultData;
};
