import styled, {css} from 'styled-components/macro';

export const TabItem = styled.div<{active?: boolean; disabled?: boolean}>`
    border-radius: var(--size-border-radius-x6);
    padding: var(--size-spacing-x3);
    cursor: pointer;

    :hover {
        background: var(--color-background-base-hover);
    }

    ${(props) =>
        props.active &&
        css`
            background: var(--color-background-base-selected);
        `}
    ${(props) =>
        props.disabled &&
        css`
            cursor: auto;

            :hover {
                background: none;
            }

            p {
                color: var(--color-text-disabled);
            }
        `}
`;
