import {ETaskType} from '../types/taskTypes';
import {EDocumentType} from '../types/documentTypes';
import {
    EAccessActions,
    EObjectAccessActions,
    TAccessActions,
    TRouteAccessActions,
} from '../const/actions';

export const hasLinkPermission = (mode: ETaskType, userActions: TAccessActions): boolean => {
    switch (mode) {
        case ETaskType.JOB: {
            return userHasPermission(EAccessActions.JobTaskCard, userActions);
        }
        case ETaskType.DOCUMENT: {
            return userHasPermission(
                {permission: EObjectAccessActions.DocumentsCard, type: EDocumentType.DOCUMENTS},
                userActions,
            );
        }
        case ETaskType.ADDITIONAL_AGREEMENT: {
            return userHasPermission(EAccessActions.AgreementCard, userActions);
        }
        case ETaskType.COMPLETED_WORKS_SHEET: {
            return userHasPermission(EAccessActions.CompletedWorksCard, userActions);
        }
    }
    return false;
};

export const userHasPermission = (
    permission: TRouteAccessActions,
    userActions: TAccessActions,
): boolean => {
    if (typeof permission === 'string') {
        return userActions.includes(permission);
    } else {
        return userHasDocumentPermission(permission.permission, permission.type, userActions);
    }
};

export const userHasDocumentPermission = (
    permission: EObjectAccessActions,
    documentType: EDocumentType,
    userActions: TAccessActions,
) => {
    const result = userActions
        .filter((item) => typeof item === 'object')
        // @ts-ignore
        ?.find((item) => Object.hasOwn(item, permission));
    if (!!result) {
        return (
            result[permission].includes(EDocumentType.ALL) ||
            result[permission].includes(documentType)
        );
    }
    return false;
};
