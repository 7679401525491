import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    width: 100%;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    margin-bottom: 8px;

    table {
        table-layout: fixed;
    }
`;

export const SelectWrapper = styled.div`
    margin: 16px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    @media (max-width: 1090px) {
        flex-wrap: wrap;
    }
`;

export const PrimeContractorWrapper = styled.div`
    flex: 0 1 413px;
    min-width: 400px;
`;

export const TypeWrapper = styled.div`
    flex: 0 1 645px;
    min-width: 400px;
`;
