import {AllStatuses, ColorTypes} from '@beeline/design-system-react/types/types/status';

import {IEmployeeInfo} from 'shared/types/userTypes';
import {IShortStatus, IStatus} from 'shared/types/statusTypes';
import {EWorkObjectTypes, IAgreementShortData} from 'shared/types/additionalAgreementsTypes';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {IComment} from 'shared/types/commonTypes';

import {ICoefficient} from '../../ApplyCoefficientsPage/types';

export interface AdditionalAgreementQueryParams {
    id: string;
    projectId?: string;
}

export interface AdditionalAgreementResponse {
    data: AdditionalAgreementFull;
}

export interface AdditionalAgreementFull {
    address: string;
    agreement_attachments?: TAttachmentFile[];
    agreement_comments?: IComment[];
    agreement_id?: string | null;
    agreement_number: string;
    approved_at?: Date | null;
    begin_date: string | null;
    bs_name: string;
    bs_number: string;
    contract: string;
    contract_name: string;
    created_at: Date;
    creator: IEmployeeInfo;
    current_agreement: IAgreementShortData;
    editor: IEmployeeInfo;
    end_date: string;
    gpo_short_name: string;
    history_agreements: [];
    id: string;
    is_prepayment: boolean;
    is_released: boolean;
    is_termination?: boolean;
    linked_entities: IAgreementLinkedEntity;
    number_addendum: number | null;
    parent_signing_date: string | null;
    primary_is_prepayment?: boolean;
    primary_is_sum?: string;
    primary_sum_tax?: string;
    project_total_sum?: string;
    print_forms: TAttachmentFile[];
    project: string;
    project_number?: string;
    project_type: string;
    purchase_lots_name: string;
    purchasing_event_number: string;
    remote_territory?: string;
    signing_date?: string | null;
    status: IStatus;
    sum: string;
    sum_tax: string;
    total_sum: string;
    tpi: string;
    updated_at: Date;
    work_types: string;
    works: Array<IAgreementWorksheet>;
}

export interface IAgreementWorksheet {
    appendix_number: number;
    id: string;
    sum: number;
    sum_tax: number;
    total_sum: number;
    work_objects: IAgreementWorkObject[];
}

export interface IAgreementWorkObject {
    id: string;
    gfk: string;
    name: string;
    object_type: EWorkObjectTypes;
    transport_net_id?: string;
    work_specifications: IAgreementWorkSpecification[];
    address: string;
    is_half_set: boolean;
    lav_gfk: string;
    work_type_id: string;
    work_type_name?: string;
    work_type_dative_case?: string;
}

export interface IAgreementWorkSpecification {
    accuracy: number;
    begin_date: string | null;
    category_name: string | null;
    coefficient: number;
    coefficient_ams: number;
    coefficient_foundation: number;
    coefficient_remote_territory: number;
    end_date: string | null;
    id: string;
    is_agreed_price: boolean;
    is_tax?: boolean;
    is_ams: boolean;
    is_category: boolean;
    is_foundation: boolean;
    is_prepayment: boolean;
    is_price_editable: boolean;
    is_quantity_editable: boolean;
    is_ws_deletable: boolean;
    name: string;
    parent_name?: string | null;
    note?: string;
    number: string;
    ordinal_number: number;
    price: string;
    quantity: string;
    rate?: number;
    sum: string;
    sum_tax: string;
    total_sum: string;
    tpi_specification: string;
    tpi_specification_id?: string;
    tax_rate: string;
    type_position?: number;
    unit: string;
    work_detail: {
        id: string;
        work_category: string;
    } | null;
    work_specification_approval: boolean | null;
    work_specification_approval_total: boolean | null;
    work_specification_comments?: IComment[];
    work_specification_attachments?: AgreementAttachmentType[];
    work_coefficients: IWorkCoefficient[];
    work_type_dative_case: string;
    work_type_id: string;
    work_type_name: string;
}

export interface AgreementAttachmentType {
    id: string;
    file_name: string;
    file_id: string;
    file: File;
    type: EAttachmentType;
}

export enum TASK_STATUS {
    PLAN = 1,
    RUN = 2,
    PAUSE = 3,
    STOP = 4,
    DONE = 5,
}

export const TaskStatusBadge: Record<TASK_STATUS, AllStatuses> = {
    [TASK_STATUS.PLAN]: 'info',
    [TASK_STATUS.RUN]: 'warning',
    [TASK_STATUS.PAUSE]: 'default',
    [TASK_STATUS.STOP]: 'error',
    [TASK_STATUS.DONE]: 'success',
};

export const TaskStatusColor: Record<TASK_STATUS, ColorTypes> = {
    [TASK_STATUS.PLAN]: 'blue',
    [TASK_STATUS.RUN]: 'orange',
    [TASK_STATUS.PAUSE]: 'grey',
    [TASK_STATUS.STOP]: 'red',
    [TASK_STATUS.DONE]: 'green',
};

export const TaskStatusTitle = {
    [TASK_STATUS.PLAN]: 'Планируется',
    [TASK_STATUS.RUN]: 'Выполняется',
    [TASK_STATUS.PAUSE]: 'Приостановлен',
    [TASK_STATUS.STOP]: 'Прекращен',
    [TASK_STATUS.DONE]: 'Выполнен',
};

export interface ITaskResponse {
    data: Task;
}

export interface Task {
    actions: TaskAction[];
    approved_at: string | null;
    branch: string;
    created_at: string;
    comment: string | null;
    contractor: string;
    creator: string | null;
    deleted_at: string;
    description: string | null;
    eraser: string | null;
    executor: string | null;
    id: string;
    is_delegated_task: boolean;
    is_group: boolean;
    iteration: number;
    name: string;
    number: number;
    owner: string;
    region: string;
    replay: number;
    status: number;
    substage: string;
    substages_type: number;
    user: string;
}

export interface TaskAction {
    id: string;
    code: string;
    description: string | null;
    header: string | null;
    name: string;
    is_iteration: false;
    number: TaskNumber;
    type: TaskType;
    method_result: null;
    is_selected: false;
    is_finished: false;
    is_canceled: false;
    is_positive: boolean | null;
    task: string;
    stage: string;
    action_variant_template: string;
    type_confirmation: ETaskTypeConfirmations;
}

export enum TaskType {
    type,
}

export enum TaskNumber {
    number,
}

export interface UpdateTaskProps {
    id: string;
    actionId: string;
}

export interface IWorkCoefficient extends ICoefficient {
    tpi_specification?: string;
    is_manual: boolean;
}

export enum ETaskTypeConfirmations {
    NoConfirmation = 1,
    SimpleConfirmation,
    ExtendedConfirmation,
    Signing,
    ErrorCorrection,
    KZD,
    PO,
    MandatoryPO,
}

export interface IAgreementLinkedEntity {
    addendums: IAddendumInfo[];
    payment_request: IIntegrationInfo | null;
    purchase_order: IIntegrationInfo | null;
    signing: ISigningInfo | null;
    vvrs: IShortCompletedWorksSheet[];
}

export interface IAddendumInfo {
    agreement_id: string | null;
    id: string;
    status: IShortStatus;
    signing_date: string;
    number_addendum: number;
    number_agreement: string;
    created_at: string;
    approved_at: string | null;
    is_termination: boolean;
}

export interface IIntegrationInfo {
    id: string;
    number: string | null;
    status: IShortStatus;
    approved_at: string | null;
    dcm_hyperlink: string;
}

export interface ISigningInfo {
    status: IShortStatus;
    customer_signing_date: string;
    implementer_signing_date: string;
    diadoc_url: string;
    diadoc_archive: boolean;
}

export interface IShortCompletedWorksSheet {
    created_at: string;
    approved_at: string | null;
    id: string;
    number: number;
    status: IShortStatus;
    sum: string;
}
