export const COMPLETED_WORKS_TAB_TITLE = 'Финансовая приемка';
export const COMPLETED_WORKS_TAB_EMPTY_TITLE = 'Ведомости выполненных работ еще не сформированы';
export const COMPLETED_WORKS_TAB_EMPTY_SUBTITLE = 'Вы можете создать их, нажав на кнопку ниже';
export const COMPLETED_WORKS_CREATION_BUTTON = 'Создать ВВР';

export const TECHNICAL_ACCEPTANCE_STAGE_EMPTY_TITLE =
    'Документы по технической приемке еще не созданы';
export const LOGISTIC_STAGE_EMPTY_TITLE = 'Документы по логистике еще не созданы';
export const WORK_PROGRESS_TITLE = 'Ход работ';
export const COMPLETED_WORKS_STAGE_EMPTY_TITLE = 'Выданных заданий еще нет';
export const AGREEMENTS_STAGE_EMPTY_TITLE = 'Доп. соглашений еще нет';
export const COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE = 'Вы можете создать их, нажав на кнопку ниже';
