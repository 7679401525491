import React from 'react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import {IDocument} from 'shared/types/documentTypes';
import {formatDate} from 'shared/helpers/formatHelper';
import {
    ADDRESS_TITLE,
    CITY_TITLE,
    CONTRACTOR_SHORT_TITLE,
    CREATED_TITLE,
    PLANNED_DATE_TITLE,
    REGION_TITLE,
} from 'shared/constants';

import * as S from './style';
import {InfoUnitSkeleton} from './skeletons/InfoUnitSkeleton';

interface RequestViewInfoUnitProps {
    data: IDocument | null;
    isLoading: boolean;
}

export const RequestViewInfoUnit = ({data, isLoading}: RequestViewInfoUnitProps) => {
    if (!data || isLoading) return <InfoUnitSkeleton />;
    const {created_at, planned_date, organization_gpo} = data;
    return (
        <S.InfoUnitGrid>
            <InformationalUnit title={CREATED_TITLE} gridArea="createdDate">
                {formatDate(created_at)}
            </InformationalUnit>
            <InformationalUnit title={PLANNED_DATE_TITLE} gridArea="plannedDate">
                {formatDate(planned_date)}
            </InformationalUnit>
            <InformationalUnit title={CONTRACTOR_SHORT_TITLE} gridArea="contractor">
                {organization_gpo}
            </InformationalUnit>
            <InformationalUnit title={ADDRESS_TITLE} gridArea="address">
                {formatDate(created_at)}
            </InformationalUnit>
            <InformationalUnit title={REGION_TITLE} gridArea="region">
                {formatDate(created_at)}
            </InformationalUnit>
            <InformationalUnit title={CITY_TITLE} gridArea="city">
                {formatDate(created_at)}
            </InformationalUnit>
        </S.InfoUnitGrid>
    );
};
