import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {and} from 'patronum';

import {TableProps} from 'shared/helpers/tables';
import {fetchNewCompletedWorksList} from 'shared/services/completedWorks.service';
import {ICompletedWorksListResponse} from 'shared/types/completedWorksTypes';

export const CompletedWorksStageDomain = createDomain();
export const CompletedWorksStageGate = createGate<string>();

// effects
export const getCompletedWorksListFx = CompletedWorksStageDomain.createEffect(
    async ({id, page, pageSize}: {id: string; page: number; pageSize: number}) => {
        try {
            const result = await fetchNewCompletedWorksList(id, page, pageSize);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);
// events
export const rowExpanded = CompletedWorksStageDomain.createEvent<string>();
export const pageChanged = CompletedWorksStageDomain.createEvent<number>();
export const pageSizeChanged = CompletedWorksStageDomain.createEvent<number>();
// stores

const $projectId = CompletedWorksStageDomain.createStore<string>('');
const $completedWorksList = CompletedWorksStageDomain.createStore<ICompletedWorksListResponse[]>([]);
export const $total = CompletedWorksStageDomain.createStore<number>(0);
const $expandedRowList = CompletedWorksStageDomain.createStore<string[]>([]);
const $tableProps = CompletedWorksStageDomain.createStore<TableProps>(new TableProps(1, 10));
const $listIsLoading = getCompletedWorksListFx.pending;
export const $completedWorksStageStore = combine({
    completedWorksList: $completedWorksList,
    total: $total,
    tableProps: $tableProps,
    listIsLoading: $listIsLoading,
    expandedRowList: $expandedRowList,
    isEmpty: and(
        $completedWorksList.map((state) => state.length === 0),
        $listIsLoading.map((item) => !item),
    ),
});

sample({
    clock: CompletedWorksStageGate.open,
    filter: (gate) => typeof gate === 'string',
    fn: (gate) => gate,
    target: $projectId,
});

sample({
    source: combine({
        id: $projectId,
        tableProps: $tableProps,
    }),
    filter: ({id}) => id !== '',
    fn: ({id, tableProps}) => ({id, page: tableProps.page, pageSize: tableProps.pageSize}),
    target: getCompletedWorksListFx,
});

sample({
    clock: getCompletedWorksListFx.doneData,
    fn: (data) => data?.results ?? [],
    target: $completedWorksList,
});

sample({
    clock: getCompletedWorksListFx.doneData,
    fn: (data) => data?.count ?? 0,
    target: $total,
});

sample({
    clock: rowExpanded,
    source: $expandedRowList,
    fn: (list, expandedRowId) => {
        if (list.includes(expandedRowId)) {
            return list.filter((el) => el !== expandedRowId);
        }
        return [...list, expandedRowId];
    },
    target: $expandedRowList,
});

$tableProps
    .on(pageChanged, (store, newPage) => new TableProps(newPage, store.pageSize))
    .on(pageSizeChanged, (store, newPageSize) => new TableProps(store.page, newPageSize));
