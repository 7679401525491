import React, {useState} from 'react';
import {useStore} from 'effector-react';
import {TableBody, TableRow, Typography} from '@beeline/design-system-react';
import {sizeSpacingX2, sizeSpacingX6} from '@beeline/design-tokens/js/tokens';

import {TableBase} from 'Entities/TableBase';
import {TableHeader} from 'Features/TableHeader';
import {TrimTextArea} from 'Features/TrimTextArea';
import {StyledAccordionItem} from 'pages/VirConstructor/VirAccordion/style';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Accordion} from 'shared/ui/deprecated/Accordion/Accordion';
import {CustomTableData} from 'shared/styles/commonStyle';
import {UNNECESSARY_TEXTAREA_LABEL} from 'shared/constants';

import {columns} from './columns';
import styles from './style.module.scss';
import {$requestCreationPageStore, itemCommentChanged} from '../model';
import {IAdaptedConsumerOrderItem} from '../model/helpers';

export const RequestCreationTable = () => {
    const {adaptedSpecification, openedCommentRow} = useStore($requestCreationPageStore);
    const [active, setActive] = useState(
        adaptedSpecification?.consume_orders[0].consume_order.id ?? '',
    );
    if (!adaptedSpecification) return null;
    return (
        <div className={styles.tableWrapper}>
            <Accordion>
                {adaptedSpecification.consume_orders.map(({consume_order: order}) => (
                    <StyledAccordionItem
                        key={order.id}
                        id={order.id}
                        title={`Спецификация ${order.number}`}
                        active={order.id === active}
                        setActive={() => setActive(order.id)}
                    >
                        <TableBase minTableWidth="700px" noBorder>
                            <TableHeader<IAdaptedConsumerOrderItem> columns={columns} />
                            <TableBody>
                                {order.items.map((item) => (
                                    <React.Fragment key={`${item.nfs_id}_index`}>
                                        <TableRow dense>
                                            {columns.map((col) => (
                                                <CustomTableData
                                                    key={col.name}
                                                    width={col.width}
                                                    height="52px"
                                                    $noPadding={col.name === 'requested'}
                                                >
                                                    {!!col.render && col.render(item)}
                                                </CustomTableData>
                                            ))}
                                        </TableRow>
                                        {openedCommentRow === item.consume_order_item_id && (
                                            <TableRow dense>
                                                <CustomTableData
                                                    colSpan={10}
                                                    height="52px"
                                                    $noPadding
                                                >
                                                    <WrapperFlex
                                                        flexDirection="column"
                                                        gap={sizeSpacingX2}
                                                        margin={`${sizeSpacingX6} 0 ${sizeSpacingX6} 0`}
                                                    >
                                                        <Typography variant="h5">
                                                            Комментарий к позиции
                                                        </Typography>
                                                        <TrimTextArea
                                                            value={item.comment}
                                                            label={UNNECESSARY_TEXTAREA_LABEL}
                                                            onChange={(comment) =>
                                                                itemCommentChanged({
                                                                    id: item.consume_order_item_id,
                                                                    comment,
                                                                })
                                                            }
                                                            fullWidth
                                                        />
                                                    </WrapperFlex>
                                                </CustomTableData>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </TableBase>
                    </StyledAccordionItem>
                ))}
            </Accordion>
        </div>
    );
};
