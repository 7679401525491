import React from 'react';

import Pagination from 'shared/ui/Pagination/Pagination';
import {IPaginationData} from 'shared/types/commonTypes';

import * as S from './style';

interface TableBaseProps {
    minTableWidth: string;
    children: React.ReactNode;
    paginationData?: IPaginationData;
    noBorder?: boolean;
}

export const TableBase = ({minTableWidth, children, paginationData, noBorder}: TableBaseProps) => {
    return (
        <S.TableWrapper $hasPagination={!!paginationData} $noBorder={noBorder}>
            <S.TableOverflowContainer>
                <S.StyledTable $minWidth={minTableWidth} $hasPagination={!!paginationData}>
                    {children}
                </S.StyledTable>
            </S.TableOverflowContainer>
            {paginationData && <Pagination {...paginationData} />}
        </S.TableWrapper>
    );
};
