import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {IDocument, TDocumentEditing} from 'shared/types/documentTypes';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {putDocumentEditing} from 'shared/services/documents.service';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';

import {
    adaptDocumentToAdaptedDocument,
    adaptRequestForBackend,
    changeDocumentComment,
    changeIsUrgent,
    changeItemChecked,
    changeItemComment,
    changePlannedDate,
    changeRequestedQuantity,
    mapAdaptedDocumentToDocument,
    TAdaptedDocument,
    TAdaptedDocumentSpecification,
} from './helpers';

export const RequestCreationPageDomain = createDomain();
export const RequestCreationPageGate = createGate();

// effects
const putDataFx = RequestCreationPageDomain.effect(
    async (data: {data: TDocumentEditing; documentId: string}) => {
        try {
            const result = await putDocumentEditing(data.data, data.documentId);
            if (result.status === 200) {
                return result.data.id;
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

// events
export const requestEditingInvoked = RequestCreationPageDomain.event<IDocument | null>();
export const requestSaved = RequestCreationPageDomain.event();
export const itemToggled = RequestCreationPageDomain.event<{id: number; checked: boolean}>();
export const isUrgentToggled = RequestCreationPageDomain.event<boolean>();
export const requestedQuantityChanged = RequestCreationPageDomain.event<{
    id: number;
    quantity: number;
}>();
export const plannedDateChanged = RequestCreationPageDomain.event<string>();
export const commonCommentChanged = RequestCreationPageDomain.event<string>();
export const itemCommentRowToggled = RequestCreationPageDomain.event<number>();
export const itemCommentChanged = RequestCreationPageDomain.event<{id: number; comment: string}>();

// stores
const $requestData = RequestCreationPageDomain.store<IDocument | null>(null);
const $adaptedRequestData = RequestCreationPageDomain.store<TAdaptedDocument | null>(null);
const $adaptedSpecification = RequestCreationPageDomain.store<TAdaptedDocumentSpecification | null>(
    null,
);
const $openedCommentRow = RequestCreationPageDomain.store<number | null>(null).on(
    itemCommentRowToggled,
    (state, payload) => {
        return state === payload ? null : payload;
    },
);

export const $requestCreationPageStore = combine({
    requestData: $adaptedRequestData,
    adaptedSpecification: $adaptedSpecification,
    openedCommentRow: $openedCommentRow,
});

sample({
    clock: requestEditingInvoked,
    target: $requestData,
});

sample({
    clock: requestEditingInvoked,
    fn: (document) => ({
        to: `equipment-requests/${document?.id}/editing`,
    }),
    target: navigationInvoked,
});

sample({
    source: $requestData,
    filter: (source) => !!source,
    fn: (source) => adaptDocumentToAdaptedDocument(source ?? null),
    target: $adaptedRequestData,
});

sample({
    source: $adaptedRequestData,
    fn: (source) => source?.specification ?? null,
    target: $adaptedSpecification,
});

// Тоггл "срочное требование"
sample({
    clock: isUrgentToggled,
    source: $adaptedRequestData,
    fn: (data, clock) => changeIsUrgent(data, clock),
    target: $adaptedRequestData,
});

// изменение требуемого кол-ва
sample({
    clock: requestedQuantityChanged,
    source: $adaptedRequestData,
    fn: (source, clock) => changeRequestedQuantity(source, clock),
    target: $adaptedRequestData,
});

// тоггл пункта
sample({
    clock: itemToggled,
    source: $adaptedRequestData,
    fn: (source, clock) => changeItemChecked(source, clock),
    target: $adaptedRequestData,
});

// изменение планируемой даты
sample({
    clock: plannedDateChanged,
    source: $adaptedRequestData,
    fn: (source, clock) => changePlannedDate(source, clock),
    target: $adaptedRequestData,
});

// изменение общего комментария
sample({
    clock: commonCommentChanged,
    source: $adaptedRequestData,
    fn: (source, clock) => changeDocumentComment(source, clock),
    target: $adaptedRequestData,
});

// изменение комментария в строке
sample({
    clock: itemCommentChanged,
    source: $adaptedRequestData,
    fn: (source, clock) => changeItemComment(source, clock),
    target: $adaptedRequestData,
});

sample({
    clock: requestSaved,
    source: $adaptedRequestData,
    fn: (source) => console.log(adaptRequestForBackend(source)),
});

// "сохранить черновик"
sample({
    clock: requestSaved,
    source: $adaptedRequestData,
    filter: (source) => !!source,
    fn: (source) => {
        const result = mapAdaptedDocumentToDocument(source);
        return {
            data: {
                project_id: result?.bs.id ?? '',
                subtypes_document_id: result?.subtype_document.id ?? '',
                document_attachments: [],
                is_urgent: result?.is_urgent,
                planned_date: result?.planned_date
                    ? formatDateToBackendFormat(result?.planned_date)
                    : undefined,
                document_comment: source?.comment ?? '',
                specification: result?.specification ?? null,
            } as TDocumentEditing,
            documentId: source?.id ?? '',
        };
    },
    target: putDataFx,
});

// редирект пользователя в карточку запроса
sample({
    clock: putDataFx.doneData,
    filter: (clock) => typeof clock === 'string',
    fn: (clock) => ({to: `equipment-requests/${clock}/`}),
    target: navigationInvoked,
});

resetDomainStoresByEvents(RequestCreationPageDomain, RequestCreationPageGate.close);
