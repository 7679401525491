import {axiosInstance} from 'shared/api/api';
import {IAdditionalAgreementError} from 'shared/types/additionalAgreementsTypes';
import {IErrorPostParams} from 'shared/services/types/agreementParamsTypes';

export const fetchErrorList = (id: string) => {
    return axiosInstance.get<IAdditionalAgreementError[]>(`/errors/`, {
        params: {
            task_id: id,
        },
    });
};
export const postAgreementError = (payload: IErrorPostParams) => {
    return axiosInstance.post<unknown>(`/errors/`, payload.data, {
        params: {
            task_id: payload.id,
        },
    });
};
