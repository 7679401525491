import React from 'react';
import {useStore} from 'effector-react';
import {Button, TableBody, TableHead, TableRow} from '@beeline/design-system-react';

import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import Pagination from 'shared/ui/Pagination/Pagination';
import {IColumn, IRowItem, ITableData, IVisibleColumn} from 'shared/types/tableTypes';
import {$userActions} from 'shared/model/user';
import {userHasPermission} from 'shared/helpers/accessCheckers';

import * as S from './style';

interface TableWithSortProps<T extends IRowItem> {
    columns: IColumn<T>[];
    dataObject: ITableData<T>;
    onChangePage: (page: number) => void;
    onChangePageSize: (size: number) => void;
    onReset: () => void;
    visibleColumns?: IVisibleColumn[];
    onSort?: (name: string) => void;
    dense?: boolean;
    minTableWidth?: string;
    rowHeight?: string;
}

export const TableWithSort = <T extends IRowItem>({
    columns,
    dataObject,
    visibleColumns,
    onChangePage,
    onChangePageSize,
    dense,
    minTableWidth,
    rowHeight,
    onReset,
    onSort,
}: TableWithSortProps<T>) => {
    const userActions = useStore($userActions);
    const {count, data, page, pageSize, isLoading, order} = dataObject;
    const isEmpty = !isLoading && data.length === 0;

    const visibleColumnList = columns.filter((column) => {
        if (!visibleColumns) return true;
        const visibleColumn = visibleColumns?.find(
            (visibleColumn) => visibleColumn.id === column.name,
        );
        return (
            !!visibleColumn &&
            (visibleColumn?.visible ?? true) &&
            (!visibleColumn?.action || userHasPermission(visibleColumn?.action, userActions))
        );
    });
    return (
        <S.TableWrapper>
            <S.TableOverflowContainer>
                {isEmpty ? (
                    <NotFound height="500px">
                        <NotFoundTitle>По заданным фильтрам ничего не найдено</NotFoundTitle>
                        <NotFoundCaption>
                            Попробуйте изменить параметры или сбросьте фильтры
                        </NotFoundCaption>
                        <Button className="mt-3" variant="plain" onClick={() => onReset()}>
                            Сбросить
                        </Button>
                    </NotFound>
                ) : (
                    <S.StyledTable $minWidth={minTableWidth}>
                        <TableHead>
                            <TableRow>
                                {visibleColumnList.map((item) => (
                                    <S.StyledTableHeaderData
                                        key={item.name.toString()}
                                        width={item.width}
                                        onClick={() =>
                                            item.hasSorting &&
                                            onSort &&
                                            onSort(item.name.toString())
                                        }
                                        $hover={item.hasSorting}
                                        $arrowUp={item.name === order}
                                        $arrowDown={
                                            `-${item.name.toString()}` === order ||
                                            (order === '' && item.name === 'created_at')
                                        }
                                    >
                                        {item.label}
                                    </S.StyledTableHeaderData>
                                ))}
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableBodySkeleton<T>
                                pageSize={pageSize}
                                columns={visibleColumnList}
                                height={rowHeight}
                                dense={dense}
                            />
                        ) : (
                            <TableBody>
                                {data.map((item) => (
                                    <TableRow key={`${item.id}_row`} dense={dense}>
                                        {visibleColumnList.map((column) => (
                                            <S.StyledTableData
                                                key={`${item.id}_${column.name.toString()}_row`}
                                                height={rowHeight}
                                            >
                                                {/*@ts-ignore*/}
                                                {column.render && column.render(item, userActions)}
                                            </S.StyledTableData>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </S.StyledTable>
                )}
            </S.TableOverflowContainer>
            <Pagination
                total={count}
                setPageSize={onChangePageSize}
                setPage={onChangePage}
                page={page}
                pageSize={pageSize}
                isLoading={isLoading}
            />
        </S.TableWrapper>
    );
};
