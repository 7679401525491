import React from 'react';

import {CommentButton} from 'Features/buttons/CommentButton';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {IConsumerOrderItem} from 'shared/types/documentTypes';

import {commentRowToggled} from '../model';


export const columns: IColumnWithActions<IConsumerOrderItem>[] = [
    {
        name: 'number',
        label: '№',
        render: ({consume_order_item_id}) => consume_order_item_id,
        width: '50px',
    },
    {
        name: 'nomenclature',
        label: 'Номенклатура',
        width: '320px',
        render: ({name}) => (
            <TooltipedSpan title={name} lineCount={2}>
                {name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'nfcCode',
        label: 'Код НФС',
        render: ({nfs_id}) => nfs_id,
        width: '160px',
    },
    {
        name: 'unit',
        label: 'Ед. изм.',
        render: ({unit}) => unit.name,
        width: '80px',
    },
    {
        name: 'plannedQuantity',
        label: 'План. кол-во',
        render: ({planned_quantity}) => planned_quantity,
        width: '112px',
    },
    {
        name: 'requiredQuantity',
        label: 'Треб. кол-во',
        render: ({required_quantity}) => required_quantity,
        width: '112px',
    },
    {
        name: 'approvedQuantity',
        label: 'Согласовано',
        render: ({agreed_quantity}) => agreed_quantity,
        width: '112px',
    },
    {
        name: 'issuedQuantity',
        label: 'Выдано',
        render: ({relocation_order_quantity}) => relocation_order_quantity,
        width: '112px',
    },
    {
        name: 'znpNumber',
        label: '№ ЗнП',
        render: ({relocation_order_number}) => relocation_order_number,
        width: '160px',
    },
    {
        name: 'comment',
        label: 'Комментарий',
        render: ({comment, consume_order_item_id}) => (
            <CommentButton
                onClick={() => commentRowToggled(consume_order_item_id)}
                hasComments={!!comment}
            />
        ),
        width: '160px',
    },
];
