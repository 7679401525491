import {useStore} from 'effector-react';

import {TableBase} from 'Entities/TableBase';
import {TableHeader} from 'Features/TableHeader';
import {
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {IPaginationData} from 'shared/types/commonTypes';

import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';

import {AgreementTableContent} from './AgreementTableContent';
import {
    $additionalAgreementsTable,
    setPage,
    setPageSize,
    toggleOpenDetails,
} from '../../../../../model/addditionalAgreementsStage';

interface AdditionalAgreementsTableProps {
    columns: IColumnWithActions<IToggledAdditionalAgreementListItem>[];
    innerColumns: IColumnWithActions<IAgreementAddition>[];
}

export function AdditionalAgreementsTable({columns, innerColumns}: AdditionalAgreementsTableProps) {
    const {agreements, isLoading, openedIds, tableParams, total} = useStore(
        $additionalAgreementsTable,
    );

    const paginationData: IPaginationData = {
        page: tableParams.page,
        pageSize: tableParams.page_size,
        total,
        isLoading,
        setPageSize,
        setPage,
    };

    return (
        <TableBase minTableWidth="1200px" paginationData={paginationData}>
            <TableHeader<IToggledAdditionalAgreementListItem> columns={columns} />
            {isLoading ? (
                <TableBodySkeleton<IToggledAdditionalAgreementListItem>
                    pageSize={tableParams.page_size}
                    columns={columns}
                    height="52px"
                    dense
                />
            ) : (
                <tbody>
                    <AgreementTableContent
                        columns={columns}
                        innerColumns={innerColumns}
                        agreements={agreements}
                        openedIds={openedIds}
                        toggleOpenDetails={toggleOpenDetails}
                    />
                </tbody>
            )}
        </TableBase>
    );
}
