import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Icon, Skeleton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$userActions} from 'shared/model/user';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {TBreadcrumb} from 'shared/types/breadcrumbTypes';

interface BreadcrumbsProps {
    data: TBreadcrumb[];
    isLoading: boolean;
}

export const Breadcrumbs = ({data, isLoading}: BreadcrumbsProps) => {
    const userActions = useStore($userActions);
    return (
        <WrapperFlex alignItems="center" gap="12px">
            {data.map((item, index) => {
                const hasLink =
                    item.link && item.access && userHasPermission(item.access, userActions);
                const isLast = data.length === index + 1;
                return (
                    <React.Fragment key={`${item.name}`}>
                        {isLoading ? (
                            <Skeleton variant="line" width={item.skeletonWidth} height={18} />
                        ) : item.link && hasLink ? (
                            <Link to={item.link}>{item.name}</Link>
                        ) : (
                            <Typography variant="body3">{item.name}</Typography>
                        )}
                        {!isLast && <Icon iconName={Icons.NavArrowRight} size="small" />}
                    </React.Fragment>
                );
            })}
        </WrapperFlex>
    );
};
