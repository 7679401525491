import {Option} from '@beeline/design-system-react';

import {TFileDataList, TFileUploaderMode} from 'shared/ui/use-file-uploader/useFileUploader.types';
import {EAttachmentType} from 'shared/types/FileTypes';
import {EDocumentType} from 'shared/types/documentTypes';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';

export interface IDocumentOption extends Option<string> {
    isRegistration?: boolean;
}

export interface IOutputUploadedFile {
    id: null;
    file_id: string;
    history_id: string;
    type: EAttachmentType;
    registration_at: string | null;
}

export interface IPreparedOutputEditingData {
    docs: {id: string | null; file_id: string | null; registration_at: string | null}[];
    documentId: string;
    projectId: string;
}

export interface IDocumentConstructorGateProps {
    projectId: string;
    documentType: EDocumentType;
    mode: TFileUploaderMode;
}

export const checkObjectValidity = (
    anyButtonClicked: boolean | undefined,
    files: TFileDataList,
    isError: boolean,
    selectedDocumentType: IDocumentOption | null,
): boolean => {
    const hasRegistrationDateError = selectedDocumentType?.isRegistration
        ? Object.values(files).some((item) => !item.registration_at)
        : false;

    return (
        !hasRegistrationDateError &&
        !anyButtonClicked &&
        Object.keys(files).length !== 0 &&
        !isError &&
        !!selectedDocumentType
    );
};

export const mapFileUploaderFilesToOutput = (
    files: TFileDataList | undefined,
    selectedDocumentType: IDocumentOption | null,
): IOutputUploadedFile[] => {
    const fileIdList: IOutputUploadedFile[] = [];
    for (let prop in files) {
        if (files[prop].status === 'upload') {
            const registration = files[prop].registration_at;
            const transformedDate =
                registration !== null && !!selectedDocumentType?.isRegistration
                    ? formatDateToBackendFormat(registration)
                    : null;
            fileIdList.push({
                id: null,
                file_id: files[prop].guid ?? '',
                history_id: files[prop].history_id ?? '',
                type: files[prop].type,
                registration_at: transformedDate,
            });
        }
    }
    return fileIdList;
};

export const mapFileUploaderFilesForEditing = (
    initialState: TFileDataList,
    files: TFileDataList,
    projId: string | undefined,
    documentId: string | undefined,
): IPreparedOutputEditingData => {
    {
        const oldFiles = Object.values(initialState);
        const newFiles = Object.values(files);
        const result = [
            ...newFiles.map((item) => {
                const transformedDate =
                    item.registration_at !== null
                        ? formatDateToBackendFormat(item.registration_at)
                        : null;

                if (item.file_id) {
                    return {
                        id: item.guid ?? null,
                        file_id: item.file_id ?? null,
                        history_id: item.history_id ?? null,
                        type: item.type,
                        registration_at: transformedDate,
                    };
                }
                return {
                    id: null,
                    file_id: item.guid ?? '',
                    history_id: item.history_id ?? null,
                    type: item.type,
                    registration_at: transformedDate,
                };
            }),
            ...oldFiles
                .filter((item) => !newFiles.some((newItem) => newItem.guid === item.guid))
                .map((item) => ({
                    id: item.guid ?? null,
                    history_id: item.history_id ?? null,
                    file_id: null,
                    type: item.type,
                    registration_at:
                        item.registration_at !== null
                            ? formatDateToBackendFormat(item.registration_at)
                            : null,
                })),
        ];
        return {
            docs: result,
            documentId: documentId ?? '',
            projectId: projId ?? '',
        };
    }
};
