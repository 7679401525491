export const MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_TITLE =
    'Вы уверены, что хотите согласовать ВВР?';
export const MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT1_TITLE = 'Обновить ВВР?';
export const MODAL_APPROVE_COMPLETED_WORKS_AGREEMENT_SUBTITLE =
    'Отменить принятое решение будет невозможно';
export const MODAL_UPDATE_COMPLETED_WORKS_AGREEMENT_SUBTITLE =
    'ВВР создана по устаревшему документу и будет обновлена. Пункты работ устаревшего документа будут исключены, для добавления станут доступны пункты работ актуального документа.';

// buttons
export const CANCEL_BUTTON_TITLE = 'Отменить';
export const CONFIRM_BUTTON_TITLE = 'Согласовать';
export const DUPLICATE_BUTTON_TITLE = 'Дублировать';
export const DELETE_BUTTON_TITLE = 'Удалить';
export const PROJECT_TITLE = 'Проект';
export const REFRESH_COMPLETED_WORKS_TITLE = 'Обновить ВВР';
export const FOR_AGREEMENT_BUTTON_TITLE = 'На согласование';
export const COPY_BUTTON_TITLE = 'Скопировать';
export const BACK_BUTTON = 'Назад';
export const SAVE_BUTTON = 'Сохранить';
export const ADD_FILES_BUTTON = 'Добавить файлы';

// titles
export const CREATION_DATE = 'Дата создания';
export const APPROVAL_DATE = 'Дата утверждения';
export const CONFORMING_DATE = 'Дата согласования';
export const SIGNING_DATE = 'Дата подписания';
export const ISSUANCE_DATE = 'Дата выдачи';
export const RESPONSE_DATE = 'Дата ответа';
export const DEADLINE_DATE = 'Срок выполнения';
export const PLANNED_DATE_TITLE = 'Желаемая дата';
export const DIADOC_LINK_TITLE = 'Ссылки на Диадок';
export const NUMBER_TITLE = 'Номер';
export const LINK_TITLE = 'Ссылка';
export const CARD_TITLE = 'Карточка';
export const PR_TITLE = 'PR';
export const SIGNING_TITLE = 'ЭДО';
export const PO_TITLE = 'ЗП';
export const ERRORS_TITLE = 'Ошибки';
export const COMPLETED_WORKS_TITLE = 'ВВР';
export const ADDRESS_TITLE = 'Адрес';
export const SET_QUANTITY_TITLE = 'Укажите количество';
export const QUANTITY_EDITING_PROHIBITION_TITLE = 'Редактирование количества запрещено';
export const PRICE_EDITING_PROHIBITION_TITLE = 'Редактирование цены запрещено';
export const FILTERS_AND_SETTINGS_SIDESHEET_TITLE = 'Фильтры и настройки';
export const FILTERS_TITLE = 'Фильтры';
export const CONTRACT_TITLE = 'Договор';
export const CONTRACT_NUMBER_TITLE = 'Номер договора';
export const CONTRACTS_TITLE = 'Договоры';
export const REGION_TITLE = 'Регион';
export const CITY_TITLE = 'Город';
export const SEARCH_TITLE = 'Поиск';
export const PROCUREMENT_EVENT_TITLE = 'Закупочное мероприятие';
export const CONTRACTOR_SHORT_TITLE = 'ГПО';
export const EXPIRY_RANGE_TITLE = 'Срок действия';
export const IS_ACTIVE_ON_DATE_TITLE = 'Действует на дату';
export const COLUMN_SETTINGS_TITLE = 'Настройка колонок';
export const LOTS_AND_BRANCHES_TITLE = 'Лоты и филиалы';
export const LOT_TITLE = 'Лот';
export const LOTS_TITLE = 'Лоты';
export const BRANCH_SEARCH_TITLE = 'Поиск филиала';
export const FROM_TITLE = 'от';
export const OF_TITLE = 'из';
export const ON_TITLE = 'по';
export const TO_TITLE = 'к';
export const WORK_TYPE_TITLE = 'Тип работ';
export const JOB_TYPE_TITLE = 'Тип задания';
export const PROJECT_TYPE_TITLE = 'Тип проекта';
export const BUSINESS_RULES_PAGE_TITLE = 'Бизнес-правила установки признака APPROVE_ALLOWED';
export const COMMON_DESCRIPTION_SUBTITLE = 'Общее описание';
export const CREATE_TITLE = 'Создать';
export const CREATE_ADDITION_TITLE = 'Создать дополнение';
export const EDIT_TITLE = 'Редактировать';
export const CURRENT_COMPLETED_WORKS_DONE_TITLE = 'Выполнено по ВВР';
export const PREV_COMPLETED_WORKS_DONE_TITLE = 'Выполнено по другим ВВР';
export const PO_NUMBER_TITLE = 'Номер ЗП';
export const UNUSUAL_PO_NUMBER_TITLE = 'Нестандартный ЦБО';
export const EDMC_TITLE = 'ЭДО КЗД';
export const EDMC_TEMPLATES_TITLE = 'ЭДО шаблоны';
export const DOWNLOAD_TITLE = 'Скачать';
export const DIADOC_LINKS_TITLE = 'Ссылки в Диадок';
export const KS2_TITLE = 'КС2';
export const KS3_TITLE = 'КС3';
export const INVOICE_TITLE = 'СФ';
export const GFK_CODE_TITLE = 'Код ГФК';
export const POSITION_NUMBER_TITLE = 'Номер позиции';
export const PAYMENT_TITLE = 'Оплата';
export const COMPLETED_WORKS_WIDGET_SSF_NUMBER_TITLE = 'Номер ССФ';
export const COMPLETED_WORKS_WIDGET_SSF_DATE_TITLE = 'Дата ССФ';
export const COMPLETED_WORKS_WIDGET_PLANED_DATE_TITLE = 'Плановая дата оплаты';
export const COMPLETED_WORKS_WIDGET_ACTUAL_DATE_TITLE = 'Фактическая дата оплаты';
export const CREATED_TITLE = 'Создан';
export const EQUIPMENT_REQUEST_TITLE = 'Запрос на выдачу оборудования';
export const COMMENT_TITLE = 'Комментарий';
export const ITEM_COMMENT_TITLE = 'Комментарий к пункту работ';
export const AGREEMENT_ITEM_COMMENT_TITLE = 'Комментарий к пункту из ДС';
export const AGREEMENT_COMMENTS_TITLE = 'Комментарии к ДС';
export const DOCUMENT_COMMENTS_TITLE = 'Комментарии к документу';
export const ATTACHMENTS_TITLE = 'Вложения';
export const ATTACHMENTS_AND_COMMENTS_TITLE = 'Вложения и комментарии';
export const ITEM_ATTACHMENTS_TITLE = 'Вложения к пункту';
export const SOURCE_DATA_TITLE = 'Исходные данные';
export const BASE_STATION_SHORT_TITLE = 'БС';
export const PREPAYMENT_TITLE = 'Аванс';
export const GET_PREPAYMENT_TITLE = 'Получить аванс';
export const COMMITTED_JOBS_TITLE = 'Поручаемые работы';

// page titles
export const FILL_IN_PO_NUMBER_TITLE = 'Заполнить номер ЗП';

// Subtitles
export const ERRORS_SUBTITLE_TEXT = 'Пожалуйста, исправьте все ошибки';
export const HOW_TO_FIX_ERROR_TEXT = 'Как исправить ошибку';

export const SET_AGREED_PRICE_TEXT = 'Укажите цену по согласованию';
export const AGREED_PRICE_TEXT = 'Цена по согласованию';

// snackbar notifications
export const LINK_COPIED = 'Ссылка была скопирована в буфер обмена';
export const TEXT_COPIED = 'Текст скопирован в буфер обмена';
export const SSF_NUMBER_COPIED = 'Номер ССФ скопирован в буфер обмена';
export const PO_NUMBER_CREATED_SNACKBAR = 'Номер ЗП сохранён';

//buttons
export const SEND_TO_APPROVE = 'Отправить на проверку';
export const DISCARD_BUTTON_TITLE = 'Сбросить';
export const APPLY_BUTTON_TITLE = 'Применить';
export const GO_TO_BASIS_TITLE = 'Перейти в БАЗИС';
export const CREATE_JOB_TITLE = 'Создать задание';

// agreements
export const ADDITIONAL_AGREEMENT_SHORT_TITLE = 'ДС';
export const ADDITIONAL_AGREEMENT_FULL_TITLE = 'Доп. соглашение';
export const ADDITIONAL_AGREEMENTS_FULL_TITLE = 'Дополнительные соглашения';
export const AGREEMENT_ADDITION_TITLE = 'Дополнение';
export const AGREEMENT_TERMINATION_TITLE = 'Расторжение ДС';
export const TERMINATION_TITLE = 'Расторжение';
export const SUM_TITLE = 'Итого без НДС';
export const TAX_TITLE = `Налог на добавленную стоимость:`;
export const NO_TAX_TITLE = 'НДС не облагается';
export const SHEET_TOTAL_SUM_TITLE = 'Итого по ведомости с НДС:';
export const SHEET_TOTAL_SUM_WITHOUT_TAX_TITLE = 'Итого по ведомости:';
export const AGREEMENT_SUM_TITLE = 'Итого по ДС без НДС:';
export const AGREEMENT_TOTAL_SUM_TITLE = 'Итого по ДС с НДС:';
export const AGREEMENT_TOTAL_SUM_WITHOUT_TAX_TITLE = 'Итого по ДС:';
export const EXTRA_JOBS_TITLE = 'Дополнительные работы';
export const EXTRA_JOBS_CATEGORY_TITLE = 'Категория дополнительных работ';
export const JOB_DESCRIPTION_TITLE = 'Описание работ';
export const NO_RESULT_TITLE = 'Нет результатов';

// hints
export const PROHIBITED_COEFFICIENT_EDITING_HINT =
    'Невозможно редактирование коэффициентов, т.к. пункт закрыт в ВВР';
export const PROHIBITED_SHEET_DELETION_HINT =
    'Невозможно удалить ведомость, т.к. есть закрытые пункты в ВВР';
export const PROHIBITED_OBJECT_DELETION_HINT =
    'Невозможно удалить объект, т.к. есть закрытые пункты в ВВР';
export const PROHIBITED_SPEC_DELETION_HINT = 'Невозможно удалить пункт, т.к. он закрыт в ВВР';
export const IRREVERSIBLE_ACTION_HINT = 'Отменить это действие будет невозможно';
export const DELETION_CONFIRMING_HINT = 'Вы уверены, что хотите удалить объект со всеми работами?';
export const SHEET_DELETION_CONFIRMING_HINT =
    'Вы уверены, что хотите удалить ведомость со всеми работами?';
export const PREPAYMENT_HINT =
    'Вы выбрали пункты работ, которые предполагают получение аванса. Чтобы отказаться от авансирования снимите галочку.';

// helpers
export const INSERT_COMMENT_HELPER = 'Введите комментарий';
export const WRITE_YOUR_COMMENT_HELPER = 'Напишите свой общий комментарий';
export const NO_VAT_CALCULATION_HELPER = 'Все расчеты указаны без учета НДС';
export const OBLIGATORY_FOR_INCREASING_COEFFICIENT_AND_AGREED_PRICE_HELPER =
    'Обязательно для пунктов с повышающим коэффициентом и ценой по согласованию';

// labels
export const DOCUMENT_TYPE_LABEL = 'Тип документа';
export const UNNECESSARY_TEXTAREA_LABEL = 'Комментарий (при необходимости)';

// project work stages
export const STAGES_TITLE = 'Этапы';
export const DOCUMENTS_TITLE = 'Документы';
export const JOB_STAGE_TITLE = 'Выдача в работу';
export const ADDITIONAL_AGREEMENT_STAGE_TITLE = 'Доп. соглашения';
export const PIR_STAGE_TITLE = 'ПИР';
export const ACCESS_TO_OBJECT_STAGE_TITLE = 'Доступ на объект';
export const LOGISTIC_STAGE_TITLE = 'Логистика';
export const SMR_STAGE_TITLE = 'СМР';
export const TECHNICAL_ACCEPTANCE_STAGE_TITLE = 'Техническая приемка';
export const STAGE_UNDER_DEVELOPMENT_TITLE = 'Раздел в разработке';
export const FORBIDDEN_STAGE_TITLE = 'Раздел недоступен';

// errors
export const TAX_SETTINGS_ARE_NOT_FOUND_TITLE =
    'Не найдена настройка налогов для организации. Напишите на ';
export const WRONG_VAT_RATE_TITLE = 'В документе обнаружено несоответствие ставки НДС.';

export const EMPTY_STRING = '';

// empty filtered data
export const EMPTY_FILTERED_DATA_TITLE = 'По заданным фильтрам ничего не найдено';
export const TRY_CHANGE_FILTERS_SUBTITLE = 'Попробуйте изменить параметры или сбросьте фильтры';
