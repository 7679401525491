import React from 'react';
import {Typography} from '@beeline/design-system-react';
import {Comment} from 'Entities/Comment';

import {TrimTextArea} from 'Features/TrimTextArea';
import {IComment} from 'shared/types/commonTypes';
import {DOCUMENT_COMMENTS_TITLE, UNNECESSARY_TEXTAREA_LABEL} from 'shared/constants';

import styles from './style.module.scss';

interface CommentListUnitProps {
    currentComment?: string;
    onChangeComment?: (value: string) => void;
    commentList: IComment[];
}

export const CommentListUnit = ({
    commentList,
    currentComment,
    onChangeComment,
}: CommentListUnitProps) => {
    return (
        <div className={styles.wrapper}>
            <Typography variant="h5">{DOCUMENT_COMMENTS_TITLE}</Typography>
            {!!onChangeComment && (
                <TrimTextArea
                    value={currentComment}
                    onChange={onChangeComment}
                    label={UNNECESSARY_TEXTAREA_LABEL}
                    fullWidth
                />
            )}
            {commentList.map((item) => (
                <Comment key={item.created_at} {...item} />
            ))}
        </div>
    );
};
