import React from 'react';
import {Counter, IconButton} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

export interface CommentButtonProps {
    onClick: () => void;
    hasComments?: boolean;
    showButton?: boolean;
    attachmentCount?: number;
}

export const CommentButton = ({
    onClick,
    hasComments = false,
    showButton = true,
    attachmentCount = 0,
}: CommentButtonProps) => {
    const iconName = hasComments ? Icons.MessageText : Icons.Message;
    return (
        <>
            {showButton && (
                <Counter
                    count={attachmentCount}
                    className={`${attachmentCount === 0 && 'emptyCounter'}`}
                >
                    <IconButton iconName={iconName} onClick={onClick} size="large" />
                </Counter>
            )}
        </>
    );
};
