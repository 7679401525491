import {ProjectType} from 'shared/services/types/types';
import {EDocumentType} from 'shared/types/documentTypes';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions, EObjectAccessActions, TAccessActions} from 'shared/const/actions';

export enum EProjectStages {
    JOBS = 1,
    ADDITIONAL_AGREEMENTS = 4,
    LOGISTICS = 5,
    TECH_ACCEPTANCE = 7,
    COMPLETED_WORKS = 8,
}

export enum EProjectTabs {
    STAGES = 0,
    DOCUMENTS = 1,
    NONE = 2,
}

export interface IProjectViewPermissionStore {
    hasStagesTab: boolean;
    hasJobsStage: boolean;
    hasJobCreation: boolean;
    hasJobList: boolean;
    hasAgreementsStage: boolean;
    hasAgreementCreation: boolean;
    hasAgreementList: boolean;
    hasLogisticsStage: boolean;
    hasLogisticsList: boolean;
    hasLogisticsCreation: boolean;
    hasTechAcceptanceStage: boolean;
    hasTechAcceptanceList: boolean;
    hasCompletedWorksStage: boolean;
    hasCompletedWorkList: boolean;
    hasCompletedWorkCreation: boolean;
    hasTechAcceptanceCreation: boolean;
    hasDocumentsTab: boolean;
    hasDocumentCreation: boolean;
    hasDocumentList: boolean;
    hasDevelop: boolean;
}

export const preparePermissionStore = (
    permissionStore: IProjectViewPermissionStore,
    actions: TAccessActions,
    pilotBranchList: string[],
    project: ProjectType | null,
): IProjectViewPermissionStore => {
    const result = {...permissionStore};
    result.hasDevelop = userHasPermission(EAccessActions.Development, actions);
    result.hasJobList = userHasPermission(EAccessActions.JobTaskList, actions);
    result.hasJobCreation = userHasPermission(EAccessActions.JobTaskCreation, actions);
    result.hasAgreementList = userHasPermission(EAccessActions.AgreementList, actions);
    result.hasAgreementCreation = userHasPermission(EAccessActions.AgreementCreate, actions);
    result.hasLogisticsList = userHasPermission(
        {
            permission: EObjectAccessActions.DocumentsList,
            type: EDocumentType.LOGISTIC,
        },
        actions,
    );
    result.hasLogisticsCreation = userHasPermission(
        {
            permission: EObjectAccessActions.DocumentsCreation,
            type: EDocumentType.LOGISTIC,
        },
        actions,
    );
    result.hasTechAcceptanceList = userHasPermission(
        {permission: EObjectAccessActions.DocumentsList, type: EDocumentType.TECHNICAL},
        actions,
    );
    result.hasTechAcceptanceCreation = userHasPermission(
        {permission: EObjectAccessActions.DocumentsCreation, type: EDocumentType.TECHNICAL},
        actions,
    );
    result.hasCompletedWorkList = userHasPermission(EAccessActions.CompletedWorksList, actions);
    result.hasCompletedWorkCreation = userHasPermission(
        EAccessActions.CompletedWorksCreation,
        actions,
    );
    result.hasJobsStage = result.hasJobCreation || result.hasJobList;
    result.hasAgreementsStage = result.hasAgreementCreation || result.hasAgreementList;
    result.hasLogisticsStage = result.hasLogisticsCreation || result.hasLogisticsList;
    result.hasTechAcceptanceStage =
        (result.hasTechAcceptanceList || result.hasTechAcceptanceCreation) &&
        pilotBranchList.includes(project?.branch ?? '');
    result.hasCompletedWorksStage = userHasPermission(EAccessActions.CompletedWorksList, actions);
    result.hasDocumentCreation = userHasPermission(
        {permission: EObjectAccessActions.DocumentsCreation, type: EDocumentType.DOCUMENTS},
        actions,
    );
    result.hasDocumentList = userHasPermission(
        {permission: EObjectAccessActions.DocumentsList, type: EDocumentType.DOCUMENTS},
        actions,
    );
    result.hasDocumentsTab = result.hasDocumentCreation || result.hasDocumentList;
    result.hasStagesTab =
        result.hasJobsStage ||
        result.hasAgreementsStage ||
        result.hasLogisticsStage ||
        result.hasTechAcceptanceStage ||
        result.hasCompletedWorksStage;
    return result;
};
