import axios from 'axios';

import {postRefreshToken} from 'shared/services/auth.service';

import {API_URL} from '../const/url';
import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';

export const axiosInstance = axios.create({
    baseURL: API_URL,
    responseType: 'json',
});

export const axiosInstanceWithoutInterceptors = axios.create({
    baseURL: API_URL,
    responseType: 'json',
});

axiosInstance.interceptors.response.use(
    (response) => {
        if (response?.data?.token) {
            localStorage.setItem('token', response.data.token);
        }
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            const originalRequest = error.config;
            const tokenResponse = await postRefreshToken();
            if (tokenResponse === 200) {
                return axiosInstance(originalRequest);
            }
        } else if (error.response.status === 403 || error.response.status === 404) {
            window.location.replace(`${window.location.origin}/forbidden`);
        } else if (error.response.status >= 400) {
            let snackbarMessage = error.response.data.error ?? error.response.data.message ?? '';
            if (Array.isArray(snackbarMessage)) {
                snackbarMessage = snackbarMessage.join(`\n`);
            }
            snackbarNotificationChanged(snackbarMessage);
        }

        return error;
    },
);

axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    return config;
});
