import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button, Checkbox, DatePicker, Switch, Typography} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {CommentListUnit} from 'widgets/CommentListUnit';
import {ActionButtonsUnit} from 'Features/ActionButtonsUnit';
import {ConstructorHeader} from 'Entities/ConstructorHeader';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {useFileUploader} from 'shared/ui/use-file-uploader';
import {prepareTFileList} from 'shared/helpers/transformObject';
import {formatDate} from 'shared/helpers/formatHelper';
import {DOCUMENT_ALLOWED_FORMAT_LIST, MAX_ALLOWED_UPLOAD_FILE_SIZE} from 'shared/const/fileFormats';

import styles from './style.module.scss';
import {RequestCreationTable} from './RequestCreationTable';
import {
    $requestCreationPageStore,
    commonCommentChanged,
    isUrgentToggled,
    plannedDateChanged,
    RequestCreationPageGate,
    requestSaved,
} from '../model';

export const RequestCreationPage = () => {
    const {projectId} = useParams<{projectId: string}>();
    useGate(RequestCreationPageGate);
    const {requestData} = useStore($requestCreationPageStore);
    const [FileUploader, files] = useFileUploader({
        fileUploaderName: 'Overall_add.agreement attachments',
        maxFileSize: MAX_ALLOWED_UPLOAD_FILE_SIZE,
        acceptFormats: DOCUMENT_ALLOWED_FORMAT_LIST,
        maxFiles: 10,
        mode: 'creation',
        initialFiles: prepareTFileList([]),
    });

    if (!requestData) return null;
    const {is_urgent, planned_date, document_comment, comment, created_at, organization_gpo} =
        requestData;
    const issueDate = formatDate(created_at);

    return (
        <div>
            <ConstructorHeader>
                <Typography>{`Запрос на выдачу оборудования от ${issueDate} ${organization_gpo}`}</Typography>
                <Button size="medium" onClick={() => navigationInvoked({to: -1})}>
                    Отменить
                </Button>
            </ConstructorHeader>
            <div className={styles.content}>
                <div grid-area="action" className={styles.actionUnit}>
                    <WrapperFlex alignItems="center" gap="32px">
                        <DatePicker
                            label="Желаемая дата"
                            value={planned_date ?? undefined}
                            onChange={plannedDateChanged}
                        />
                        <Checkbox
                            label="Срочное требование"
                            checked={is_urgent}
                            onChange={(e) => isUrgentToggled(e.currentTarget.checked)}
                        />
                        <Switch label="Только измененные" />
                    </WrapperFlex>
                    <ActionButtonsUnit
                        negativeBtnLabel={'Сохранить черновик'}
                        positiveBtnLabel={'Отправить на согласование'}
                        handleNegativeBtn={() => requestSaved()}
                        handlePositiveBtn={() => 2}
                    />
                </div>
                <RequestCreationTable />
                <div className={styles.attachmentUnit}>
                    <Typography variant="h5">Вложения</Typography>
                    <FileUploader />
                </div>
                <div className={styles.commentUnit}>
                    <CommentListUnit
                        commentList={document_comment}
                        currentComment={comment}
                        onChangeComment={commonCommentChanged}
                    />
                </div>
            </div>
        </div>
    );
};
