import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {IJobListParams} from 'shared/services/types/paramsTypes';
import {fetchJobList} from 'shared/services/jobs.service';
import {ITableParams} from 'shared/types/commonTypes';
import {TJobList} from 'shared/types/jobsTypes';

export const JobStageDomain = createDomain();
export const JobStageGate = createGate<string>();

// effects
export const getJobListFx = JobStageDomain.createEffect(async (data: IJobListParams) => {
    try {
        const result = await fetchJobList(data);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

// events
export const jobPageChanged = JobStageDomain.createEvent<number>();
export const jobPageSizeChanged = JobStageDomain.createEvent<number>();

// stores
const $jobListParams = JobStageDomain.createStore<ITableParams>({page: 1, pageSize: 10});
const $jobListData = JobStageDomain.createStore<TJobList>([]).on(
    getJobListFx.doneData,
    (_, payload) => payload?.results,
);
export const $jobListCount = JobStageDomain.createStore<number>(0).on(
    getJobListFx.doneData,
    (_, payload) => payload?.count,
);

export const $jobTableStore = combine({
    total: $jobListCount,
    params: $jobListParams,
    tableData: $jobListData,
    isLoading: getJobListFx.pending,
});

sample({
    clock: [JobStageGate.state, $jobListParams],
    source: combine({
        params: $jobListParams,
        projectId: JobStageGate.state,
    }),
    filter: ({projectId}) => typeof projectId === 'string' && !Number.isInteger(+(projectId ?? '')),
    fn: ({params, projectId}) => ({...params, projectId: projectId ?? ''}),
    target: getJobListFx,
});

sample({
    clock: jobPageChanged,
    source: $jobListParams,
    filter: ({page}, newPage) => page !== newPage,
    fn: (params, page) => ({...params, page}),
    target: $jobListParams,
});

sample({
    clock: jobPageSizeChanged,
    source: $jobListParams,
    filter: ({pageSize}, newPageSize) => pageSize !== newPageSize,
    fn: (params, pageSize) => ({page: 1, pageSize}),
    target: $jobListParams,
});

