import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {fetchRawDocument} from 'shared/services/documents.service';
import {IDocument} from 'shared/types/documentTypes';
import {requestEditingInvoked} from '../../RequestCreationPage/model';

export const RequestViewPageDomain = createDomain();
export const RequestViewPageGate = createGate<string>();

// effects
const getRequestData = RequestViewPageDomain.effect(async (id: string) => {
    try {
        const result = await fetchRawDocument(id);
        return result.data;
    } catch (e) {
        console.warn(e);
        return null;
    }
});

// events
export const editRequestClicked = RequestViewPageDomain.event();
export const commentRowToggled = RequestViewPageDomain.event<number>();
// stores

const $requestData = RequestViewPageDomain.store<IDocument | null>(null);
const $expandedItemId = RequestViewPageDomain.store<number | null>(null).on(
    commentRowToggled,
    (state, payload) => {
        return state === payload ? null : payload;
    },
);

export const $requestViewPageStore = combine({
    requestData: $requestData,
    expandedItemId: $expandedItemId,
    isLoading: getRequestData.pending,
});

sample({
    clock: RequestViewPageGate.open,
    target: getRequestData,
});

sample({
    clock: getRequestData.doneData,
    target: $requestData,
});

sample({
    clock: editRequestClicked,
    source: $requestData,
    filter: (source) => !!source,
    target: requestEditingInvoked,
});
