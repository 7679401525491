import styled, {css} from 'styled-components';
import {Table} from '@beeline/design-system-react';

export const TableWrapper = styled.div<{$hasPagination?: boolean; $noBorder?: boolean}>`
    min-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    border: var(--size-border-width-regular) solid var(--color-border);
    border-radius: var(--size-border-radius-x2);
    ${(props) =>
        props.$hasPagination &&
        css`
            padding-bottom: 16px;
        `}${(props) =>
        props.$noBorder &&
        css`
            border: none;
        `}
`;

export const TableOverflowContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export const StyledTable = styled(Table)<{$minWidth?: string; $hasPagination?: boolean}>`
    table-layout: fixed;
    width: 100%;
    min-width: 800px;
    ${(props) =>
        props.$minWidth &&
        css`
            min-width: ${props.$minWidth};
        `}
    border-width: 0;
    ${(props) =>
        props.$hasPagination &&
        css`
            border-bottom: var(--size-border-width-regular) solid var(--color-border);
        `}
`;
