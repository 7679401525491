import React, {useState} from 'react';
import {MaskField} from '@beeline/design-system-react';

import * as S from './style';
import styles from './QuantityCell.module.scss';

interface QuantityCellProps {
    value: string | number;
    maxValue: number;
    isEditable: boolean;
    onChange: (num: number) => void;
}

export const QuantityCell = ({value, maxValue, isEditable, onChange}: QuantityCellProps) => {
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        setIsActive(true);
    };
    const handleBlur = () => {
        setIsActive(false);
    };
    const mask = {
        mask: Number,
        min: 0,
        max: maxValue,
        scale: 0,
        thousandsSeparator: ' ',
    };
    return (
        <S.StyledContainer $isActive={isActive} $isEditable={isEditable} onClick={handleClick}>
            <MaskField
                className={styles.maskedFieldWrapper}
                inputClassName={styles.maskedField}
                defaultValue={value === 0 ? value.toString() : value}
                value={value}
                maskConfig={mask}
                onChange={(value, unmaskedValue, event) => {
                    if (!!value) onChange(+value);
                }}
                onBlur={handleBlur}
                onKeyDownCapture={(e) => {
                    if (e.key === 'Enter') {
                        // @ts-ignore
                        handleBlur(e);
                    }
                }}
                size="small"
            />
        </S.StyledContainer>
    );
};
