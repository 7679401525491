import React from 'react';
import {Typography} from '@beeline/design-system-react';
import {sizeSpacingX1} from '@beeline/design-tokens/js/tokens';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from './style';

export interface ITabList {
    title: string;
    subtitle?: string;
    disabled?: boolean;
}

interface VerticalTabProps {
    tabList: ITabList[];
    selectedTabIndex: number;
    onSelect: (index: number) => void;
}

export const VerticalTab = ({tabList, selectedTabIndex, onSelect}: VerticalTabProps) => {
    return (
        <WrapperFlex flex="0 0 15%" flexDirection="column" gap={sizeSpacingX1}>
            {tabList.map((item, index) => (
                <S.TabItem
                    key={item.title}
                    onClick={() => !item.disabled && onSelect(index + 1)}
                    active={selectedTabIndex === index + 1}
                    disabled={item.disabled}
                >
                    <Typography variant="body2">{item.title}</Typography>
                    <Typography className="inactiveText" variant="body3">
                        {item.subtitle}
                    </Typography>
                </S.TabItem>
            ))}
        </WrapperFlex>
    );
};
