import React from 'react';
import {useLocation} from 'react-router-dom';
import {useGate, useUnit} from 'effector-react';
import {Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {sizeSpacingX4} from '@beeline/design-tokens/js/tokens';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {DocumentTable} from 'widgets/DocumentTable';
import {DocumentCreationButton} from 'Features/buttons/DocumentCreationButton';
import {EmptyTab} from 'Entities/EmptyTab';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserVK} from 'shared/model/user';
import {IPaginationData} from 'shared/types/commonTypes';
import {EDocumentType} from 'shared/types/documentTypes';
import {CREATE_TITLE, TECHNICAL_ACCEPTANCE_STAGE_TITLE} from 'shared/constants';

import {columns} from './columns';
import {
    COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE,
    TECHNICAL_ACCEPTANCE_STAGE_EMPTY_TITLE,
} from '../../../../constants';
import {
    $documentTableStore,
    rowExpanded,
    setPage,
    setPageSize,
    TechnicalAcceptanceStageGate,
} from '../../../../model/technicalAcceptanceStage';

interface TechnicalAcceptanceStageProps {
    projectId: string;
}

export const TechnicalAcceptanceStage = ({projectId}: TechnicalAcceptanceStageProps) => {
    useGate(TechnicalAcceptanceStageGate, projectId);
    const location = useLocation();
    const [
        {
            documentsStore,
            documentListParams: {page, page_size},
            expandedRowList,
            tableIsLoading,
            paginationCount,
            documentListAllowed,
            creationAllowed,
        },
        isVk,
    ] = useUnit([$documentTableStore, $isUserVK]);

    const paginationData: IPaginationData = {
        total: paginationCount,
        isLoading: tableIsLoading,
        page,
        pageSize: page_size,
        setPage,
        setPageSize,
    };
    const visibleColumns = isVk ? columns : columns.filter((col) => col.name !== 'contractor');
    const tableIsEmpty = paginationCount === 0 && !tableIsLoading;

    return (
        <WrapperFlex flexDirection="column" alignItems="flex-start" gap={sizeSpacingX4}>
            <Typography variant="h5">{TECHNICAL_ACCEPTANCE_STAGE_TITLE}</Typography>
            {tableIsEmpty ? (
                <EmptyTab
                    iconName={Icons.PageEmpty}
                    showButton={creationAllowed}
                    color="blue"
                    title={TECHNICAL_ACCEPTANCE_STAGE_EMPTY_TITLE}
                    subtitle={COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE}
                    buttonTitle={CREATE_TITLE}
                    onClick={() =>
                        navigationInvoked({to: `${location.pathname}/tech-acceptance-creation`})
                    }
                />
            ) : (
                <>
                    {creationAllowed && <DocumentCreationButton type={EDocumentType.TECHNICAL} />}
                    {documentListAllowed && (
                        <DocumentTable
                            columns={visibleColumns}
                            onRowClick={rowExpanded}
                            data={documentsStore}
                            expandedRowList={expandedRowList}
                            paginationData={paginationData}
                            minTableWidth="1400px"
                        />
                    )}
                </>
            )}
        </WrapperFlex>
    );
};
