import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {fetchDocumentsList} from 'shared/services/documents.service';
import {
    EDocumentType,
    IDocumentsListQueryParams,
    TDocumentsListItem,
} from 'shared/types/documentTypes';
import {IResponseWithPagination} from 'shared/types/apiParamsTypes';

export const LogisticsStageDomain = createDomain();
export const LogisticsStageGate = createGate<string>();

// effects
const getLogisticsListFx = LogisticsStageDomain.effect(async (params: IDocumentsListQueryParams) => {
    try {
        const result = await fetchDocumentsList(params);
        return result.data;
    } catch (e) {
        console.warn(e);
        return {results: [], count: 0};
    }
});
// events
export const pageChanged = LogisticsStageDomain.event<number>();
export const pageSizeChanged = LogisticsStageDomain.event<number>();
export const tableReset = LogisticsStageDomain.event();
// stores
const $logisticsTableParams = LogisticsStageDomain.store<IDocumentsListQueryParams>({
    project_id: '',
    page: 1,
    page_size: 10,
    project_section: EDocumentType.LOGISTIC,
});
const $backendLogisticsData = LogisticsStageDomain.store<IResponseWithPagination<TDocumentsListItem>>(
    {
        results: [],
        count: 0,
    },
);

export const $count = $backendLogisticsData.map((data) => data?.count ?? 0);

export const $logisticTabStore = combine({
    logisticsList: $backendLogisticsData.map((data) => data?.results ?? []),
    count: $count,
    isLoading: getLogisticsListFx.pending,
    tableParams: $logisticsTableParams,
});

sample({
    clock: LogisticsStageGate.open,
    source: $logisticsTableParams,
    filter: (_, projectId) => !!projectId,
    fn: (store, projectId) => ({...store, project_id: projectId}),
    target: $logisticsTableParams,
});

sample({
    clock: $logisticsTableParams,
    filter: (store) => !!store.project_id,
    target: getLogisticsListFx,
});

sample({
    clock: getLogisticsListFx.doneData,
    fn: (response) => response,
    target: $backendLogisticsData,
});

$logisticsTableParams
    .on(pageChanged, (store, newPage) => ({...store, page: newPage}))
    .on(pageSizeChanged, (store, newPageSize) => ({
        ...store,
        page: 1,
        page_size: newPageSize,
    }))
    .on(tableReset, (store) => ({...store, page: 1, page_size: 10}));
