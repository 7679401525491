import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {fetchDocumentsList} from 'shared/services/documents.service';
import {$userActions} from 'shared/model/user';
import {
    EDocumentType,
    IDocumentsListQueryParams,
    TDocumentsListItem,
} from 'shared/types/documentTypes';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EObjectAccessActions} from 'shared/const/actions';

export const TechnicalAcceptanceStageDomain = createDomain();
export const TechnicalAcceptanceStageGate = createGate<string>();

interface DocumentsListParamsType {
    project_id: string;
    page: number;
    page_size: number;
}

//effects
export const fetchDocumentsDataFx = TechnicalAcceptanceStageDomain.effect(
    async (params: DocumentsListParamsType) => {
        try {
            const resultParams: IDocumentsListQueryParams = {
                project_id: params.project_id,
                page: params.page,
                page_size: params.page_size,
                project_section: EDocumentType.TECHNICAL,
            };
            const result = await fetchDocumentsList(resultParams);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

// events
export const setPage = TechnicalAcceptanceStageDomain.event<number>();
export const setPageSize = TechnicalAcceptanceStageDomain.event<number>();
export const rowExpanded = TechnicalAcceptanceStageDomain.event<string>();
export const domainHasBeenReset = TechnicalAcceptanceStageDomain.event();

// stores
const $documentsStore = TechnicalAcceptanceStageDomain.store<TDocumentsListItem[]>([]).on(
    fetchDocumentsDataFx.doneData,
    (state, payload) => payload?.results,
);

export const $paginationCount = TechnicalAcceptanceStageDomain.store<number>(0).on(
    fetchDocumentsDataFx.doneData,
    (_, payload) => payload?.count ?? 0,
);

const $documentsListParams = TechnicalAcceptanceStageDomain.store<DocumentsListParamsType>({
    project_id: '',
    page: 1,
    page_size: 10,
})
    .on(setPage, (state, payload) => ({...state, page: payload}))
    .on(setPageSize, (state, payload) => ({
        ...state,
        page: 1,
        page_size: payload,
    }));

const $expandedRowList = TechnicalAcceptanceStageDomain.store<string[]>([]);
const $creationAllowed = TechnicalAcceptanceStageDomain.store<boolean>(false);
const $documentListAllowed = TechnicalAcceptanceStageDomain.store<boolean>(false);

export const $documentTableStore = combine({
    documentsStore: $documentsStore,
    documentListParams: $documentsListParams,
    paginationCount: $paginationCount,
    tableIsLoading: fetchDocumentsDataFx.pending,
    expandedRowList: $expandedRowList,
    creationAllowed: $creationAllowed,
    documentListAllowed: $documentListAllowed,
});

// проверка доступа к созданию док-та
sample({
    clock: TechnicalAcceptanceStageGate.open,
    source: $userActions,
    fn: (actions) =>
        userHasPermission(
            {
                permission: EObjectAccessActions.DocumentsCreation,
                type: EDocumentType.TECHNICAL,
            },
            actions,
        ),
    target: $creationAllowed,
});

// проверка доступа к таблице док-ов
sample({
    clock: TechnicalAcceptanceStageGate.open,
    source: $userActions,
    fn: (actions) =>
        userHasPermission(
            {
                permission: EObjectAccessActions.DocumentsList,
                type: EDocumentType.TECHNICAL,
            },
            actions,
        ),
    target: $documentListAllowed,
});

// запись project_id при открытии страницы
sample({
    clock: TechnicalAcceptanceStageGate.open,
    source: combine({params: $documentsListParams, documentListAllowed: $documentListAllowed}),
    filter: ({documentListAllowed}, gate) => !!gate && documentListAllowed,
    fn: ({params}, gate) => ({...params, project_id: gate}),
    target: $documentsListParams,
});

// refetch данных при изменении параметров
sample({
    source: $documentsListParams,
    filter: (source) => source.project_id !== '',
    target: fetchDocumentsDataFx,
});

sample({
    clock: rowExpanded,
    source: $expandedRowList,
    fn: (list, expandedRowId) => {
        if (list.includes(expandedRowId)) {
            return list.filter((el) => el !== expandedRowId);
        }
        return [...list, expandedRowId];
    },
    target: $expandedRowList,
});

resetDomainStoresByEvents(TechnicalAcceptanceStageDomain, domainHasBeenReset);
