import React from 'react';
import {useLocation} from 'react-router-dom';
import {Button} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {EDocumentType} from 'shared/types/documentTypes';
import {CREATE_TITLE} from 'shared/constants';


interface DocumentCreationButtonProps {
    type: EDocumentType;
}

export const DocumentCreationButton = ({type}: DocumentCreationButtonProps) => {
    const location = useLocation();
    const handleCreateDocument = () => {
        switch (type) {
            case EDocumentType.DOCUMENTS: {
                navigationInvoked({
                    to: `${location.pathname}/document-creation`,
                });
                break;
            }
            case EDocumentType.TECHNICAL: {
                navigationInvoked({to: `${location.pathname}/tech-acceptance-creation`});
                break;
            }
            case EDocumentType.LOGISTIC: {
                navigationInvoked({to: `${location.pathname}/logistic-creation`});
                break;
            }
        }
    };
    return (
        <Button variant="outlined" size="medium" onClick={handleCreateDocument}>
            {CREATE_TITLE}
        </Button>
    );
};
