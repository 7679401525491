import {EDocumentType} from '../types/documentTypes';

export type TAccessActions = (EAccessActions | TDocumentsAccessAction)[];

export type TRouteAccessActions =
    | EAccessActions
    | {permission: EObjectAccessActions; type: EDocumentType};

export enum EAccessActions {
    AgreementCreate = 'agreement_create',
    AgreementDelete = 'agreement_delete',
    AgreementDownload = 'agreement_download',
    AgreementEdit = 'agreement_edit',
    AgreementList = 'agreement_list',
    AgreementCard = 'agreement_card',
    ProjectList = 'projects_list',
    ProjectCard = 'projects_card',
    FinancialDocumentsCreation = 'documents_create_financial',
    JobTaskCreation = 'job_tasks_create',
    JobTaskList = 'job_tasks_list',
    JobTaskCard = 'job_tasks_card',
    ProcessesList = 'process_list',
    CompletedWorksList = 'completed_works_list',
    CompletedWorksCreation = 'completed_works_create',
    CompletedWorksEditing = 'completed_works_edit',
    CompletedWorksCard = 'completed_works_card',
    DocumentSigning = 'process_signature',
    ContractList = 'contracts_list',
    ContractCard = 'contracts_card',
    ContractListLinkShowing = 'contracts_show_hyperlink',
    ContractListTpiCard = 'contracts_tpi_card',
    Development = 'development',
    WhiteList = 'constants_whitelist_imProjectGroup',
}

export enum EObjectAccessActions {
    DocumentsCard = 'documents_card',
    DocumentsCreation = 'documents_create',
    DocumentsDownload = 'documents_download',
    DocumentsEdit = 'documents_edit',
    DocumentsList = 'documents_list',
}

export type TDocumentsAccessAction = Record<EObjectAccessActions, EDocumentType[]>;
