import {combine, createDomain, createStore, forward, sample} from 'effector';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {createGate} from 'effector-react';

import {ProjectType} from 'shared/services/types/types';
import {getProjectData, getProjectId} from 'shared/services/projects.service';
import {fetchPilotBranches} from 'shared/services/catalogs.service';
import {$userActions} from 'shared/model/user';

import {additionalAgreementsDomain} from './addditionalAgreementsStage';
import {JobStageDomain} from './jobStage';
import {CompletedWorksStageDomain} from './completedWorksStage';
import {LogisticsStageDomain} from './logisticsStage';
import {DocumentTableDomain} from './documentTab';
import {
    EProjectStages,
    EProjectTabs,
    IProjectViewPermissionStore,
    preparePermissionStore,
} from './helpers';

export const ProjectViewDomain = createDomain();
export const ProjectViewGate = createGate<string | undefined>();

//Events
export const fetchProjectData = ProjectViewDomain.createEvent<string>();
export const stageIndexSet = ProjectViewDomain.createEvent<number>();
export const tabIndexSet = ProjectViewDomain.createEvent<number>();

//Effects
export const fetchProjectDataFx = ProjectViewDomain.createEffect(async (projectId: string) => {
    try {
        const result = await getProjectData(projectId);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

export const fetchProjectIdFx = ProjectViewDomain.createEffect(async (projectId: number) => {
    try {
        const result = await getProjectId(projectId);
        return result.data.id;
    } catch (e) {
        console.warn(e);
    }
});

export const getPilotBranchesFx = ProjectViewDomain.createEffect(async () => {
    try {
        const result = await fetchPilotBranches();
        return result.data.technical;
    } catch (e) {
        console.warn(e);
    }
});

//Stores
const $currentStageIndex = createStore<EProjectStages>(EProjectStages.JOBS).on(
    stageIndexSet,
    forwardPayload(),
);
const $currentTabIndex = createStore<EProjectTabs>(EProjectTabs.STAGES).on(
    tabIndexSet,
    forwardPayload(),
);
const $projectData = ProjectViewDomain.createStore<ProjectType | null>(null).on(
    fetchProjectDataFx.doneData,
    forwardPayload(),
);

const $projectId = ProjectViewDomain.createStore<string>('').on(
    fetchProjectIdFx.doneData,
    forwardPayload(),
);

const $availablePilotBranchList = ProjectViewDomain.store<string[]>([]);

const $permissionStore = ProjectViewDomain.store<IProjectViewPermissionStore>({
    hasDocumentsTab: false,
    hasStagesTab: false,
    hasJobsStage: false,
    hasJobList: false,
    hasJobCreation: false,
    hasAgreementsStage: false,
    hasAgreementList: false,
    hasAgreementCreation: false,
    hasLogisticsStage: false,
    hasLogisticsList: false,
    hasLogisticsCreation: false,
    hasTechAcceptanceStage: false,
    hasTechAcceptanceList: false,
    hasTechAcceptanceCreation: false,
    hasCompletedWorksStage: false,
    hasCompletedWorkList: false,
    hasCompletedWorkCreation: false,
    hasDocumentCreation: false,
    hasDocumentList: false,
    hasDevelop: false,
});

export const $viewProjectStore = combine({
    projectIdFromNri: $projectId,
    projectData: $projectData,
    availablePilotBranchList: $availablePilotBranchList,
    isLoading: fetchProjectDataFx.pending,
    currentStageIndex: $currentStageIndex,
    currentTabIndex: $currentTabIndex,
    permissionStore: $permissionStore,
});

export const $viewProjectPermissionStore = combine({
    permissionStore: $permissionStore,
});

sample({
    source: ProjectViewGate.state,
    filter: (clock) => typeof clock === 'string' && !Number.isInteger(+(clock ?? '')),
    fn: (clock) => clock ?? '',
    target: fetchProjectData,
});

forward({from: fetchProjectData, to: fetchProjectDataFx});

sample({
    source: ProjectViewGate.state,
    filter: (clock) => Number.isInteger(+(clock ?? '')),
    fn: (clock) => +(clock ?? 0),
    target: fetchProjectIdFx,
});

// получение регионов для пилота тех. приемки
sample({
    source: ProjectViewGate.state,
    filter: (clock) => typeof clock === 'string' && !Number.isInteger(+(clock ?? '')),
    target: getPilotBranchesFx,
});

// заполнения стора доступов
sample({
    clock: [$availablePilotBranchList, $userActions, $projectData],
    source: combine({
        actions: $userActions,
        permissionStore: $permissionStore,
        pilotBranchList: $availablePilotBranchList,
        project: $projectData,
        gateIsOpen: ProjectViewGate.status,
    }),
    filter: ({gateIsOpen}) => gateIsOpen,
    fn: ({actions, permissionStore, pilotBranchList, project}) =>
        preparePermissionStore(permissionStore, actions, pilotBranchList, project),
    target: $permissionStore,
});

// получение данных по пилоту тех. приемки (пилотный функционал)
sample({
    clock: getPilotBranchesFx.doneData,
    filter: (response) => !!response,
    fn: (response) => response ?? [],
    target: $availablePilotBranchList,
});

// редирект пользователя на другой этап, если пилотный этап недоступен
sample({
    clock: $permissionStore,
    source: combine({
        index: $currentStageIndex,
        gateIsOpen: ProjectViewGate.status,
    }),
    filter: ({gateIsOpen}) => gateIsOpen,
    fn: ({index}, store) => {
        switch (index) {
            case EProjectStages.TECH_ACCEPTANCE: {
                return store.hasTechAcceptanceStage
                    ? EProjectStages.TECH_ACCEPTANCE
                    : EProjectStages.ADDITIONAL_AGREEMENTS;
            }
        }
        return index;
    },
    target: $currentStageIndex,
});

// редирект пользователя на другой таб, если данный таб недоступен
sample({
    clock: $permissionStore,
    source: combine({
        index: $currentTabIndex,
        gateIsOpen: ProjectViewGate.status,
    }),
    filter: ({gateIsOpen}) => gateIsOpen,
    fn: ({index}, store) => {
        if (store.hasStagesTab && store.hasDocumentsTab) return index;
        switch (index) {
            case EProjectTabs.DOCUMENTS: {
                return store.hasDocumentsTab
                    ? EProjectTabs.DOCUMENTS
                    : store.hasStagesTab
                    ? EProjectTabs.STAGES
                    : EProjectTabs.NONE;
            }
            case EProjectTabs.STAGES: {
                return store.hasStagesTab
                    ? EProjectTabs.STAGES
                    : store.hasDocumentsTab
                    ? EProjectTabs.DOCUMENTS
                    : EProjectTabs.NONE;
            }
        }
        return index;
    },
    target: $currentTabIndex,
});

resetDomainStoresByEvents(ProjectViewDomain, ProjectViewGate.close);
resetDomainStoresByEvents(additionalAgreementsDomain, ProjectViewGate.close);
resetDomainStoresByEvents(CompletedWorksStageDomain, ProjectViewGate.close);
resetDomainStoresByEvents(JobStageDomain, ProjectViewGate.close);
resetDomainStoresByEvents(LogisticsStageDomain, ProjectViewGate.close);
resetDomainStoresByEvents(DocumentTableDomain, ProjectViewGate.close);
