import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useUnit} from 'effector-react';
import {Button, Skeleton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {createAgreementAddition, resetAdditionalAgreementCreation} from 'processes/contructor';
import {EmptyTab} from 'Entities/EmptyTab';
import {Menu} from 'shared/ui/Menu';
import {
    ADDITIONAL_AGREEMENT_SHORT_TITLE,
    ADDITIONAL_AGREEMENTS_FULL_TITLE,
    CREATE_ADDITION_TITLE,
    CREATE_TITLE,
    TO_TITLE,
} from 'shared/constants';

import {AdditionalAgreementsTable} from './AgreementsTable';
import {columns, innerColumns} from './allColumns';
import * as S from './style';
import {
    $additionalAgreementsTable,
    AdditionalAgreementsGate,
    createAddAgreementAddition,
} from '../../../../model/addditionalAgreementsStage';
import {selectProject} from '../../../../../VirStart/model';
import {$viewProjectPermissionStore} from '../../../../model';
import {
    AGREEMENTS_STAGE_EMPTY_TITLE,
    COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE,
} from '../../../../constants';

interface AdditionalAgreementsTabProps {
    projectId?: string;
    projectNumber?: number;
}

export const AdditionalAgreementsStage = ({
    projectNumber,
    projectId,
}: AdditionalAgreementsTabProps) => {
    useGate(AdditionalAgreementsGate, {projectId: projectId || ''});
    const navigate = useNavigate();
    const [
        {copyingAgreementList, isLoadingAddAgreementCopy, agreementCopy, isEmpty},
        {
            permissionStore: {hasAgreementCreation, hasAgreementList},
        },
    ] = useUnit([$additionalAgreementsTable, $viewProjectPermissionStore]);
    const creationIsAllowed = hasAgreementCreation;
    const listIsAllowed = hasAgreementList;
    const projectViewPageIsLoading = !projectNumber;
    if (agreementCopy) {
        createAgreementAddition(agreementCopy);
        navigate(`/additional-agreement/${agreementCopy.id}/constructor`);
    }

    const menuOptions = copyingAgreementList.map((item) => ({
        item: (
            <Typography variant="body2">{`${TO_TITLE.toUpperCase()} ${ADDITIONAL_AGREEMENT_SHORT_TITLE} ${
                item.number_agreement
            }`}</Typography>
        ),
        action: () => createAddAgreementAddition(item.id),
    }));

    const onClickCreateAgreement = () => {
        {
            if (projectNumber && projectId) {
                resetAdditionalAgreementCreation();
                selectProject({
                    project_id: projectId,
                    project_number: projectNumber.toString(),
                });
                navigate(`/projects/${projectId}/additional-agreement/create`);
            }
        }
    };

    return (
        <S.Wrapper>
            <Typography variant="h5">{ADDITIONAL_AGREEMENTS_FULL_TITLE}</Typography>
            {!isEmpty ? (
                <>
                    {creationIsAllowed && (
                        <S.ActionUnit>
                            {projectViewPageIsLoading ? (
                                <Skeleton variant="square" width="103px" height="48px" />
                            ) : (
                                <Button
                                    id="agreementCreationBtn"
                                    color="outline"
                                    size="medium"
                                    onClick={onClickCreateAgreement}
                                >
                                    {CREATE_TITLE}
                                </Button>
                            )}
                            {isLoadingAddAgreementCopy ? (
                                <Skeleton variant="square" width="196px" height="48px" />
                            ) : (
                                <Menu
                                    width="430px"
                                    title={CREATE_ADDITION_TITLE}
                                    disabled={menuOptions.length === 0 || isLoadingAddAgreementCopy}
                                    options={menuOptions}
                                    maxHeight
                                />
                            )}
                        </S.ActionUnit>
                    )}
                    {listIsAllowed && (
                        <AdditionalAgreementsTable columns={columns} innerColumns={innerColumns} />
                    )}
                </>
            ) : (
                <EmptyTab
                    iconName={Icons.PageEmpty}
                    color="blue"
                    showButton={creationIsAllowed}
                    title={AGREEMENTS_STAGE_EMPTY_TITLE}
                    subtitle={COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE}
                    buttonTitle={CREATE_TITLE}
                    onClick={onClickCreateAgreement}
                />
            )}
        </S.Wrapper>
    );
};
