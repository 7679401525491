import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useUnit} from 'effector-react';
import {Progress, Tab, Tabs, Typography} from '@beeline/design-system-react';
import {sizeSpacingX2, sizeSpacingX9} from '@beeline/design-tokens/js/tokens';
import {ViewProjectMainInfo} from 'Entities/ProjectMainInfo';
import {ViewPageTitle, ViewProjectTitleSkeleton} from 'Entities/ViewProjectTitle';
import {ITabList, VerticalTab} from 'Features/VerticalTab';

import {CommonLayout} from 'shared/ui/CommonLayout';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {
    ACCESS_TO_OBJECT_STAGE_TITLE,
    ADDITIONAL_AGREEMENT_STAGE_TITLE,
    DOCUMENTS_TITLE,
    FORBIDDEN_STAGE_TITLE,
    JOB_STAGE_TITLE,
    LOGISTIC_STAGE_TITLE,
    PIR_STAGE_TITLE,
    SMR_STAGE_TITLE,
    STAGE_UNDER_DEVELOPMENT_TITLE,
    STAGES_TITLE,
    TECHNICAL_ACCEPTANCE_STAGE_TITLE,
} from 'shared/constants';

import * as S from './style';
import {
    AdditionalAgreementsStage,
    CompletedWorksStage,
    JobStage,
    LogisticsStage,
    TechnicalAcceptanceStage,
} from './stages';
import {DocumentTab} from './tabs/DocumentTab';
import {COMPLETED_WORKS_TAB_TITLE, WORK_PROGRESS_TITLE} from '../constants';
import {$viewProjectStore, stageIndexSet, ProjectViewGate, tabIndexSet} from '../model';
import {$jobListCount} from '../model/jobStage';
import {$total} from '../model/addditionalAgreementsStage';
import {$total as $completedWorksTotal} from '../model/completedWorksStage';
import {$paginationCount as $technicalAcceptanceCount} from '../model/technicalAcceptanceStage';
import {$count} from '../model/logisticsStage';
import {EProjectStages} from '../model/helpers';

export const ProjectViewPage = function () {
    const {projectId} = useParams<{projectId: string}>();
    const navigate = useNavigate();
    useGate(ProjectViewGate, projectId);
    const {
        projectData,
        isLoading,
        projectIdFromNri,
        currentTabIndex,
        currentStageIndex,
        permissionStore: {
            hasDevelop,
            hasDocumentsTab,
            hasStagesTab,
            hasJobsStage,
            hasAgreementsStage,
            hasTechAcceptanceStage,
            hasCompletedWorksStage,
        },
    } = useUnit($viewProjectStore);
    const [jobCount, agreementCount, completedWorksCount, techAcceptanceCount, logisticCount] =
        useUnit([$jobListCount, $total, $completedWorksTotal, $technicalAcceptanceCount, $count]);

    if (projectId && projectIdFromNri && projectIdFromNri !== projectId) {
        navigate(`/projects/${projectIdFromNri}`);
    }

    const isNriId = Number.isInteger(+(projectId ?? ''));

    if (isNriId) {
        return (
            <S.WindowWrapper>
                <Progress
                    cycled
                    shape="circle"
                    size="standart"
                    strokeWidth={4}
                    type="solo"
                    value={75}
                />
            </S.WindowWrapper>
        );
    }
    const getSubtitle = (hasTasks: boolean) => (hasTasks ? 'Задачи в процессе' : 'Задач нет');
    const stagesList: ITabList[] = hasStagesTab
        ? [
              {
                  title: JOB_STAGE_TITLE,
                  subtitle: getSubtitle(jobCount > 0),
              },
              {
                  title: ACCESS_TO_OBJECT_STAGE_TITLE,
                  subtitle: STAGE_UNDER_DEVELOPMENT_TITLE,
                  disabled: true,
              },
              {
                  title: PIR_STAGE_TITLE,
                  subtitle: STAGE_UNDER_DEVELOPMENT_TITLE,
                  disabled: true,
              },
              {
                  title: ADDITIONAL_AGREEMENT_STAGE_TITLE,
                  subtitle: getSubtitle(agreementCount > 0),
              },
              {
                  title: LOGISTIC_STAGE_TITLE,
                  subtitle: hasDevelop ? getSubtitle(logisticCount > 0) : FORBIDDEN_STAGE_TITLE,
                  disabled: !hasDevelop,
              },
              {
                  title: SMR_STAGE_TITLE,
                  subtitle: STAGE_UNDER_DEVELOPMENT_TITLE,
                  disabled: true,
              },
              {
                  title: TECHNICAL_ACCEPTANCE_STAGE_TITLE,
                  subtitle: !hasTechAcceptanceStage
                      ? STAGE_UNDER_DEVELOPMENT_TITLE
                      : getSubtitle(techAcceptanceCount > 0),
                  disabled: !hasTechAcceptanceStage,
              },
              {
                  title: COMPLETED_WORKS_TAB_TITLE,
                  subtitle: hasCompletedWorksStage
                      ? getSubtitle(completedWorksCount > 0)
                      : FORBIDDEN_STAGE_TITLE,
                  disabled: !hasCompletedWorksStage,
              },
          ]
        : [];
    return (
        <CommonLayout>
            <WrapperOffset offset="32">
                {isLoading ? (
                    <ViewProjectTitleSkeleton />
                ) : (
                    projectData && <ViewPageTitle data={projectData} />
                )}

                <ViewProjectMainInfo data={projectData} isLoading={isLoading} />

                <WrapperFlex flexDirection="column" mt={sizeSpacingX9} gap={sizeSpacingX2}>
                    <Typography variant="h5">{WORK_PROGRESS_TITLE}</Typography>
                    <Tabs selectedTabIndex={currentTabIndex} onChange={tabIndexSet}>
                        <Tab key="stages" label={STAGES_TITLE} disabled={!hasStagesTab}>
                            <S.StagesWrapper>
                                <VerticalTab
                                    tabList={stagesList}
                                    onSelect={stageIndexSet}
                                    selectedTabIndex={currentStageIndex}
                                ></VerticalTab>
                                <S.StageContentWrapper>
                                    {hasJobsStage &&
                                        projectId &&
                                        currentStageIndex === EProjectStages.JOBS && (
                                            <JobStage
                                                projectId={projectId}
                                                preliminary_organization={
                                                    projectData?.preliminary_organization
                                                }
                                            />
                                        )}

                                    {hasAgreementsStage &&
                                        projectId &&
                                        currentStageIndex ===
                                            EProjectStages.ADDITIONAL_AGREEMENTS && (
                                            <AdditionalAgreementsStage
                                                projectId={projectId}
                                                projectNumber={projectData?.project_ext_id}
                                            />
                                        )}
                                    {hasDevelop &&
                                        projectId &&
                                        currentStageIndex === EProjectStages.LOGISTICS && (
                                            <LogisticsStage projectId={projectId} />
                                        )}

                                    {hasTechAcceptanceStage &&
                                        projectId &&
                                        currentStageIndex === EProjectStages.TECH_ACCEPTANCE && (
                                            <TechnicalAcceptanceStage projectId={projectId} />
                                        )}
                                    {hasCompletedWorksStage &&
                                        projectId &&
                                        currentStageIndex === EProjectStages.COMPLETED_WORKS && (
                                            <CompletedWorksStage projectId={projectId} />
                                        )}
                                </S.StageContentWrapper>
                            </S.StagesWrapper>
                        </Tab>
                        <Tab key="documents" label={DOCUMENTS_TITLE} disabled={!hasDocumentsTab}>
                            <DocumentTab />
                        </Tab>
                    </Tabs>
                </WrapperFlex>
            </WrapperOffset>
        </CommonLayout>
    );
};
