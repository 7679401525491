import {createGate} from 'effector-react';
import {combine, createDomain, forward, sample} from 'effector';
import {Option} from '@beeline/design-system-react';

import {$isUserVK, $userActions} from 'shared/model/user';
import {
    fetchDocumentsList,
    fetchDocumentSubtypeList,
    fetchPrimeContractorList,
} from 'shared/services/documents.service';
import {
    EDocumentType,
    IDocumentsListQueryParams,
    TDocumentsListItem,
} from 'shared/types/documentTypes';
import {forwardPayload} from 'shared/helpers/effector';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EObjectAccessActions} from 'shared/const/actions';

interface DocumentsListParamsType {
    project_id: string;
    page: number;
    page_size: number;
    selectedDocumentType: Option<string>[];
    selectedPrimeContractor: Option<string>[];
}

export interface ChangingSelectValueType {
    value: Option<string>[];
    targetItem: Option<string> | null;
    checked: boolean;
}

export const DocumentTableGate = createGate<string>();
export const DocumentTableDomain = createDomain();

// events

export const setPage = DocumentTableDomain.createEvent<number>();
export const setPageSize = DocumentTableDomain.createEvent<number>();
export const resetDocumentTypeSelect = DocumentTableDomain.createEvent();
export const resetPrimeContractorSelect = DocumentTableDomain.createEvent();
export const changeSelectedPrimeContractor =
    DocumentTableDomain.createEvent<ChangingSelectValueType>();
export const changeSelectedDocumentType =
    DocumentTableDomain.createEvent<ChangingSelectValueType>();
export const changePrimeContractorSearchValue = DocumentTableDomain.createEvent<string>();
export const changeDocumentTypeSearchValue = DocumentTableDomain.createEvent<string>();
export const rowExpanded = DocumentTableDomain.createEvent<string>();
export const resetPrimeContractorSearchValue = DocumentTableDomain.createEvent();
export const resetDocumentTypeSearchValue = DocumentTableDomain.createEvent();
export const resetAllSelects = DocumentTableDomain.createEvent();

// effects
export const fetchDocumentsDataFx = DocumentTableDomain.createEffect(
    async (params: DocumentsListParamsType) => {
        try {
            const resultParams: IDocumentsListQueryParams = {
                project_id: params.project_id,
                page: params.page,
                page_size: params.page_size,
                subtypes_document: params.selectedDocumentType.map((item) => item.id).join(','),
                gpo: params.selectedPrimeContractor.map((item) => item.id).join(','),
                project_section: EDocumentType.DOCUMENTS,
            };
            const result = await fetchDocumentsList(resultParams);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

export const fetchPrimeContractorsDataFx = DocumentTableDomain.createEffect(
    async (projectId: string) => {
        try {
            const result = await fetchPrimeContractorList(projectId);
            return result.data.map((item) => ({id: item.id, value: item.short_name}));
        } catch (e) {
            console.warn(e);
        }
    },
);

export const fetchDocumentTypesFx = DocumentTableDomain.createEffect(async (projectId: string) => {
    try {
        const result = await fetchDocumentSubtypeList(projectId, EDocumentType.DOCUMENTS);
        return result.data.map((item) => ({id: item.id, value: item.name}));
    } catch (e) {
        console.warn(e);
    }
});

// stores
const $documentsStore = DocumentTableDomain.createStore<TDocumentsListItem[]>([]).on(
    fetchDocumentsDataFx.doneData,
    (state, payload) => payload?.results,
);

const $paginationCount = DocumentTableDomain.createStore<number>(0).on(
    fetchDocumentsDataFx.doneData,
    (_, payload) => payload?.count ?? 0,
);

const $documentTypesStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchDocumentTypesFx.doneData,
    forwardPayload(),
);

const $availableDocumentTypesStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchDocumentTypesFx.doneData,
    forwardPayload(),
);

const $primeContractorsStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchPrimeContractorsDataFx.doneData,
    forwardPayload(),
);

const $availablePrimeContractorsStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchPrimeContractorsDataFx.doneData,
    forwardPayload(),
);

const $primeContractorSearchValue = DocumentTableDomain.createStore<string>('')
    .on(changePrimeContractorSearchValue, forwardPayload())
    .reset(resetPrimeContractorSearchValue);

const $documentTypeSearchValue = DocumentTableDomain.createStore<string>('')
    .on(changeDocumentTypeSearchValue, forwardPayload())
    .reset(resetDocumentTypeSearchValue);

const $documentsListParams = DocumentTableDomain.createStore<DocumentsListParamsType>({
    project_id: '',
    page: 1,
    page_size: 10,
    selectedDocumentType: [],
    selectedPrimeContractor: [],
})
    .on(setPage, (state, payload) => ({...state, page: payload}))
    .on(setPageSize, (state, payload) => ({
        ...state,
        page: 1,
        page_size: payload,
    }))
    .on(resetDocumentTypeSelect, (state, _) => ({
        ...state,
        selectedDocumentType: [],
    }))
    .on(resetPrimeContractorSelect, (state, _) => ({...state, selectedPrimeContractor: []}));

const $expandedRowList = DocumentTableDomain.store<string[]>([]);
const $creationAllowed = DocumentTableDomain.store<boolean>(false);
const $tableAllowed = DocumentTableDomain.store<boolean>(false);

export const documentTableStore = combine({
    documentsStore: $documentsStore,
    documentTypesStore: $availableDocumentTypesStore,
    primeContractorsStore: $availablePrimeContractorsStore,
    documentListParams: $documentsListParams,
    paginationCount: $paginationCount,
    tableIsLoading: fetchDocumentsDataFx.pending,
    primeContractorSearchValue: $primeContractorSearchValue,
    documentTypeSearchValue: $documentTypeSearchValue,
    expandedRowList: $expandedRowList,
    creationAllowed: $creationAllowed,
    tableAllowed: $tableAllowed,
});

// проверка экшенов
sample({
    clock: DocumentTableGate.open,
    source: $userActions,
    fn: (actions) =>
        userHasPermission(
            {
                permission: EObjectAccessActions.DocumentsList,
                type: EDocumentType.DOCUMENTS,
            },
            actions,
        ),
    target: $tableAllowed,
});

// проверка экшенов
sample({
    clock: DocumentTableGate.open,
    source: $userActions,
    fn: (actions) =>
        userHasPermission(
            {
                permission: EObjectAccessActions.DocumentsCreation,
                type: EDocumentType.DOCUMENTS,
            },
            actions,
        ),
    target: $creationAllowed,
});

// запись project_id при открытии страницы
sample({
    clock: DocumentTableGate.open,
    source: combine({
        params: $documentsListParams,
        tableAllowed: $tableAllowed,
    }),
    filter: ({tableAllowed}) => tableAllowed,
    fn: ({params}, clock) => ({...params, project_id: clock}),
    target: $documentsListParams,
});

// получение данных для автокомплита "тип документа"
sample({
    clock: DocumentTableGate.open,
    source: combine({
        selectStore: $documentTypesStore,
        tableAllowed: $tableAllowed,
    }),
    filter: ({selectStore, tableAllowed}) => !selectStore.length && tableAllowed,
    fn: (_, clock) => clock,
    target: fetchDocumentTypesFx,
});

// получение данных для автокомплита "ГПО"
sample({
    clock: DocumentTableGate.open,
    source: combine({
        primeContractorsStore: $primeContractorsStore,
        tableAllowed: $tableAllowed,
        isUserVk: $isUserVK,
    }),
    filter: ({primeContractorsStore, tableAllowed, isUserVk}, clock) =>
        typeof clock === 'string' && !primeContractorsStore.length && tableAllowed && isUserVk,
    fn: (_, clock) => clock,
    target: fetchPrimeContractorsDataFx,
});

// refetch данных при изменении параметров
sample({
    source: $documentsListParams,
    filter: (source) => source.project_id !== '',
    target: fetchDocumentsDataFx,
});

// фильтрация элементов селекта "ГПО" при использовании поиска
sample({
    clock: $primeContractorSearchValue,
    source: $primeContractorsStore,
    fn: (store, clock) => {
        if (clock !== '') {
            return store.filter((item) => item.value.toLowerCase().includes(clock.toLowerCase()));
        }
        return store;
    },
    target: $availablePrimeContractorsStore,
});

// фильтрация элементов селекта "Тип документа" при использовании поиска
sample({
    clock: $documentTypeSearchValue,
    source: $documentTypesStore,
    fn: (store, clock) => {
        if (clock !== '') {
            return store.filter((item) => item.value.toLowerCase().includes(clock.toLowerCase()));
        }
        return store;
    },
    target: $availableDocumentTypesStore,
});

// работа селекта ГПО
sample({
    clock: changeSelectedPrimeContractor,
    source: combine({
        params: $documentsListParams,
        store: $primeContractorsStore,
    }),
    fn: (source, clock) => {
        const {params, store} = source;
        const {targetItem, checked} = clock;
        let result: Option<string>[] = [];
        if (targetItem === null) {
            if (checked) {
                result = [...store];
            }
        } else {
            if (checked) {
                result = [...params.selectedPrimeContractor, targetItem];
            } else {
                result = [...params.selectedPrimeContractor].filter(
                    (item) => item.value !== targetItem.value,
                );
            }
        }
        result.sort((a, b) => a.value.localeCompare(b.value));
        return {...params, page: 1, selectedPrimeContractor: result};
    },
    target: $documentsListParams,
});

// работа селекта "Тип документа"
sample({
    clock: changeSelectedDocumentType,
    source: combine({
        params: $documentsListParams,
        store: $documentTypesStore,
    }),
    fn: (source, clock) => {
        const {params, store} = source;
        const {targetItem, checked} = clock;
        let result: Option<string>[] = [];
        if (targetItem === null) {
            if (checked) {
                result = [...store];
            }
        } else {
            if (checked) {
                result = [...params.selectedDocumentType, targetItem];
            } else {
                result = [...params.selectedDocumentType].filter(
                    (item) => item.value !== targetItem.value,
                );
            }
        }
        result.sort((a, b) => a.value.localeCompare(b.value));
        return {...params, page: 1, selectedDocumentType: result};
    },
    target: $documentsListParams,
});

sample({
    clock: rowExpanded,
    source: $expandedRowList,
    fn: (list, expandedRowId) => {
        if (list.includes(expandedRowId)) {
            return list.filter((el) => el !== expandedRowId);
        }
        return [...list, expandedRowId];
    },
    target: $expandedRowList,
});

forward({
    from: resetAllSelects,
    to: [
        resetDocumentTypeSearchValue,
        resetPrimeContractorSearchValue,
        resetDocumentTypeSelect,
        resetPrimeContractorSelect,
    ],
});
