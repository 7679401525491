import {bindAllMethods} from 'shared/helpers/binder';
import {axiosInstance} from 'shared/api/api';
import {IWhiteList} from 'shared/types/whiteListTypes';
import {
    CatalogContractResponse,
    CatalogTPIResponse,
    CatalogWorkObject,
    GetCatalogTPIRequest,
    GetWorkObjectsRequest,
    WorkTypeResponse,
} from 'pages/VirStart/types';

export const catalogService = bindAllMethods({
    async getCatalogProjects(): Promise<{data: {results: {id: string; project_ext_id: string}[]}}> {
        return axiosInstance.get('/projects/projects/');
    },

    async getCatalogWorkObjects(params: GetWorkObjectsRequest): Promise<{data: CatalogWorkObject}> {
        return axiosInstance.get(`/projects/projects/${params.project_id}/work_objects/`, {params});
    },

    async getCatalogWorkTypes(): Promise<{data: WorkTypeResponse[]}> {
        return axiosInstance.get('/catalogs/work_types/');
    },

    async getCatalogContracts(params: {
        contractor_id?: string;
        project_id?: string;
    }): Promise<{data: CatalogContractResponse[]}> {
        return axiosInstance.get('/contracts/contracts_list/', {params});
    },

    async getCatalogTpis(params: GetCatalogTPIRequest): Promise<{data: CatalogTPIResponse[]}> {
        return axiosInstance.get('/contracts/tpis/', {params});
    },
});

export const fetchPurchasingEventList = () => {
    return axiosInstance.get<string[]>('/contracts/contracts/purchasing_events/');
};

export const fetchWhiteList = () => {
    return axiosInstance.get<IWhiteList>('catalogs/constants/whitelist_imProjectGroup/');
};

export const fetchPilotBranches = () => {
    return axiosInstance.get<{technical: string[]}>(`catalogs/constants/pilot/`);
};
