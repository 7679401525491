import React from 'react';
import {useGate, useUnit} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserVK, $userActions} from 'shared/model/user';
import {EAccessActions} from 'shared/const/actions';
import {JOB_STAGE_TITLE} from 'shared/constants';
import {userHasPermission} from 'shared/helpers/accessCheckers';

import {jobListColumns} from './columns';
import {JobTable} from './JobTable';
import {JobStageGate} from '../../../../model/jobStage';

interface JobTabProps {
    projectId: string;
    preliminary_organization?: string[];
}

export const JobStage = ({projectId, preliminary_organization}: JobTabProps) => {
    useGate(JobStageGate, projectId);
    const [isUserVK, actions] = useUnit([$isUserVK, $userActions]);

    const handleCreateJob = () => {
        navigationInvoked({
            to: `/projects/${projectId}/job-creation`,
            options: {
                state: {
                    preliminary_organization:
                        (preliminary_organization && preliminary_organization[0]) ?? '',
                },
            },
        });
    };

    const createButtonVisible = userHasPermission(EAccessActions.JobTaskCreation, actions);
    const filteredColumns = jobListColumns.filter((column) =>
        isUserVK ? true : column.name !== 'contractor',
    );

    return (
        <WrapperFlex flexDirection="column" alignItems="flex-start" gap="16px">
            <Typography variant="h5">{JOB_STAGE_TITLE}</Typography>
            <JobTable
                createButtonVisible={createButtonVisible}
                columns={filteredColumns}
                handleCreateJob={handleCreateJob}
            />
        </WrapperFlex>
    );
};
