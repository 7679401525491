import React from 'react';
import {useStore} from 'effector-react';
import {Button, Skeleton, TableBody, TableRow} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {TableHeader} from 'Features/TableHeader';
import {EmptyTab} from 'Entities/EmptyTab';
import {TableBase} from 'Entities/TableBase';
import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import {$userActions} from 'shared/model/user';
import {TJobListItem} from 'shared/types/jobsTypes';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {IPaginationData} from 'shared/types/commonTypes';
import {CustomTableData} from 'shared/styles/commonStyle';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {CREATE_JOB_TITLE} from 'shared/constants';
import {EAccessActions} from 'shared/const/actions';

import {$jobTableStore, jobPageChanged, jobPageSizeChanged} from '../../../../model/jobStage';
import {
    COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE,
    COMPLETED_WORKS_STAGE_EMPTY_TITLE,
} from '../../../../constants';

interface JobTableProps {
    createButtonVisible: boolean;
    columns: IColumnWithActions<TJobListItem>[];
    handleCreateJob: () => void;
}

export const JobTable = ({columns, createButtonVisible, handleCreateJob}: JobTableProps) => {
    const actions = useStore($userActions);
    const {params, total, tableData, isLoading} = useStore($jobTableStore);
    const tableIsEmpty = !isLoading && total === 0;
    const hasLink = userHasPermission(EAccessActions.JobTaskCard, actions);
    const paginationData: IPaginationData = {
        ...params,
        total,
        setPage: jobPageChanged,
        setPageSize: jobPageSizeChanged,
        isLoading,
    };

    const handleNavigate = (id: string) =>
        hasLink
            ? navigationInvoked({
                  to: `/jobs/${id}`,
              })
            : undefined;

    return tableIsEmpty ? (
        <EmptyTab
            iconName={Icons.PageEmpty}
            showButton={createButtonVisible}
            color="blue"
            title={COMPLETED_WORKS_STAGE_EMPTY_TITLE}
            subtitle={COMPLETED_WORKS_STAGE_EMPTY_SUBTITLE}
            buttonTitle={CREATE_JOB_TITLE}
            onClick={handleCreateJob}
        />
    ) : (
        <>
            {createButtonVisible && isLoading ? (
                <Skeleton width={169} height={48} variant="square" />
            ) : (
                createButtonVisible && (
                    <Button size="medium" onClick={handleCreateJob}>
                        {CREATE_JOB_TITLE}
                    </Button>
                )
            )}
            <TableBase minTableWidth="1180px" paginationData={paginationData}>
                <TableHeader<TJobListItem> columns={columns} />
                {isLoading ? (
                    <TableBodySkeleton<TJobListItem>
                        pageSize={params.pageSize}
                        columns={columns}
                        height="52px"
                        dense
                    />
                ) : (
                    <TableBody>
                        {tableData.map((item) => (
                            <TableRow
                                key={item.id}
                                onClick={() => handleNavigate(item.id)}
                                hover={hasLink}
                            >
                                {columns.map((column) => (
                                    <CustomTableData
                                        key={`${item.id}_${column.name}`}
                                        width={column.width}
                                        $minWidth={column.minWidth}
                                        height="52px"
                                        dense
                                    >
                                        {column.render && column.render(item, actions)}
                                    </CustomTableData>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </TableBase>
        </>
    );
};
