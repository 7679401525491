import React from 'react';
import {useUnit} from 'effector-react';
import {TableBody, TableRow, Typography} from '@beeline/design-system-react';
import {sizeSpacingX3} from '@beeline/design-tokens/js/tokens';

import {TableHeader} from 'Features/TableHeader';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Accordion} from 'shared/ui/deprecated/Accordion/Accordion';
import {CustomTableData, InnerTable} from 'shared/styles/commonStyle';
import {IConsumerOrderItem} from 'shared/types/documentTypes';

import * as S from '../../RequestViewPage/ui/style';
import {columns} from '../../RequestViewPage/ui/columns';
import {WhiteTypography} from '../../RequestViewPage/ui/style';
import {$rawDocumentStore} from '../model';

export const SpecificationTable = () => {
    const {data, expandedItemId} = useUnit($rawDocumentStore);
    if (!data?.specification) return null;
    const {consume_orders} = data.specification;

    return (
        <Accordion>
            {consume_orders.map((item) => (
                <S.StyledAccordionItem
                    key={item.consume_order.id}
                    id={item.consume_order.id}
                    title={`Спецификация ${item.consume_order.number}`}
                    active={true}
                    noOverflow
                >
                    <InnerTable>
                        <TableHeader<IConsumerOrderItem> columns={columns} />
                        <TableBody>
                            {item.consume_order.items.map((rowItem) => (
                                <React.Fragment key={rowItem.nfs_id}>
                                    <TableRow dense>
                                        {columns.map((column) => (
                                            <CustomTableData
                                                key={`inner_${column.name}`}
                                                width={column.width}
                                                height="52px"
                                            >
                                                {column.render && column.render(rowItem)}
                                            </CustomTableData>
                                        ))}
                                    </TableRow>
                                    {expandedItemId === rowItem.consume_order_item_id && (
                                        <TableRow dense>
                                            <CustomTableData height="52px" colSpan={10}>
                                                <WrapperFlex
                                                    flexDirection="column"
                                                    margin={sizeSpacingX3}
                                                    gap={sizeSpacingX3}
                                                >
                                                    <Typography variant="h5">
                                                        Комментарий к пункту
                                                    </Typography>
                                                    <WhiteTypography variant="body2">
                                                        {rowItem.comment}
                                                    </WhiteTypography>
                                                </WrapperFlex>
                                            </CustomTableData>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </InnerTable>
                </S.StyledAccordionItem>
            ))}
        </Accordion>
    );
};
