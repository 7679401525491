import styled from 'styled-components/macro';

export const BtnBlock = styled.div`
    border-bottom: 1px solid #e0e0e0;
`;

export const DSTabText = styled.div`
    font-size: 17px;
    margin-bottom: 16px;
`;

export const WindowWrapper = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
`;

export const StageContentWrapper = styled.div`
    flex: 1 1 600px;
    max-width: 85%;
`;

export const StagesWrapper = styled.div`
    width: 100%;
    margin-top: var(--size-spacing-x4);
    display: flex;
    gap: var(--size-spacing-x4);
`;
