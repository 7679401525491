import styled, {css} from 'styled-components/macro';

export const StyledContainer = styled.div<{$isEditable?: boolean; $isActive?: boolean}>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
        width: 100%;
        height: 100%;

        .dsb_input-wrapper {
            border: none;
        }

        > div {
            width: 100%;
            height: 100%;
        }
    }

    ${(props) =>
        props.$isActive &&
        css`
            background: var(--color-background-base-hover);
            box-shadow: inset 0 0 0 1px black;
        `}
    ${(props) =>
        props.$isEditable &&
        css`
            cursor: pointer;

            :hover {
                background: var(--color-background-base-hover);
                box-shadow: inset 0 0 0 1px black;
            }
        `}
`;

export const StyledInput = styled.input`
    width: 100%;
    height: 100%;
    text-align: right;
    border: none;
    background-color: transparent;
    outline: none;
`;
