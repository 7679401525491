import React from 'react';

import styles from './style.module.scss';

interface ConstructorHeaderProps {
    children: React.ReactNode;
}

export const ConstructorHeader = ({children}: ConstructorHeaderProps) => {
    return (
        <header grid-area="pageHeader" className={styles.constructorHeaderWrapper}>
            {children}
        </header>
    );
};
