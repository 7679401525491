import React from 'react';
import {Link} from 'react-router-dom';
import {IconButton} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {IExpandedColumn} from 'shared/types/tableTypes';
import {IContractListItem} from 'shared/types/contractsTypes';
import {formatDate} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

export const IContractColumns: IExpandedColumn<IContractListItem>[] = [
    {
        name: 'number',
        label: 'Номер',
        hasSorting: true,
        width: '182px',
        render: ({number, id}, userActions) => {
            return (
                <TooltipedSpan title={number} lineCount={2}>
                    {!!userActions &&
                    userHasPermission(EAccessActions.ContractCard, userActions) ? (
                        <Link to={id}>{number}</Link>
                    ) : (
                        number
                    )}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'contractor',
        label: 'ГПО',
        hasSorting: true,
        width: '30%',
        render: ({gpo_short_name}) => (
            <TooltipedSpan title={gpo_short_name} lineCount={2}>
                {gpo_short_name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'KZP',
        label: 'КЗП',
        hasSorting: true,
        width: '112px',
        render: ({nfs_contract_number}) => (
            <TooltipedSpan title={nfs_contract_number} lineCount={2}>
                {nfs_contract_number}
            </TooltipedSpan>
        ),
    },
    {
        name: 'purchasing_event_number',
        label: '№ ЗМ',
        hasSorting: false,
        width: '95px',
        render: ({purchasing_events}) => {
            const purchasingEventString = purchasing_events.join(', ');
            return (
                <TooltipedSpan title={purchasingEventString} lineCount={2}>
                    {purchasingEventString}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'conclusion_date',
        label: 'Заключен',
        hasSorting: true,
        width: '112px',
        render: ({date_conclusion}) => {
            const conclusionDateString = formatDate(date_conclusion);
            return (
                <TooltipedSpan title={conclusionDateString} lineCount={1}>
                    {conclusionDateString}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'validity_date',
        label: 'Дата окончания',
        hasSorting: true,
        width: '143px',
        render: ({date_action}) => {
            const validityDateString = formatDate(date_action);
            return (
                <TooltipedSpan title={validityDateString} lineCount={1}>
                    {validityDateString}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'region',
        label: 'Регион',
        hasSorting: false,
        width: '148px',
        render: ({regions}) => {
            const regionString = regions.join(', ');
            return (
                <TooltipedSpan title={regionString} lineCount={2}>
                    {regionString}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'external_link',
        label: 'Ссылка',
        hasSorting: false,
        width: '82px',
        render: ({dcm_hyperlinks}) => (
            <TooltipedSpan title={'Открыть в новой вкладке'} lineCount={1}>
                <Link to={dcm_hyperlinks} target="_blank">
                    <IconButton iconName={Icons.OpenInWindow} onClick={() => 1} />
                </Link>
            </TooltipedSpan>
        ),
    },
];
