import {IStatus} from 'shared/types/statusTypes';
import {IEmployeeInfo} from 'shared/types/userTypes';
import {PaginationType} from 'shared/types/apiParamsTypes';
import {TBaseStation} from 'shared/types/baseStationsTypes';
import {TAttachmentFile} from 'shared/types/FileTypes';
import {IComment} from 'shared/types/commonTypes';

export interface IDocument {
    approved_at: string | null;
    bs: TBaseStation;
    created_at: Date;
    creator: IEmployeeInfo;
    document_comment: IComment[];
    document_file: TAttachmentFile[];
    editor: IEmployeeInfo;
    ext_number: string | null;
    external_code: string | null;
    id: string;
    is_approved: boolean | null;
    is_released: boolean;
    is_urgent: boolean;
    last_version: boolean;
    number: number;
    organization_gpo: string | null;
    owner_id: string;
    planned_date: string | null;
    project_type_name: string;
    specification: IDocumentSpecification | null;
    status: IStatus;
    subtype_document: TDocumentSubtype;
    subtypes_document: string;
    updated_at: Date;
    version: number;
}

export type TDocumentCreation = {
    project_id: string;
    subtypes_document_id: string;
    document_comment: string;
    document_attachments: {
        id: null;
        file_id: string;
        history_id: string;
        registration_at: string | null;
    }[];
};

export type TDocumentEditing = {
    project_id: string;
    subtypes_document_id: string;
    document_comment: string;
    document_attachments?: {
        id: string | null;
        file_id: string | null;
        registration_at: string | null;
    }[];
    specification?: IDocumentSpecification | null;
    is_urgent?: boolean;
    planned_date?: string;
};

export enum EDocumentType {
    DOCUMENTS = 'document',
    LOGISTIC = 'logistics',
    TECHNICAL = 'technical',
    ALL = 'all',
}

export interface IDocumentsListQueryParams extends PaginationType {
    project_id: string;
    gpo?: string;
    subtypes_document?: string;
    project_section?: EDocumentType | EDocumentType[];
}

// ResponseType
export type TDocumentsListItem = {
    approved_at: string | null;
    created_at: string;
    creator: IEmployeeInfo;
    document_file: TAttachmentFile[];
    editor: IEmployeeInfo;
    id: string;
    is_urgent: boolean;
    last_version: boolean;
    organization_gpo?: string | null;
    planned_date: string | null;
    prefix_version: number;
    previous_version?: Array<Omit<TDocumentsListItem, 'previous_version'>>;
    status: IStatus;
    subtypes_document: string;
    version: number;
};

export type TDocumentSubtype = {
    external_code: string;
    id: string;
    is_registration: boolean;
    is_selectable: boolean;
    is_versioning_only: boolean;
    name: string;
    permission_type: number;
    quantity_files: number;
    quantity_files_min: number;
};

export type TPrimeContractorListItem = {
    id: string;
    name?: string;
    short_name: string;
};

export type TDocumentTypeListItem = {
    id: string;
    name: string;
};

export interface IConsumerOrderItem {
    agreed_quantity: number;
    comment: string;
    consume_order_item_id: number;
    gfk: string;
    is_canceled: boolean;
    is_used: boolean;
    name: string;
    nfs_id: string;
    planned_quantity: number;
    relocation_order_number: string;
    relocation_order_quantity: number;
    required_quantity: number;
    unit: IUnit;
    vendor_id: string;
}

export interface IUnit {
    id: string;
    name: string;
}

export interface IConsumerOrder {
    business_line_code: string;
    comment: string;
    create_date: string;
    id: string;
    items: IConsumerOrderItem[];
    number: string;
}

export interface IDocumentSpecification {
    consume_orders: {
        consume_order: IConsumerOrder;
    }[];
}
