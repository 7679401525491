import {Navigate, RouteProps} from 'react-router-dom';
import React from 'react';

import ProjectPageNew from 'pages/ProjectPage/ProjectPage';
import {ProjectViewPage} from 'pages/ProjectViewPage';
import {DSPage} from 'pages/DSPage/ui/DSPage';
import {CreateVIRStart} from 'pages/VirStart/ui';
import VirSelectPage from 'pages/VirSelect/ui';
import {ApplyCoefficientsPage} from 'pages/ApplyCoefficientsPage/ui';
import {AddWorkObjects} from 'pages/AddWorkObjects/ui';
import {AdditionalAgreementsPage} from 'pages/AdditionalAgreementsPage';
import {VirConstructor} from 'pages/VirConstructor/VirConstructor';
import {NewLoginPage} from 'pages/NewLoginPage/ui/NewLoginPage';
import {JobCreationPage} from 'pages/JobCreationPage';
import {JobViewPage} from 'pages/JobViewPage';
import {ForbiddenPage} from 'pages/ForbiddenPage';
import {DocumentViewPage} from 'pages/DocumentViewPage';
import {DocumentConstructorPage} from 'pages/DocumentConstructorPage';
import {ProcessesPage} from 'pages/ProcessesPage';
import {Stage2CopyAgreement} from 'pages/Stage2CopyAgreement';
import {CompletedWorksViewPage} from 'pages/CompletedWorksViewPage';
import {GroupSignPage} from 'pages/GroupSignPage';
import {CompletedWorksEditingProcess, CompletedWorksProcess} from 'processes/CompletedWorksProcess';
import {ContractListPage} from 'pages/ContractListPage';
import {ContractViewPage} from 'pages/ContractViewPage';
import {WhiteListPage} from 'pages/WhiteListPage';
import {RequestViewPage} from 'pages/RequestViewPage';
import {RequestCreationPage} from 'pages/RequestCreationPage';
import {EDocumentType} from 'shared/types/documentTypes';
import {EAccessActions, EObjectAccessActions, TRouteAccessActions} from 'shared/const/actions';

export type AppRoutesProps = RouteProps & {
    authOnly?: boolean;
    commonLayout?: boolean;
    accessAction?: TRouteAccessActions;
};

export enum AppRoutes {
    MAIN = 'main',
    PROJECTS = 'projects',
    PROJECT_PROFILE = 'project_profile',
    JOB_CREATION = 'job_creation',
    JOB_PROFILE = 'job_profile',
    ADDITIONAL_AGREEMENT = 'additional_agreement',
    ADDITIONAL_AGREEMENT_CREATE = 'additional_agreement_create',
    ADDITIONAL_AGREEMENTS = 'additional_agreements',
    WORK_EXECUTION_SHEET_CREATE = 'work_execution_sheet_create',
    WORK_EXECUTION_SHEET_SELECT = 'work_execution_sheet_select',
    WORK_EXECUTION_SHEET_CONSTRUCTOR = 'work_execution_sheet_constructor',
    WORK_EXECUTION_SHEET_EDIT_CONSTRUCTOR = 'work_execution_sheet_edit_constructor',
    COPY_WORK_EXECUTION_SHEET_CONSTRUCTOR = 'copy_work_execution_sheet_constructor',
    WORK_COEFFICIENTS_APPLYING = 'work_coefficients_applying',
    WORK_OBJECTS = 'work_objects',
    LOGIN = 'login',
    FORBIDDEN = 'forbidden',
    DOCUMENT = 'document',
    DOCUMENT_CREATION = 'document_creation',
    TECHNICAL_DOCUMENT_CREATION = 'technical_document_creation',
    PROCESSES = 'processes',
    COMPLETED_WORKS_SHEET_CREATE = 'completed_works_sheet_create',
    COMPLETED_WORKS_SHEET_EDIT = 'completed_works_sheet_edit',
    COMPLETED_WORKS_SHEET_CARD = 'completed_works_sheet_card',
    GROUP_SIGN = 'group_sign',
    CONTRACT_LIST = 'contract_list',
    CONTRACT = 'contract',
    WHITE_LIST = 'white_list',
    EQUIPMENT_REQUEST = 'equipment_request',
    EQUIPMENT_REQUEST_CONSTRUCTOR = 'equipment_request_constructor',
    // in advance
    // NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.PROJECTS]: '/projects',
    [AppRoutes.PROJECT_PROFILE]: '/projects/:projectId',
    [AppRoutes.JOB_CREATION]: '/projects/:projectId/job-creation',
    [AppRoutes.JOB_PROFILE]: '/jobs/:jobId',
    [AppRoutes.ADDITIONAL_AGREEMENT]: '/additional-agreements/:agreementId',
    [AppRoutes.ADDITIONAL_AGREEMENT_CREATE]: '/additional-agreement/create',
    [AppRoutes.ADDITIONAL_AGREEMENTS]: '/additional-agreements',
    [AppRoutes.WORK_EXECUTION_SHEET_CREATE]: '/projects/:projectId/additional-agreement/create',
    [AppRoutes.WORK_EXECUTION_SHEET_SELECT]: '/projects/:projectId/additional-agreement/select',
    [AppRoutes.WORK_EXECUTION_SHEET_CONSTRUCTOR]: '/additional-agreement/constructor',
    [AppRoutes.WORK_EXECUTION_SHEET_EDIT_CONSTRUCTOR]:
        '/additional-agreement/:agreementId/constructor',
    [AppRoutes.COPY_WORK_EXECUTION_SHEET_CONSTRUCTOR]:
        '/projects/:projectId/additional-agreement/copy-agreement',
    [AppRoutes.WORK_COEFFICIENTS_APPLYING]: '/constructor/work_coefficients',
    [AppRoutes.WORK_OBJECTS]: '/constructor/add_work_objects',
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.FORBIDDEN]: '/forbidden',
    [AppRoutes.DOCUMENT]: '/documents/:documentId',
    [AppRoutes.DOCUMENT_CREATION]: '/projects/:projectId/document-creation',
    [AppRoutes.TECHNICAL_DOCUMENT_CREATION]: '/projects/:projectId/tech-acceptance-creation',
    [AppRoutes.PROCESSES]: '/processes',
    [AppRoutes.COMPLETED_WORKS_SHEET_CREATE]: '/projects/:projectId/completed-works/creation',
    [AppRoutes.COMPLETED_WORKS_SHEET_EDIT]: '/completed-works/:id/editing',
    [AppRoutes.COMPLETED_WORKS_SHEET_CARD]: '/completed-works/:id',
    [AppRoutes.GROUP_SIGN]: '/processes/signing',
    [AppRoutes.CONTRACT_LIST]: '/contracts',
    [AppRoutes.CONTRACT]: '/contracts/:id',
    [AppRoutes.WHITE_LIST]: '/info',
    [AppRoutes.EQUIPMENT_REQUEST]: '/equipment-requests/:id/*',
    [AppRoutes.EQUIPMENT_REQUEST_CONSTRUCTOR]: '/equipment-requests/:id/editing',
    // in advance
    // [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <Navigate to="/projects" replace />,
        authOnly: true,
        commonLayout: true,
    },
    [AppRoutes.PROJECTS]: {
        path: RoutePath.projects,
        element: <ProjectPageNew />,
        authOnly: true,
        commonLayout: true,
    },
    [AppRoutes.PROJECT_PROFILE]: {
        path: RoutePath.project_profile,
        element: <ProjectViewPage />,
        authOnly: true,
        commonLayout: true,
        accessAction: EAccessActions.ProjectCard,
    },
    [AppRoutes.JOB_CREATION]: {
        path: RoutePath.job_creation,
        element: <JobCreationPage />,
        authOnly: true,
        commonLayout: true,
        accessAction: EAccessActions.JobTaskCreation,
    },
    [AppRoutes.JOB_PROFILE]: {
        path: RoutePath.job_profile,
        element: <JobViewPage />,
        authOnly: true,
        commonLayout: true,
        accessAction: EAccessActions.JobTaskCard,
    },
    [AppRoutes.ADDITIONAL_AGREEMENT]: {
        path: RoutePath.additional_agreement,
        element: <DSPage />,
        authOnly: true,
        commonLayout: true,
        accessAction: EAccessActions.AgreementCard,
    },
    [AppRoutes.ADDITIONAL_AGREEMENT_CREATE]: {
        path: RoutePath.additional_agreement_create,
        element: <CreateVIRStart />,
        authOnly: true,
    },
    [AppRoutes.ADDITIONAL_AGREEMENTS]: {
        path: RoutePath.additional_agreements,
        element: <AdditionalAgreementsPage />,
        authOnly: true,
        commonLayout: true,
        accessAction: EAccessActions.AgreementList,
    },
    [AppRoutes.WORK_EXECUTION_SHEET_CREATE]: {
        path: RoutePath.work_execution_sheet_create,
        element: <CreateVIRStart />,
        authOnly: true,
    },
    [AppRoutes.WORK_EXECUTION_SHEET_SELECT]: {
        path: RoutePath.work_execution_sheet_select,
        element: <VirSelectPage />,
        authOnly: true,
    },
    [AppRoutes.WORK_EXECUTION_SHEET_CONSTRUCTOR]: {
        path: RoutePath.work_execution_sheet_constructor,
        element: <VirConstructor />,
        authOnly: true,
    },
    [AppRoutes.WORK_EXECUTION_SHEET_EDIT_CONSTRUCTOR]: {
        path: RoutePath.work_execution_sheet_edit_constructor,
        element: <VirConstructor />,
        authOnly: true,
    },
    [AppRoutes.WORK_COEFFICIENTS_APPLYING]: {
        path: RoutePath.work_coefficients_applying,
        element: <ApplyCoefficientsPage />,
        authOnly: true,
    },
    [AppRoutes.WORK_OBJECTS]: {
        path: RoutePath.work_objects,
        element: <AddWorkObjects />,
        authOnly: true,
    },
    [AppRoutes.LOGIN]: {
        path: RoutePath.login,
        element: <NewLoginPage />,
    },
    [AppRoutes.FORBIDDEN]: {
        path: RoutePath.forbidden,
        element: <ForbiddenPage />,
        authOnly: true,
    },
    [AppRoutes.DOCUMENT]: {
        path: RoutePath.document,
        element: <DocumentViewPage />,
        authOnly: true,
    },
    [AppRoutes.DOCUMENT_CREATION]: {
        path: RoutePath.document_creation,
        element: <DocumentConstructorPage mode="creation" documentType={EDocumentType.DOCUMENTS} />,
        authOnly: true,
        accessAction: {
            permission: EObjectAccessActions.DocumentsCreation,
            type: EDocumentType.DOCUMENTS,
        },
    },
    [AppRoutes.TECHNICAL_DOCUMENT_CREATION]: {
        path: RoutePath.technical_document_creation,
        element: <DocumentConstructorPage mode="creation" documentType={EDocumentType.TECHNICAL} />,
        authOnly: true,
        accessAction: {
            permission: EObjectAccessActions.DocumentsCreation,
            type: EDocumentType.TECHNICAL,
        },
    },
    [AppRoutes.PROCESSES]: {
        path: RoutePath.processes,
        element: <ProcessesPage />,
        authOnly: true,
        accessAction: EAccessActions.ProcessesList,
    },
    [AppRoutes.COPY_WORK_EXECUTION_SHEET_CONSTRUCTOR]: {
        path: RoutePath.copy_work_execution_sheet_constructor,
        element: <Stage2CopyAgreement />,
        authOnly: true,
        accessAction: EAccessActions.AgreementCreate,
    },
    [AppRoutes.COMPLETED_WORKS_SHEET_CREATE]: {
        path: RoutePath.completed_works_sheet_create,
        element: <CompletedWorksProcess />,
        authOnly: true,
        accessAction: EAccessActions.CompletedWorksCreation,
    },
    [AppRoutes.COMPLETED_WORKS_SHEET_EDIT]: {
        path: RoutePath.completed_works_sheet_edit,
        element: <CompletedWorksEditingProcess />,
        authOnly: true,
        accessAction: EAccessActions.CompletedWorksEditing,
    },
    [AppRoutes.COMPLETED_WORKS_SHEET_CARD]: {
        path: RoutePath.completed_works_sheet_card,
        element: <CompletedWorksViewPage />,
        authOnly: true,
        accessAction: EAccessActions.CompletedWorksCard,
    },
    [AppRoutes.GROUP_SIGN]: {
        path: RoutePath.group_sign,
        element: <GroupSignPage />,
        authOnly: true,
        accessAction: EAccessActions.DocumentSigning,
    },
    [AppRoutes.CONTRACT_LIST]: {
        path: RoutePath.contract_list,
        element: <ContractListPage />,
        authOnly: true,
        accessAction: EAccessActions.ContractList,
    },
    [AppRoutes.CONTRACT]: {
        path: RoutePath.contract,
        element: <ContractViewPage />,
        authOnly: true,
        accessAction: EAccessActions.ContractCard,
    },
    [AppRoutes.WHITE_LIST]: {
        path: RoutePath.white_list,
        element: <WhiteListPage />,
        authOnly: true,
        accessAction: EAccessActions.WhiteList,
    },
    [AppRoutes.EQUIPMENT_REQUEST]: {
        path: RoutePath.equipment_request,
        element: <RequestViewPage />,
        authOnly: true,
        accessAction: EAccessActions.Development,
    },
    [AppRoutes.EQUIPMENT_REQUEST_CONSTRUCTOR]: {
        path: RoutePath.equipment_request_constructor,
        element: <RequestCreationPage />,
        authOnly: true,
        accessAction: EAccessActions.Development,
    },
    /* in advance
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.not_found,
        element: <NotFoundPage />,
    },*/
};
