import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {
    fetchAvailableDocumentSubtypeList,
    postDocumentCreation,
    putDocumentEditing,
} from 'shared/services/documents.service';
import {TDocumentCreation, TDocumentEditing, TDocumentSubtype} from 'shared/types/documentTypes';
import {postJobAttachment} from 'shared/services/files.service';
import {TFileDataList} from 'shared/ui/use-file-uploader/useFileUploader.types';

import {
    checkObjectValidity,
    IDocumentConstructorGateProps,
    IDocumentOption,
    IOutputUploadedFile,
    IPreparedOutputEditingData,
    mapFileUploaderFilesForEditing,
    mapFileUploaderFilesToOutput,
} from './helpers';

export const DocumentConstructorDomain = createDomain();
export const DocumentConstructorGate = createGate<IDocumentConstructorGateProps>();

export const commentValueChanged = DocumentConstructorDomain.createEvent<string>();
export const selectedDocumentTypeChanged = DocumentConstructorDomain.createEvent<IDocumentOption>();
export const documentTypeAutocompleteListFilled =
    DocumentConstructorDomain.createEvent<TDocumentSubtype[]>();
export const selectedDocumentTypeReset = DocumentConstructorDomain.createEvent();
export const creationButtonClicked = DocumentConstructorDomain.createEvent<{
    files: TFileDataList;
    isError: boolean;
    isWorkflowButtonClicked?: boolean;
}>();
export const editingButtonClicked = DocumentConstructorDomain.createEvent<{
    initialState: TFileDataList;
    files: TFileDataList;
    isError: boolean;
    projId: string | undefined;
    documentId: string | undefined;
    isWorkflowButtonClicked: boolean | undefined;
}>();
export const documentCreated = DocumentConstructorDomain.createEvent<IOutputUploadedFile[]>();
export const documentEdited = DocumentConstructorDomain.createEvent<IPreparedOutputEditingData>();

const createDocumentFx = DocumentConstructorDomain.createEffect(async (data: TDocumentCreation) => {
    try {
        const result = await postDocumentCreation(data);
        if (result.status === 201) {
            return result.data.id;
        }
    } catch (e) {
        console.warn(e);
    }
});

const editDocumentFx = DocumentConstructorDomain.createEffect(
    async (data: {data: TDocumentEditing; documentId: string}) => {
        try {
            const result = await putDocumentEditing(data.data, data.documentId);
            if (result.status === 200) {
                return result.data.id;
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

const fetchDocumentTypeListFx = DocumentConstructorDomain.createEffect(
    async (params: IDocumentConstructorGateProps) => {
        try {
            const result = await fetchAvailableDocumentSubtypeList(
                params.projectId,
                params.documentType,
            );
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

const sendAttachmentFileFx = DocumentConstructorDomain.createEffect(async (fileList: FileList) => {
    try {
        const fileIdList: string[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const result = await postJobAttachment(fileList[i]);

            if (result.status === 201) {
                fileIdList.push(result.data.ids[0].id);
            }
        }
        return fileIdList;
    } catch (e) {
        console.log(e);
    }
});

// Stores
const $documentTypeList = DocumentConstructorDomain.createStore<TDocumentSubtype[]>([])
    .on(fetchDocumentTypeListFx.doneData, forwardPayload())
    .on(documentTypeAutocompleteListFilled, forwardPayload());
const $attachmentDocumentIdList = DocumentConstructorDomain.createStore<string[]>([]).on(
    sendAttachmentFileFx.doneData,
    forwardPayload(),
);

const $createdDocId = DocumentConstructorDomain.createStore<string | null>(null).on(
    createDocumentFx.doneData,
    forwardPayload(),
);

const $editedDocId = DocumentConstructorDomain.createStore<string | null>(null).on(
    editDocumentFx.doneData,
    forwardPayload(),
);

const $documentTypeAutocompleteList = DocumentConstructorDomain.createStore<IDocumentOption[]>([]);
const $selectedDocumentType = DocumentConstructorDomain.createStore<IDocumentOption | null>(null)
    .on(selectedDocumentTypeChanged, forwardPayload())
    .reset(selectedDocumentTypeReset);
const $maxFilesCount = DocumentConstructorDomain.createStore<number>(1);
const $minFilesCount = DocumentConstructorDomain.createStore<number>(1);
const $commentValue = DocumentConstructorDomain.createStore<string>('').on(
    commentValueChanged,
    forwardPayload(),
);
const $buttonClicked = DocumentConstructorDomain.createStore<boolean>(false).on(
    [createDocumentFx.pending, editDocumentFx.pending],
    forwardPayload(),
);
const $isFormTouched = DocumentConstructorDomain.createStore(false);

export const $documentCreationStore = combine({
    documentTypeList: $documentTypeAutocompleteList,
    selectedDocumentType: $selectedDocumentType,
    commentValue: $commentValue,
    createdDocumentId: $createdDocId,
    editedDocumentId: $editedDocId,
    maxFilesCount: $maxFilesCount,
    minFilesCount: $minFilesCount,
    buttonClicked: $buttonClicked,
    formTouched: $isFormTouched,
});

// загрузка списка типов документов при открытии страницы
sample({
    clock: DocumentConstructorGate.state,
    filter: (state) => typeof state.projectId === 'string' && state.mode === 'creation',
    target: fetchDocumentTypeListFx,
});

// активация проверки ошибок формы
sample({
    clock: [creationButtonClicked, editingButtonClicked],
    fn: () => true,
    target: $isFormTouched,
});

sample({
    clock: creationButtonClicked,
    source: combine({
        buttonClicked: $buttonClicked,
        selectedDocumentType: $selectedDocumentType,
    }),
    filter: ({buttonClicked, selectedDocumentType}, {files, isWorkflowButtonClicked, isError}) =>
        checkObjectValidity(
            buttonClicked || isWorkflowButtonClicked,
            files,
            isError,
            selectedDocumentType,
        ),
    fn: ({selectedDocumentType}, {files}) =>
        mapFileUploaderFilesToOutput(files, selectedDocumentType),
    target: documentCreated,
});

sample({
    clock: editingButtonClicked,
    source: combine({
        buttonClicked: $buttonClicked,
        selectedDocumentType: $selectedDocumentType,
    }),
    filter: ({buttonClicked, selectedDocumentType}, {files, isWorkflowButtonClicked, isError}) =>
        checkObjectValidity(
            buttonClicked || isWorkflowButtonClicked,
            files,
            isError,
            selectedDocumentType,
        ),
    fn: (_, {files, initialState, projId, documentId}) =>
        mapFileUploaderFilesForEditing(initialState, files, projId, documentId),
    target: documentEdited,
});

// создание неоцифрованного документа
sample({
    clock: documentCreated,
    source: combine({
        gate: DocumentConstructorGate.state,
        selectedDocumentType: $selectedDocumentType,
        comment: $commentValue,
        document_attachments: $attachmentDocumentIdList,
    }),
    filter: (source) => source.selectedDocumentType !== null,
    fn: (source, clock) => ({
        project_id: source.gate.projectId,
        subtypes_document_id: source.selectedDocumentType?.id.toString() ?? '',
        document_comment: source.comment,
        document_attachments: clock,
    }),
    target: createDocumentFx,
});

// редактирование неоцифрованного документа
sample({
    clock: documentEdited,
    source: combine({
        projectId: DocumentConstructorGate.state,
        selectedDocumentType: $selectedDocumentType,
        comment: $commentValue,
        document_attachments: $attachmentDocumentIdList,
    }),
    filter: (source) => source.selectedDocumentType !== null,
    fn: (source, clock) => {
        return {
            data: {
                project_id: clock.projectId,
                subtypes_document_id: source.selectedDocumentType?.id.toString() ?? '',
                document_comment: source.comment,
                document_attachments: clock.docs,
            },
            documentId: clock.documentId,
        };
    },
    target: editDocumentFx,
});

// преобразование данных от бека для автокомплита
sample({
    source: $documentTypeList,
    fn: (source) =>
        source.map((item) => ({
            id: item.id,
            value: item.name,
            isRegistration: item.is_registration,
        })),
    target: $documentTypeAutocompleteList,
});

// автовыбор значения, если оно единственное
sample({
    source: $documentTypeAutocompleteList,
    filter: (list) => list.length === 1,
    fn: (list) => list[0],
    target: selectedDocumentTypeChanged,
});

// изменение максимального количества загружаемых файлов в зависимости от выбранного типа документа
sample({
    clock: selectedDocumentTypeChanged,
    source: $documentTypeList,
    fn: (source, clock) => source.find((item) => item.id === clock.id)?.quantity_files ?? 1,
    target: $maxFilesCount,
});

// изменение минимального количества загружаемых файлов в зависимости от выбранного типа документа
sample({
    clock: selectedDocumentTypeChanged,
    source: $documentTypeList,
    fn: (source, clock) => source.find((item) => item.id === clock.id)?.quantity_files_min ?? 1,
    target: $minFilesCount,
});

// сброс домена после ухода со страницы
resetDomainStoresByEvents(DocumentConstructorDomain, DocumentConstructorGate.close);
