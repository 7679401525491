import React from 'react';

import {CommentButton, CommentButtonProps} from 'Features/buttons/CommentButton';
import {TableCell} from 'shared/ui/TableCell';

export const CommentCell = ({
    onClick,
    hasComments = false,
    showButton = true,
    attachmentCount = 0,
}: CommentButtonProps) => {
    return (
        <TableCell width="56px">
            <CommentButton
                onClick={onClick}
                hasComments={hasComments}
                showButton={showButton}
                attachmentCount={attachmentCount}
            />
        </TableCell>
    );
};
